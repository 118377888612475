import { useEffect, useState, Fragment } from 'react';

import { Button } from '../atoms';

import { checkSimilarity, extractGapFillAnswer } from './exerciseHelpers';
import { AnswerField, Keyboard } from './exerciseComponents';

export function RenderGapFill({
		content, 
		questions,
		setQuestions,
		exercise,
		setExercise,
		modified,
		setModified
	}) {
		
	const [blurOrigin, setBlurOrigin] = useState(null);

	return(
		<>
			{exercise.hideNumbers ? (			
				<table className="exercise-gapfill">
					<tbody>
						{questions.map((thisRow, i) =>
							<tr key={i}>
								<th>{thisRow.label}</th>
								<GapFillRow
									blurOrigin={blurOrigin}
									content={thisRow}
									exercise={exercise}
									modified={modified}
									questions={questions}
									setBlurOrigin={setBlurOrigin}
									setExercise={setExercise}
									setModified={setModified}
									setQuestions={setQuestions}	
								/>
							</tr>
						)}
					</tbody>
				</table>
			) : (
				<ol className="exercise-gapfill">
					{questions.map((thisRow, i) =>
						<GapFillRow
							blurOrigin={blurOrigin}
							content={thisRow}
							exercise={exercise}
							key={i}
							modified={modified}
							questions={questions}
							setBlurOrigin={setBlurOrigin}
							setExercise={setExercise}
							setModified={setModified}
							setQuestions={setQuestions}
						/>
					)}			
				</ol>
			)}			
			<Keyboard blurOrigin={blurOrigin} setBlurOrigin={setBlurOrigin} />
		</>
	)

}

function GapFillRow({
		blurOrigin,
		content, 
		exercise,
		modified,
		questions,
		setBlurOrigin,
		setExercise,
		setModified,
		setQuestions,
		setTabindex,
		tabindex
	}) {
		
	var number = 1;
	
	var fragmentContent = [];
	var splitArray = content.content.matchAll(/([^[\]()]*)(\([^\)]*\))?(\[[^\]]*\])?([^[\]()]*)/g);
	
	if(splitArray) {
		for (var match of splitArray) {
			match.shift();
			match.map((thisFragment, i) => {
				if(thisFragment) {
					var findPrompt = thisFragment.match(/(?<=\().+?(?=\))/);
					var findAnswer = thisFragment.match(/\[([^]*)\]/);
					if(findPrompt) {
						if(findPrompt[0]) {
							fragmentContent.push((<span>(<em>{findPrompt[0]}</em>)</span>));	
						}
					}	else if(findAnswer) {
						for(const thisAnswerField of content.answerFields) {
							if(thisAnswerField.number === number) {
								var thisFieldContent = thisAnswerField;
							}
						}
						fragmentContent.push(
							<GapFillAnswerField
								i={i}
								blurOrigin={blurOrigin}
								content={thisFieldContent}
								exercise={exercise}
								modified={modified}
								questionContent={content}
								questions={questions}
								setBlurOrigin={setBlurOrigin}
								setModified={setModified}
								setQuestions={setQuestions}
								tabindex={tabindex}
							/>
						);
						number = number+1;
					} else {
						if(thisFragment) {
							if(thisFragment!=="()") {
								fragmentContent.push(<span>{thisFragment}</span>);
							}
						}				
					}
				}			
			});
		}		
	} else {
		fragmentContent.push(content.content);
	}
	
	return(
		<>
			{exercise.hideNumbers ? (
				<td>
					<div className="conversation-gapfill-content">
					{fragmentContent.map((thisFragment, i) =>
						<Fragment key={i}>
							{thisFragment}
						</Fragment>
					)}
					</div>
				</td>
			) : (
				<li className={content.images.length>0 ? "image-question": ""}>
					<div className="numeral">{content.number}</div>
					<div className="question-wrapper">
						{content.images.length>0 && (
							<div className="question-images">
								{content.images.map((thisImage, i) =>
									<img key={i} src={thisImage.url} className="image-frame medium" />
								)}
							</div>
						)}
						<div className="question-content gapfill">
							{content.label && (
								<strong>{content.label}</strong>
							)}
							{fragmentContent.map((thisFragment, i) =>
								<Fragment key={i}>
									{thisFragment}
								</Fragment>
							)}
						</div>
					</div>
				</li>
			)}
		</>		
	);
	
}

function GapFillAnswerField({
		blurOrigin,
		content,
		exercise,
		i,
		modified,
		questionContent,
		questions,
		setBlurOrigin,
		setModified,
		setQuestions,
		tabindex
	}) {
	
	const [answerFieldValue, setAnswerFieldValue] = useState(content.userAnswer);
	const [answerFieldState, setAnswerFieldState] = useState(content.status);	
	
	const [error, setError] = useState(null);
	
	
	const [answerAttempts, setAnswerAttempts] = useState(0);
	const [answerFeedbackVisible, setAnswerFeedbackVisible] = useState(false);
	
	const [focusValue, setFocusValue] = useState(null);
	
	var maxAnswerLength = 0;
	for(const thisAnswer of content.answers) {
		if(thisAnswer.length>maxAnswerLength) {
			maxAnswerLength = thisAnswer.length;
		}
	}
	
	var fieldLength = " ";
	if(maxAnswerLength<3) {
		fieldLength = " short";
	} else if(maxAnswerLength>20) {
		fieldLength = " extralong";
	} else if(maxAnswerLength>10) {
		fieldLength = " long";
	} 
	
	useEffect(() => {
		setQuestions(questions.map(thisRow => {
			if(questionContent.number === thisRow.number) {
				const thisFieldContent = thisRow.answerFields.map(thisAnswerField => {
					if(content.number === thisAnswerField.number) {
						return {
							...thisAnswerField,							
							status: answerFieldState,
							userAnswer: answerFieldValue
						}
					} else {
						return thisAnswerField;
					}
				});
				return {
					...thisRow,
					answerFields: thisFieldContent
				}
			} else {
				return thisRow;
			}
		}));
	}, [answerFieldState, answerFieldValue]);
	
	function handleUpdateUserAnswer(e) {
		setAnswerFieldValue(e.target.value);
	}	
	
	function handleVerifyAnswer(e) {
		if(setBlurOrigin !== "specialChars") {
			const cleanUserAnswer = e.target.value.trim().toLowerCase();
			if(e.target.value) {
				var newAnswerFieldState = answerFieldState;
				var mostSimilarAnswer = {	score: -1 }
				for(const thisAnswer of content.answers) {
					if(newAnswerFieldState!=="correct") {
						var cleanAnswer = thisAnswer.toLowerCase();
						var similarityObject = checkSimilarity(cleanAnswer, cleanUserAnswer);
						if(similarityObject.score === 100) {
							console.log("correct");
							setAnswerFeedbackVisible(false);
							if(answerFieldState!=="revealed") {								
								setError(null);
								setAnswerFieldValue(thisAnswer);
								newAnswerFieldState = "correct";
								e.target.blur();
							}
						} else {
							if(similarityObject.score > mostSimilarAnswer.score) {
								mostSimilarAnswer = similarityObject;
							}
							setError(mostSimilarAnswer);
							setAnswerAttempts(answerAttempts+1);
							newAnswerFieldState = "error";	
							if(answerAttempts>0) {
								setAnswerFeedbackVisible(true);
							}							
						}
					}					
				}		
				setAnswerFieldState(newAnswerFieldState);						
			} else {
				setAnswerFieldState("unfilled");
			}	
		}
	}
	
	function handleFieldTracking(e) {
		localStorage.setItem("lastFocus", e.target.name);
		localStorage.setItem("lastCaretPos", e.target.selectionStart);
	}
	
	function handleEnterKey(e) {
		localStorage.setItem("lastCaretPos", e.target.selectionStart);
		if(e.keyCode === 13) {
			handleVerifyAnswer(e);
			if((content.tabIndex+1) < exercise.questionCount) {
				var nextField = `answer-${content.tabIndex+1}`;
				document.getElementById(nextField).focus();
			}			
		}
	}
	
	function handleBlur(e) {
		console.log(answerFieldState);
		setModified(true);
		handleVerifyAnswer(e);
	}
	
	function handleRevealAnswer() {
		setAnswerFeedbackVisible(false);
		setAnswerFieldState("revealed");
		setAnswerFieldValue(content.answers[0]);
		setError(null);
	}
	
	return(
		<div className="answer-field-wrapper">
			<div className={`answer${fieldLength} ${answerFieldState}`}>
				<input
					autoFocus={`${content.tabIndex === exercise.focusField ? "autofocus" : ""}`}
					autoCapitalize="off"
					autoComplete="off"
					disabled={(answerFieldState === "correct" || answerFieldState === "revealed") && "disabled"}
					id={`answer-${content.tabIndex}`}
					key={i}
					name={`answer-${content.tabIndex}`}				
					onBlur={e => handleBlur(e)}
					onChange={e => handleUpdateUserAnswer(e)}
					onClick={e => handleFieldTracking(e)}
					onKeyUp={e => handleEnterKey(e)}
					placeholder=" "
					spellCheck="false"
					tabIndex={content.tabIndex}
					type="text"
					value={answerFieldValue}
				/>
				{(answerFieldState === "correct" && (
					<div className="icon color" title="Right answer">
						<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="12" cy="12.5" r="12" fill="#1CA2B2"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M17.5145 6.64241C17.9881 6.92656 18.1416 7.54082 17.8575 8.0144L11.8575 18.0144C11.6991 18.2785 11.4278 18.4548 11.1222 18.4924C10.8166 18.53 10.5106 18.4248 10.2929 18.207L6.29289 14.207C5.90237 13.8165 5.90237 13.1833 6.29289 12.7928C6.68342 12.4023 7.31658 12.4023 7.70711 12.7928L10.8015 15.8872L16.1425 6.98541C16.4267 6.51183 17.0409 6.35827 17.5145 6.64241Z" fill="white"/>
						</svg>					
					</div>
				))}
				{(answerFieldState === "error" && (
					<div className="icon color" title="Wrong answer">
						<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="12" cy="12.5" r="12" fill="#EC1801"/>
						<circle cx="12" cy="18.5" r="2" fill="white"/>
						<path d="M12 5.5L12 13.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
						</svg>
					</div>
				))}
				{(answerFieldState === "accentError" && (
					<div className="icon color" title="Check your accented letters! 👀">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="12" cy="12" r="12" fill="#FFB700"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M5.70777 19.7727C6.6834 18.3727 8.62204 17.6944 11.2376 17.6944H13.5386V16.7778C13.5386 15.3611 12.8455 14.4722 11.1267 14.4722C9.49108 14.4722 8.63167 15.3056 7.99405 16.1944L5.55444 14.0278C6.7188 12.3056 8.38217 11.25 11.4317 11.25C15.5346 11.25 17.6416 13.1389 17.6416 16.5833V20.2578C16.4361 21.0829 15.0409 21.651 13.5347 21.883C13.5373 21.8395 13.5386 21.7952 13.5386 21.75V20.0278H11.5426C10.0047 20.0278 9.17581 20.5803 9.13248 21.5828C7.86764 21.2049 6.70793 20.5833 5.70777 19.7727Z" fill="#1C1E21"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M15.6457 5.19444L12.2358 3.5L9.49121 8.88889L11.9308 10.0833L15.6457 5.19444ZM14.118 5.552L12.6775 4.83616L10.8433 8.43747L11.6321 8.82364L14.118 5.552Z" fill="#1C1E21"/>
						</svg>					
					</div>
				))}
			</div>
			<div className={`answer-feedback${answerFeedbackVisible ? " visible" : ""}`}>
				{error && error.wordCount>1 && (
					<div className="answer-feedback-wrapper">
						{error.score > 60 && "Almost right! "}
						{error.mismatches.length > 0 && (
							<>							
								{error.missingWords.length > 0 && "• "}
								{error.mismatches.length === 1 ? "Check this word: " : "Check these words: " } 
								<strong>
									{error.mismatches.map((thisMismatch, i) => 
										<Fragment key={i}>{thisMismatch.userWord}{i+1 < error.mismatches.length ? ', ' : ''}</Fragment>
									)}
								</strong>
								{error.missingWords.length > 0 ? <br /> : ""}
							</>
						)}
						{error.missingWords.length > 0 && "• "}
						{error.missingWords.length === 1 && "It looks like you’re missing a word."}
						{error.missingWords.length > 1 && "It looks like you’re missing some words."}
					</div>
				)}					
				<div className="reveal-answer-wrapper">
					<Button
						icon="show"
						onClick={handleRevealAnswer}
						role="link"
					>
						Reveal answer
					</Button>
				</div>
			</div>
		</div>
	)
	
}