import { useEffect, useRef, useState } from 'react';
import { stringify } from 'qs';

import { getToken } from '../../helpers';
import { createNewAnswerEntry, getAnswerScheme } from '../../fetches';
import { useAuthContext } from '../../contexts';

import { Button, ToggleButtons } from '../../components/atoms';
import { RenderMarkdown, RichTextBlockRenderer } from '../../components/renderers';

import { countQuestions, extractGapFillAnswers, extractExerciseType, saveWork } from './exerciseHelpers';
import { ClearAnswersDialog, ExerciseLoadingSkeleton, ExerciseToolbar, Mark, RenderExercise } from './exerciseComponents';

import { ChipsDemo } from './orderChips';

export default function Exercise({
		content,
		contentID,
		nextPageDisabled,
		setNextPageDisabled
	}) {

	const authToken = getToken();
	const user = useAuthContext();
	
	const [dialogVisible, setDialogVisible] = useState(null);
	
	const exerciseContent = content.exercise.data.attributes.content[0].rows;
	
	const exerciseType = extractExerciseType(content.exercise.data.attributes.content[0].__component);
	const questionCount = countQuestions(exerciseContent, exerciseType);
	
	var questionContent = [];

	var tabIndex = -1;
	
	exerciseContent.map((thisQuestion, i) => {
		if(exerciseType==="gapFilling") {
			const extractedAnswers = extractGapFillAnswers(thisQuestion.content);
			if(extractedAnswers.length>0) {
				const imageArray = [];
				if(thisQuestion.images.data) {
					for(const thisImage of thisQuestion.images.data) {
						imageArray.push({
							url: thisImage.attributes.url
						})
					}
				}				
				questionContent.push({
					answerFields: extractedAnswers.map((thisAnswer, j) => {	
						tabIndex = tabIndex+1;
						return { 
							...thisAnswer,
							status: 'unfilled',
							tabIndex: tabIndex,
							userAnswer: ''
						}					
					}),
					content: thisQuestion.content,
					images: imageArray,
					label: thisQuestion.label,
					number: i+1
				});
			}	else {
				questionContent.push({
					content: thisQuestion.content,
					label: thisQuestion.label,
					number: i+1,
				});
			}
		} else if(exerciseType==="orderChips") {
			questionContent.push({
				prompt: thisQuestion.prompt,
				afterPrompt: thisQuestion.afterPrompt,
				answers: thisQuestion.answers,
				number: i+1,
				status: 'unfilled',
				userAnswer: []
			});
		}	else if(exerciseType==="bucketSort") {
			questionContent.push({
				bucket: thisQuestion.bucket,
				item: thisQuestion.item,
				notes: thisQuestion.notes,
				number: i+1,
				status: 'unfilled',
				userAnswer: []
			});
		}		
	});	
	
	const [questions, setQuestions] = useState(questionContent);
	const [answersID, setAnswersID] = useState(null);
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const [exercise, setExercise] = useState({
		correctAnswers: 0,
		hideNumbers: content.exercise.data.attributes.content[0].hideNumbers,
		questionCount: questionCount,
		state: "unstarted",
		unfilledAnswers: questionCount
	});	
	
	const [modified, setModified] = useState(false);	
	const [saving, setSaving] = useState(false);
	const [saveError, setSaveError] = useState(false);
	
	var q = stringify({
		filters: {
			user: {
				$eq: user.user.id,
			},
			exercise: {
				$eq: content.exercise.data.id
			}
		}
	});
	
	useEffect(() => {	
		console.log("Fetching answer scheme…");
		getAnswerScheme(q)
		.then((data) => {			
			if(data.data.length>0) {
				setLoading(false);
				setAnswersID(data.data[0].documentId);
				setQuestions(data.data[0].answerJSON);
			} else {
				console.log("No answers, creating blank entry…");
				const newAnswerData = {
					data: {
						answerJSON: questions,
						correctAnswers: exercise.correctAnswers,
						exercise: content.exercise.data.documentId,
						lessonPage: contentID,
						possibleMarks: exercise.questionCount,
						state: "unstarted",
						unfilledAnswers: exercise.unfilledAnswers,
						user: user.user.id						
					}
				}
				createNewAnswerEntry(newAnswerData)
				.then((data) => {
					setLoading(false);
					if(data.data) {
						setAnswersID(data.data.documentId);
					}					
				})
				.catch((error) => {
					setLoading(false);
					setError(true);
					console.log(error);
				});
			}
		})
		.catch((error) => {
			setError(true);		
			setLoading(false);
			console.log(error);
		});
	}, [q]);
	
	useEffect(() => {
		var correctAnswers = 0;
		var incorrectAnswers = 0;
		var unfilledAnswers = 0;
		var focusField = 0;
		var firstEmptyAnswer = null;
		questions.map((thisQuestion, i) => {
			if(thisQuestion.answerFields) {
				for(const thisAnswerField of thisQuestion.answerFields) {
					if(thisAnswerField.status === "correct") {
						correctAnswers = correctAnswers+1;
					} else if(thisAnswerField.status === "error") {
						incorrectAnswers = incorrectAnswers+1;
					} else if(thisAnswerField.status === "unfilled") {
						unfilledAnswers = unfilledAnswers+1;
						if(firstEmptyAnswer===null) {
							firstEmptyAnswer = thisAnswerField.tabIndex;
						}
					}
				} 	
			} else if(thisQuestion.status) {
				if(thisQuestion.status === "correct") {
					correctAnswers = correctAnswers+1;
				} else if(thisQuestion.status === "unfilled") {
					unfilledAnswers = unfilledAnswers+1;
				}
			}
		});
		if(firstEmptyAnswer!==focusField) {
			focusField = firstEmptyAnswer;
		}
		setExercise({
			...exercise,
			correctAnswers: correctAnswers,
			incorrectAnswers: incorrectAnswers,
			unfilledAnswers: unfilledAnswers,
			focusField: focusField
		});
	}, [questions]);
	
	useEffect(() => {
		if(exercise) {
			if(exercise.unfilledAnswers > 0 || exercise.incorrectAnswers > 0) {
				setNextPageDisabled(true);
			} else {
				if(exercise.unfilledAnswers===0 && modified) {
					console.log("Exercise complete!");					
					if(!saving) {
						saveWork({
							answersId: answersID,
							exercise: exercise,
							questions: questions,
							saving: saving,
							setModified: setModified,
							setSaveError: setSaveError,
							setSaving: setSaving
						});
						setNextPageDisabled(false);
					}			
				} else {
					setNextPageDisabled(false);
				}		
			}	
		}			
	}, [exercise, modified, saving]);
	
	if(questions && content.exercise.data) {
		return(
			<>
				<div className="prose">
					<h1>{RenderMarkdown(content.title)}</h1>
				</div>			
				<div className="exercise-wrapper">
					<div className="block exercise">
						<div className="intro">
							<div className="instructions">
								<RichTextBlockRenderer content={content.exercise.data.attributes.instructions} />
								{(exerciseType==="orderChips") && (
									<ChipsDemo />
								)}
							</div>				
							{!loading && (
								<ExerciseToolbar
									answersID={answersID}
									dialogVisible={dialogVisible}
									exercise={exercise}
									exerciseType={exerciseType}
									questions={questions}
									setDialogVisible={setDialogVisible}
									setExercise={setExercise}
									setQuestions={setQuestions}
								/>
							)}							
							</div>
						{(loading || !answersID) ? (
							<ExerciseLoadingSkeleton />
						) : (
							<>
								<RenderExercise
									content={content}
									exercise={exercise}
									exerciseType={exerciseType}
									modified={modified}
									questions={questions}
									setExercise={setExercise}
									setModified={setModified}
									setQuestions={setQuestions}
								/>
								<Mark
									answersID={answersID}
									exercise={exercise}
									questions={questions}
									saving={saving}
									setModified={setModified}
									setSaveError={setSaveError}
									setSaving={setSaving}
								/>
							</>
						)}					
					</div>
				</div>
				<ClearAnswersDialog answersID={answersID} visible={dialogVisible} setVisible={setDialogVisible} />
			</>
		);
	}
	
}