import { useAuthContext } from '../../contexts';
import { Link } from 'react-router-dom';

import { Button, Spinner } from '../../components/atoms';

export function PaymentProcessing({ 
		status 
	}) {
		
	return(
		<div className="payment-status-wrapper">
			<div className="payment-status-content">
				<Spinner />
				<h2>{status}</h2>
			</div>
		</div>
	)
	
}
 
export function PaymentSuccess({
		productData,
		squareReceiptURL
	}) {
		
	const user = useAuthContext();
	
	return(
		<div className="payment-status-wrapper success">
			<div className="payment-status-content">
				<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="32" cy="32.5" r="32" fill="#1CA2B2"/>
				<path d="M18.6666 35.1667L29.3333 45.8333L45.3333 19.1667" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>				
				<h2>Payment successful</h2>
				<p>You’ll get a receipt from Square to your email address.</p>
			</div>
			<div className="payment-status-buttons">
				<Link to={squareReceiptURL} target="_new">
					<Button
						icon="external"
						iconPlacement="after"
						label="View receipt"
						role="secondary"
					/>
				</Link>
				<a href={`/c/${productData.slug}`}>
					<Button
						icon="next"
						iconPlacement="after"
						label="Start learning"
					/>
				</a>
			</div>
		</div>
	)
	
}