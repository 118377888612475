import { useEffect, useState } from 'react';

import { RichTextBlockRenderer } from '../renderers'

export function RenderBucketSort({
		buckets,
		content,
		mode,
		questions,
		setModified,
		setQuestions
	}) {
		
	const [selectedChip, setSelectedChip] = useState({
		item: null
	});
	
	const [clue, setClue] = useState(null);
	
	const [bucketChips, setBucketChips] = useState([]);
	const [wellChips, setWellChips] = useState([]);
	
	const [incorrect, setIncorrect] = useState(null);
	
	useEffect(() => {
		setBucketChips(buckets.map((thisBucket, i) => {			
			const bucketChipsArray = [];
			questions.map((thisItem) => {
				if(thisItem.status === "correct" && thisItem.bucket === thisBucket.identifier) {
					bucketChipsArray.push(thisItem.item);
				}
			});
			return {
				...thisBucket,
				chips: bucketChipsArray
			}
		}));
	}, [buckets]);
	
	useEffect(() => {
		const wellChipsArray = []
		questions.map((thisItem, i) => {
			if(thisItem.status !== "correct") {
				wellChipsArray.push(thisItem);
			}
		});
		setWellChips(wellChipsArray);
	}, [buckets]);
	
	function handleCheckSelection(identifier) {
		if(wellChips.length>0) {
			setIncorrect(null);			
			setClue(null);
			var selectedChip = selectedChip;
			if(mode==="one-up") {
				selectedChip = wellChips[0];
			}
			if(!selectedChip.item) {
				console.log("No chip selected");
			} else {
				if(identifier===selectedChip.bucket) {
					console.log("Right bucket");
					setBucketChips(bucketChips.map((thisBucket, i) => {
						if(thisBucket.identifier===selectedChip.bucket) {
							var newChipsArray = thisBucket.chips;
							newChipsArray.push(selectedChip.item);
							return {
								...thisBucket,
								chips: newChipsArray
							}
						} else {
							return {
								...thisBucket
							}
						}					
					}));
					setQuestions(questions.map(thisQuestion => {
						if(thisQuestion.number===selectedChip.number) {
							return {
								...thisQuestion,
								status: 'correct'
							}
						} else {
							return thisQuestion;
						}
					}));				
					const truncatedWellChipsArray = [];
					wellChips.map((thisWellChip, i) => {
						if(thisWellChip.item!==selectedChip.item) {
							truncatedWellChipsArray.push(thisWellChip);
						}
					});
					setWellChips(truncatedWellChipsArray);
					setSelectedChip({});
					setModified(true);
				} else {
					console.log("Wrong bucket");
					setIncorrect(identifier);
					setClue(selectedChip.notes);
				}
			}
		}		
	}
	
	function handleSelectChip(data) {
		setSelectedChip(data);
	}
	
	return(
		<div className="bucket-sort">
			<div className="bucket-wrapper">
				{bucketChips.map((thisBucket, i) =>
					<div 
						className={`bucket${(selectedChip.item || (mode==="one-up" && wellChips.length>0)) ? ' selectable' : ''}${incorrect===thisBucket.identifier ? ' incorrect' : ''}`}
						onClick={() => handleCheckSelection(thisBucket.identifier)}
						key={i}
					>
						<div className="bucket-label">
							{thisBucket.icon && (
								<img
									alt={thisBucket.icon.data.attributes.alternativeText}
									className="icon color med"
									src={thisBucket.icon.data.attributes.url} 
								/>
							)}
							{thisBucket.label}
						</div>
						<div className="bucket-well">
							<div className="bucket-well-wrapper">
								{thisBucket.chips.map((thisChip, j) =>
									<div className="chip" key={j}>
										{thisChip}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>			
			<div className="chips-well">
				{content.exercise.data.attributes.content[0].prompt && (
					<div className="centred-content">
						<RichTextBlockRenderer content={content.exercise.data.attributes.content[0].prompt} />
					</div>
				)}
				<div className="chips-wrapper">
					{mode==="one-up" ? (
						<>
							{wellChips.length>0 && (
								<>
									<div
										className="chip"
										onClick={() => handleSelectChip(wellChips[0])}
									>																	
										{wellChips[0].item}
									</div>
								</>
							)}	
						</>				
					) : (
						<>
							{wellChips.map((thisChip, i) =>
								<div
									className={`chip${selectedChip.item===thisChip.item ? ' selected' : ''}`}
									onClick={() => handleSelectChip(thisChip)}
									key={i}
								>
									{thisChip.item}
								</div>
							)}
						</>
					)}
				</div>	
				{clue && (
					<div className="centred-content">
						<div className="chip-clue">
							{clue}
						</div>
					</div>
				)}				
			</div>
		</div>
	)
	
}