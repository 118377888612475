import { useEffect, useState } from 'react';

import { getProductReviews } from '../../fetches';
import { formatDate, parseCountryCode } from '../../helpers';

import { Spinner } from '../../components/atoms';

export function Reviews({
		product
	}) {
		
	const [reviews, setReviews] = useState([]);
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	useEffect(() => {
		getProductReviews(product)
		.then((reviews) => {
			console.log(reviews.data);
			setReviews(reviews.data);
			setLoading(false);
		})
		.catch((error) => {
			setError(true);
			setLoading(false);
		});
	}, [product]);
		
	return(
		<div className="card secondary reviews">
			<div className="reviews-intro">
				<h2>Reviews</h2>
				<p><strong>See what real learners have to say about this course.</strong></p>
			</div>
			{loading ? (
				<div className="centred-content"><Spinner /></div>
			) : (
				<>
					{reviews.length > 0 ? (			
						<>	
							{reviews.map((thisReview, i) => 
								<Review content={thisReview} key={i} />
							)}					
						</>
					) : ( 
						<div className="centred-content padded">
							There aren’t any reviews for this course yet, but please check back soon.
						</div>
					)}
				</>
			)}			
		</div>
	)
	
}

function Review({ content	}) {
	return(
		<div className="review">
			<div className="review-info">
				<Rating rating={content.rating} />
				{content.user && (
					<div className="review-user"><strong>{content.user.firstName}</strong>, {parseCountryCode(content.user.country)}</div>
				)}				
			</div>
			<p>{content.comments}</p>
			<div className="review-date">{formatDate(content.reviewDate)}</div>
		</div>
	)	
}

function Rating({ rating }) {
	
	const emptyStarCount = 5-rating;
	
	const stars = [];
	const emptyStars = [];
	
	for(let i = 0; i < rating; i++) {
		stars.push(<svg key={i} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z" fill="#FFC800"/></svg>);
	}
	
	for(let i = 0; i < emptyStarCount; i++) {
		emptyStars.push(<svg key={i} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z" fill="white"/><path d="M7.52447 1.58156C7.67415 1.1209 8.32585 1.1209 8.47553 1.58156L9.5451 4.87336C9.74591 5.49139 10.3218 5.90983 10.9717 5.90983H14.4329C14.9172 5.90983 15.1186 6.52964 14.7268 6.81434L11.9266 8.84878C11.4009 9.23075 11.1809 9.9078 11.3817 10.5258L12.4513 13.8176C12.6009 14.2783 12.0737 14.6613 11.6818 14.3766L8.88168 12.3422C8.35595 11.9602 7.64405 11.9602 7.11832 12.3422L4.31815 14.3766C3.9263 14.6613 3.39906 14.2783 3.54873 13.8176L4.6183 10.5258C4.81911 9.9078 4.59913 9.23075 4.07339 8.84878L1.27323 6.81434C0.881369 6.52964 1.08276 5.90983 1.56712 5.90983H5.02832C5.67816 5.90983 6.25409 5.49139 6.4549 4.87336L7.52447 1.58156Z" stroke="black" stroke-opacity="0.2"/></svg>);
	}
	
	return(
		<div className="review-rating">
			{stars}
			{emptyStars}
		</div>
	)
}