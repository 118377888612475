import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { stringify } from 'qs';

import { usePostHog } from 'posthog-js/react';

import { getCompanyData, subscribeMailingList, registerUser } from '../../../fetches';
import { getDefaultCurrency } from '../../../helpers';

import { Button, Spinner } from '../../../components/atoms';

import countries from '../../../countries.json';

export function SignupForm({
		enterpriseMode,
		loading,
		prefilledEmail,
		prefilledName,
		setEmail,
		setLoading,
		setSuccess
	}) {
		
	const posthog = usePostHog();
	const siteMode = process.env.REACT_APP_SITE_MODE;
		
	const [error] = useState(null);
	
	const [passwordBannerVisible, setPasswordBannerVisible] = useState(false);	
	const [companyData, setCompanyData] = useState(null);
		
	const { register, handleSubmit, setError, setFocus, setValue, formState: { errors } } = useForm();
	
	useEffect(() => {		
		if(!loading) {
			if(prefilledName && prefilledEmail) {
				document.getElementById('country').focus();
				setPasswordBannerVisible(true);
			} else {
				document.getElementById('firstName').focus();
			}
		}
	}, [loading, prefilledName, prefilledEmail])
	
	useEffect(() => {
		if(enterpriseMode && !companyData && prefilledEmail) {	
			console.log("enterprise");	
			const companyDomain = prefilledEmail.match(/@([A-Za-z0-9_.\-]+)$/)
			var q = stringify({
				filters: {
					domain: {
						$eq: companyDomain[1]
					}
				}
			});
			getCompanyData(q)
			.then((company) => {
				if(company.data) {
					setCompanyData(company.data[0]);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
			})
		} else {
			setLoading(false);
			setValue("email", prefilledEmail);
		}
	}, [companyData, enterpriseMode, prefilledEmail]);
	
	function checkKeyDown(e) {
		if(e.key === 'Enter') e.preventDefault();
	}
	
	async function handleUserSignup(formData) {
		if(formData.password!==formData.confirmPassword) {
			setError('confirmPassword', { type: 'noMatch' });
		} else {
			setLoading(true);
			var trialExpiry = new Date();
			trialExpiry.setDate(trialExpiry.getDate() + 7);
			if(enterpriseMode) {
				var signupData = {
					username: formData.enterpriseEmail,
					email: formData.enterpriseEmail,
					password: formData.password,
					firstName: formData.firstName.trim(),									
					country: formData.country,
					enterpriseAccount: companyData.id,
					enterpriseAccountActive: true
				}
			} else {
				var signupData = {
					username: formData.email,
					email: formData.email,
					password: formData.password,
					firstName: formData.firstName.trim(),					
					country: formData.country,					
					trialExpiry: trialExpiry
				}
			}			
			try {			
				const response = await registerUser(signupData);
				if (response?.error) {
					throw response?.error;
				} else {					
					setSuccess(true);
					posthog?.capture('user_signup');
					localStorage.removeItem('signupFirstname');
					localStorage.removeItem('signupEmail');
					localStorage.setItem('currency', getDefaultCurrency(formData.country));
					if(enterpriseMode) {
						setEmail(formData.enterpriseEmail);
					} else {
						setEmail(formData.email);
					}
					if(formData.newsletterOptin) {
						subscribeMailingList(formData)
						.then((data) => {
						})
						.catch((error) => {
							console.log(error);
						});
					}
				}
			} catch(error) {
				console.error(error);
				if(error.message === 'Email or Username are already taken') {
					setError('email', { type: 'taken' });
					setFocus('email');
					setPasswordBannerVisible(false);
				} else {
					setError(error);
				}
			} finally {
				setLoading(false);
			}
		}
	}
	
	return(
		<div className="signup-login-form-wrapper">			
			{(loading) ? (
				<div className="centred-content">
					<Spinner />
				</div>
			) : (
				<>	
					{enterpriseMode && (
						<div className="placard enterprise-signup">
							<div className="avatar">
								<svg className="icon body-fill" width="48" height="48" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3 1C3.53125 1 4 1.46875 4 2V6.75L8.875 4.09375C9.375 3.84375 10 4.1875 10 4.78125V6.75L14.875 4.09375C15.375 3.84375 16 4.1875 16 4.78125V13.5C16 14.3438 15.3125 15 14.5 15H1.5C0.65625 15 0 14.3438 0 13.5V2C0 1.46875 0.4375 1 1 1H3Z" fill="#1C1E21"/>
								</svg>
							</div>
							<span><strong>{companyData.companyName}</strong><br />has invited you to join</span>
						</div>
					)}
					<div className="placard-placeholder">
						{siteMode==="beta" && (
							<div className="placard info">
								You’re invited to the Learn Icelandic closed beta. Create your account and get testing today! 🥳
							</div>
						)}
						{error && (
							<div className="placard error">
								{error.message}
							</div>
						)}
						{errors.email?.type === 'taken' && (
							<div className="placard error">An account already exists under this email address ✉️</div>
						)}
						{errors.email?.type === 'pattern' && (
							<div className="placard error">Please enter a valid email address ✉️</div>
						)}
						{errors.confirmPassword?.type === 'noMatch' && (
							<div className="placard error">Passwords don’t match 🔐</div>
						)}
					</div>
					<div className="centred-content">
						<form 
							className="signup-login-form"
							onKeyDown={e => checkKeyDown(e)}
							onSubmit={handleSubmit(handleUserSignup)}
						>
							<div className="rows">			
								{enterpriseMode && (
									<div className="row">
										<input 
											type="hidden" 
											id="enterpriseEmail" 
											name="enterpriseEmail" 
											{...register('enterpriseEmail')}
										/>		
										<div className="row-label">Email address</div>
										<div className="row-content">
											{prefilledEmail}											
											<div className="explanation">Your account is linked to your work email address. This cannot be changed.</div>
										</div>
									</div>
								)}
								{siteMode==="beta" && (
									<div className="row">
										<input 
											type="hidden" 
											id="enterpriseEmail" 
											name="enterpriseEmail" 
											{...register('enterpriseEmail')}
										/>		
										<div className="row-label">Email address</div>
										<div className="row-content">
											{prefilledEmail}											
											<div className="explanation">Your account is linked to your email invite. You can update your email address later in account settings.</div>
										</div>
									</div>
								)}
								<div className="row">
									<div className="cell">
										<label
											aria-invalid={errors.firstName ? "true" : "false"}
											className="row-label"
											htmlFor="firstName">
											First name
										</label>
										<input 
											type="text" 
											id="firstName" 
											name="firstName" 
											className="account-form"
											defaultValue={prefilledName}
											{...register('firstName', { required: true })}
											aria-invalid={errors.firstName ? "true" : "false"}
										/>
									</div>					
									{(!enterpriseMode && siteMode!=="beta") && (							
										<div className="cell">
											<label 
												aria-invalid={errors.email ? "true" : "false"}
												className="row-label"
												htmlFor="email">
												Email address
											</label>
											<input 
												type="email" 
												id="email" 
												name="email" 
												className="account-form"
												defaultValue={prefilledEmail}
												{...register('email', { required: true, pattern: /^[-a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
												aria-invalid={errors.email ? "true" : "false"}
											/>				
										</div>	
									)}	
								</div>
							</div>
							{(passwordBannerVisible && 
								<>
									{(prefilledName && prefilledEmail) && (
										<div className="placard info">To finish signing up, please create a password 🔐</div>
									)}
								</>
							)}							
							<div className="rows">
								<div className="row">
									<div className="cell">
										<label											
											aria-invalid={errors.country ? "true" : "false"}
											className="row-label"
											htmlFor="country">
											Country
										</label>
										<CountryDropdown
											className="account-form"
											errors={errors}
											setValue={setValue}
										/>
										<input										
											id="country" 
											name="country"
											{...register('country', { required: true })}
											type="hidden"
											defaultValue={undefined}
										/>
									</div>
								</div>
								<div className="row padded">
									<div className="cell">
										<label
											aria-invalid={errors.password ? "true" : "false"}
											className="row-label"
											htmlFor="password">
											Password
										</label>
										<input 
											type="password" 
											id="newPassword" 
											name="newPassword" 
											className="account-form"
											{...register('password', { required: true, minLength: 6 })}
											aria-invalid={errors.password ? "true" : "false"}
										/>
									</div>
									<div className="cell">
										<label 
											aria-invalid={errors.confirmPassword ? "true" : "false"}
											className="row-label"
											htmlFor="password">
											Confirm password
										</label>
										<input 
											type="password" 
											id="confirmPassword" 
											name="confirmPassword" 
											className="account-form"
											{...register('confirmPassword', { required: true, minLength: 6 })}
											aria-invalid={errors.confirmPassword ? "true" : "false"}
										/>
									</div>
								</div>
								<div className="row padded">
									<div className="checkbox-wrapper full-width">
										<input
											id="newsletterOptin"
											name="newsletterOptin"
											{...register('newsletterOptin')} 							
											type="checkbox"
										/>
										<label htmlFor="newsletterOptin">Send me occasional updates about cool new courses and great special offers via email 😎</label>
									</div>
								</div>
								<div className="row padded centred">						
									{loading ? (
										<Button	label="Signing up…" disabled={true} />			
									) : (
										<button type="submit">Sign up</button>			
									)}							
								</div>
								<div className="row padded">
									<div className="form-terms">By signing up, you agree to the <a href="/privacy-notice" target="_new">privacy notice</a>.</div>
								</div>
							</div>
						</form>
					</div>
				</>	
			)}
		</div>
		
	)		
}

export function Success({
		email,
		loading,
		setLoading
	}) {

	const [emailResent, setEmailResent] = useState(false);
	
	async function handleResendConfirmation() {
		setLoading(true);
		const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/send-email-confirmation`, {
			method: 'POST',
			headers: {
				'Strapi-Response-Format': 'v4',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email
			})
		});		
		const data = await response.json();
		if(data.sent === true) {
			setEmailResent(true);
			setLoading(false);
		}
	}
	
	const [countdown, setCountdown] = useState(60);
	
	useEffect(() => {
		const id = setInterval(() => {
			if(countdown>0) {
				setCountdown(c => c - 1);
			}				
		}, 1000);
		return () => {
			clearInterval(id);
		};
	}, [countdown]);
	
	return(
		<>
			<div className="centred-content">
				<h2>Check your email</h2>
				<p>You’re almost there! Check your inbox for a confirmation email and <strong>follow the link inside</strong> to finish creating your account.</p>
				<p>The email can take a few minutes to arrive.</p>
				<p>Once you’ve received the email, you can close this tab.</p>
			</div>
			{email  && (					
				<div className="centred-content">
					{emailResent ? (
						<div className="placard success">✉️ Confirmation email resent</div>
					) : (
						<Button 
							onClick={handleResendConfirmation}
							disabled={(loading || countdown>0) && true}
						>
							Resend confirmation email {countdown>0 && `(${countdown})`}
						</Button>
					)}			
				</div>
			)}	
		</>	
	)
	
}

function CountryDropdown({
		className,
		errors,
		setValue
	}) {
		
	const ref = useRef(null);
		
	const [searchValue, setSearchValue] = useState(undefined);
	const [searchResults, setSearchResults] = useState(countries);
	
	const [hoverItem, setHoverItem] = useState({
		code: undefined,
		name: undefined
	});
	const [selectPosition, setSelectPosition] = useState(0);
	
	const [dropdownVisible, setDropdownVisible] = useState(false);
	
	function filterSearch(q) {
		var resultsArray = []
		for(const thisCountry of countries) {
			const cleanCountryName = thisCountry.name.toLowerCase();
			const cleanSearchString = q.toLowerCase().trim();
			if(cleanCountryName.startsWith(cleanSearchString)) {
				resultsArray.push(thisCountry);
			} else if(cleanCountryName.includes(cleanSearchString)) {
				if(cleanSearchString.length>2) {
					resultsArray.push(thisCountry);
				}
			} else if(thisCountry.synonyms) {
				if(cleanSearchString.length>2) {
					for(const thisSynonym of thisCountry.synonyms) {
						const cleanSynonym = thisSynonym.toLowerCase();
						if(cleanSynonym.startsWith(cleanSearchString)) {
							resultsArray.push(thisCountry);
						}
					}
				}				
			}		
		}
		if(resultsArray.length===0) {
			resultsArray = countries;
		}
		setSearchResults(resultsArray);
	}
		
	function handleToggleDropdown(e) {
		if(dropdownVisible) {
			console.log(e.relatedTarget);
			if(e.relatedTarget!==null) {
				if(e.relatedTarget.id!=="select-search-dropdown") {
					setDropdownVisible(false);
				}				
			}		  
		} else {
			setDropdownVisible(true);
		}
	}
	
	function handleKeyPress(e) {
		console.log(selectPosition);
		setDropdownVisible(true);
		setSearchValue(e.target.value);
		if(e.key === 'Enter') {
			if(hoverItem) {
				setValue("country", hoverItem.code);
				setSearchValue(hoverItem.name);
				setDropdownVisible(false);
			}			
		} else if(e.key === 'ArrowDown') {
			console.log("Down")
			e.preventDefault();
			if(selectPosition<searchResults.length) {
				setSelectPosition(selectPosition+1);
			}			
		} else if(e.key === 'ArrowUp') {
			e.preventDefault();
			if(selectPosition>0) {
				setSelectPosition(selectPosition-1);
			}			
		} else {
			filterSearch(e.target.value);
		}
		setHoverItem({
			code: searchResults[selectPosition].code,
			name: searchResults[selectPosition].name
		});
	}
	
	function handleClick(code, name) {
		setValue("country", code);
		setSearchValue(name);
		setDropdownVisible(false);
	}
	
	function handleMouseEnter(code, name, selectPosition) {
		setHoverItem({
			code: code,
			name: name
		});
		setSelectPosition(selectPosition);
	}
	
	function handleFocus(e) {		
		console.log(e.target.value);
		filterSearch(e.target.value);
		setHoverItem({
			code: searchResults[0].code,
			name: searchResults[0].name
		});
	}
	
	function handleBlur(e) {
		if(!e.relatedTarget || e.relatedTarget.id!=="select-search-dropdown") {
			setDropdownVisible(false);
			for(const thisCountry of countries) {
				const cleanCountryName = thisCountry.name.toLowerCase();
				const cleanSearchString = e.target.value.toLowerCase().trim();
				if(cleanCountryName === cleanSearchString) {
					setValue("country", thisCountry.code);
					setSearchValue(thisCountry.name);
				}			
			}
		}		
	}
	
	return (
		<div className="select-search-wrapper">
			<input 
				className={`select-search account-form ${className}`}
				id="country"
				onChange={e => setSearchValue(e.target.value)}
				onBlur={e => handleBlur(e)}
				onFocus={e => handleFocus(e)}
				onKeyDown={e => handleKeyPress(e)}
				onFocus={handleToggleDropdown}
				type="text"
				value={searchValue}
				aria-invalid={errors.country ? "true" : "false"}
			/>
			<ul className={`select-search-dropdown${dropdownVisible ? " visible" : ""}`} id="select-search-dropdown" tabIndex="-1">
				{searchResults.map((thisCountry, i) =>
					<li 
						className={`${hoverItem.code===thisCountry.code ? "selected" : "" }`} 
						key={i} 
						onClick={() => handleClick(thisCountry.code, thisCountry.name)}
						onMouseEnter={() => handleMouseEnter(thisCountry.code, thisCountry.name, i)}
						value={thisCountry.code}
					>
						{thisCountry.name}
					</li>
				)}
			</ul>
		</div>
	)
	
}