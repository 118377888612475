import { useEffect, useState } from 'react';

import { getVendor } from '../../fetches';

import { Button, Spinner } from '../../components/atoms';
import { RichTextBlockRenderer } from '../../components/renderers';

export function VendorInfoDialog({ 
		vendorId,
		visible,
		setVisible
	}) {
	
	const [vendorData, setVendorData] = useState(null);
	
	useEffect(() => {
		if(!vendorData) {
			getVendor(vendorId)
			.then((vendorData) => {
				setVendorData(vendorData.data);
			})
			.catch((error) => {
				console.log(error);
			});
		}
	}, [vendorData]);
	
	function handleCloseDialog() {
		setVisible(false);
	}
		
	return(
		<div className={`dialog-shader light${visible === 'vendor-info' ? (' visible') : ('')}`}>
			<dialog className="dialog large">
				{!vendorData ? (
					<div className="centred-content"><Spinner /></div>
				) : (
					<>
						<div className="rows dialog-content">		
							<div className="row centred vendor-intro">
								<div className="avatar large">
									<img src={vendorData.avatar.url} />
								</div>
								<div className="vertical-row-content slim-gap">
									<h2>{vendorData.name}</h2>
									<a className="external" href={vendorData.website} target="_new">
										<Button
											role="link"
											icon="external"
											iconPlacement="after"
										>
											Visit website
										</Button>
									</a>
								</div>
							</div>
							<div className="row prose">
								<RichTextBlockRenderer content={vendorData.description} />
							</div>
						</div>
						<div className="rows anchored-buttons">
							<div className="row align-right">
								<Button role="secondary" onClick={handleCloseDialog}>Close</Button>
							</div>
						</div>
					</>
				)}				
			</dialog>
		</div>
	)
	
}	

export function LevelInfoDialog({ 
		level,
		visible,
		setVisible
	}) {
	
	function handleCloseDialog() {
		setVisible(false);
	}
		
	return(
		<div className={`dialog-shader light${visible === 'level-info' ? (' visible') : ('')}`}>
			<dialog className="dialog large">
				<div className="rows dialog-content">		
					<div className="row centred">
						<div className="vertical-row-content slim-gap">
							<h2>About CEFR levels</h2>
						</div>
					</div>
					<div className="row prose">
						<p>This course is rated <strong>{level}</strong> on the Common European Framework of Reference for Languages.</p>
						<p>This means that this course will help you:</p>
						<ul className="padded-list">
							<li>Understand and use familiar everyday expressions and very basic phrases to satisfy concrete needs.</li>
							<li>Introduce yourself to others and ask and answer questions about personal details such as where you live, people you know and things you have.</li>
							<li>Interact in a simple way, provided the other person talks slowly and clearly and is prepared to help.</li>
						</ul>
					</div>
				</div>
				<div className="rows anchored-buttons">
					<div className="row align-right">
						<Button role="secondary" onClick={handleCloseDialog}>Close</Button>
					</div>
				</div>
			</dialog>
		</div>
	)
	
}	

export function HumanMadeDialog({ 
		visible,
		setVisible
	}) {
	
	function handleCloseDialog() {
		setVisible(false);
	}
		
	return(
		<div className={`dialog-shader light${visible === 'human-made' ? (' visible') : ('')}`}>
			<dialog className="dialog large">
				<div className="rows dialog-content">		
					<div className="row centred">
						<div className="vertical-row-content slim-gap">
							<h2>What does “human-made” mean?</h2>
						</div>
					</div>
					<div className="row prose">
						<p>We believe in a human approach to teaching languages. As a result, we rely on experienced specialists to design our courses and avoid the use of AI.</p>
					</div>
					<ul className="row tips">
						<li>
							<div className="emoji">🧑‍🏫</div>
							<div>
								<strong>Designed by teachers</strong><br/>Our course materials are written from scratch by trusted teachers with a wealth of experience.
							</div>
						</li>
						<li>
							<div className="emoji">🤓</div>
							<div>
								<strong>Curated exercises</strong><br/>Our exercises are designed by teachers, not built automatically from datasets.
							</div>
						</li>
						<li>
							<div className="emoji">🎙️</div>
							<div>
								<strong>Real voice recordings</strong><br/>We don’t use synthesised or AI voices for our audio, just recordings of real people.
							</div>
						</li>
						<li>
							<div className="emoji">👀</div>
							<div>
								<strong>No data harvesting</strong><br/>We collect minimal data about your usage and we never use your data to train AI models.
							</div>
						</li>
					</ul>
				</div>
				<div className="rows anchored-buttons">
					<div className="row align-right">
						<Button role="secondary" onClick={handleCloseDialog}>Close</Button>
					</div>
				</div>
			</dialog>
		</div>
	)
	
}	