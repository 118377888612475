import { updateExerciseAnswers } from '../../fetches';

export function countQuestions(exerciseContent, exerciseType) {
	var questionCount = 0;
	for(const thisQuestion of exerciseContent) {
		if(exerciseType==="gapFilling") {
			var answerFieldCount = extractGapFillAnswers(thisQuestion.content);
			if(answerFieldCount) {
				questionCount = questionCount+answerFieldCount.length;
			}
		} else {
			questionCount = questionCount+1;
		}			
	}
	return questionCount;
}

export function extractExerciseType(string) {
	var exerciseType = null;
	if(string==="exercise-blocks.gap-filling") {
		exerciseType = "gapFilling";
	} else if(string==="exercise-blocks.order-chips") {
		exerciseType = "orderChips";
	} else if(string==="exercise-blocks.bucket-sort") {
		exerciseType = "bucketSort";
	}
	return exerciseType;
}

export function extractGapFillAnswers(fragment) {
		
	var answerFields = [];
	var number = 1; 
	
	var splitArray = fragment.match(/(.*)(\([^]*\))(\[[^)]+\])(.*)/);
	
	if(splitArray) {
		splitArray.shift();
		splitArray.map((thisFragment, i) => {
			var findPrompt = thisFragment.match(/(?<=\().+?(?=\))/);
			var findAnswer = thisFragment.match(/\[([^]*)\]/);
			if(findAnswer) {
				var answersArray = findAnswer[1].split('|')
				answerFields.push({
					answers: answersArray,
					number: number,
					prompt: findPrompt ? findPrompt[0] : ""
				});
				number = number+1;
			}
		});
	}
	
	return answerFields;
}

export function checkSimilarity(a, b) {
	a = a.replace(/\s+/g,' ').trim();
	b = b.replace(/\s+/g,' ').trim();
	var aArray = a.split(' ');
	var bArray = b.split(' ');
	var similarity = 0;
	var increment = 100/aArray.length;
  var mismatches = [];
	var missingWords = [];
	var wordCount = 0;
	for(var i = 0; i < aArray.length; i++) {
		wordCount += 1;
		if(aArray[i] === bArray[i]) {
			similarity += increment;
		} else {		
			if(bArray[i]===undefined) {
				missingWords.push({
					correctWord: aArray[i]
				})
			} else {
				if(a.localeCompare(b, 'en', { sensitivity: 'base' }) === 0) {
					var type = "accent"
				} else {
					var type = "mismatch"
				}
				mismatches.push({
					userWord: bArray[i],
					correctWord: aArray[i],
					type: type
				})
			}			
		}
	}	
	return({
		errorCount: mismatches.length+missingWords.length,
		mismatches: mismatches,
		missingWords: missingWords,
		score: Math.round(similarity),
		wordCount: wordCount
	});
}

export async function saveWork({
		answersId,
		exercise,
		questions,
		saving,
		setModified,
		setSaveError,
		setSaving
	}) {
		
	console.log("Saving work…");
	
	setSaving(true);
	
	var state = "started";
	if(exercise.correctAnswers===exercise.questionCount) {
		state = "complete-fullmarks"
	}
	
	const answerData = {
		answerJSON: questions,
		correctAnswers: exercise.correctAnswers,
		unfilledAnswers: exercise.unfilledAnswers,
		state: state
	}
	
	updateExerciseAnswers(answersId, answerData)
	.then((data) => {	
		setModified(false);		
		setSaving(false);
	})
	.catch((error) => {
		setSaveError(true);
		setSaving(false);
		console.log(error);
	});
	
}