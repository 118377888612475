import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { getExternalPage } from '../../fetches';

import { Spinner } from '../../components/atoms';
import { RichTextBlockRenderer } from '../../components/renderers';

import { FAQBlock } from './externalPageComponents';

export default function ExternalPage({
		slug
	}) {
		
	const [loading, setLoading] = useState(true);
	
	const [pageData, setPageData] = useState(null);
		
	useEffect(() => {
		setLoading(true);
		getExternalPage(slug)
		.then((data) => {
			setLoading(false);
			if(data.data.length>0) {	
				console.log(data.data[0]);
				setPageData(data.data[0]);
			} else {
				window.location.replace('/not-found');
			}
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
			window.location.replace('/not-found');
		});
	}, [slug]);
			
	return(
		<div className="container external document">
			{loading ? (
				<div className="prose">
					<h1 className="skeleton">Lorem ipsum</h1>
					<p className="skeleton paragraph narrower"></p>
					<p className="skeleton paragraph short"></p>
					<p className="skeleton paragraph narrower"></p>
					<p className="skeleton paragraph"></p>
					<p className="skeleton paragraph short narrow"></p>
				</div>
			) : (
				<>
				<Helmet>
					<title>{`${pageData.title} | Learn Icelandic`}</title>
					<meta name="title" content={`${pageData.seo.metaTitle} | Learn Icelandic`} />
					<meta name="description" content={pageData.seo.metaDescription} />
				</Helmet>
				<div className="prose"><h1>{pageData.title}</h1></div>
				<div className="prose">
					<RichTextBlockRenderer content={pageData.content} />
					{pageData.contentBlocks &&
						<>
							{pageData.contentBlocks.map((thisBlock, i) => 
								<>
									{thisBlock.__component==="external-page-blocks.faq-block" &&			
										<FAQBlock content={thisBlock.faqs} key={i} />
									}
								</>
							)}
						</>
					}
				</div>
				</>
			)}			
		</div>
	)
	
}