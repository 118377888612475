import { stringify } from 'qs';
import { getToken, initialiseProgress, initialiseProgressJSON } from './helpers';

const authToken = getToken();


/* Gets */

export async function getDashboardData(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses?${q}`, {
		headers: {
			Authorization: `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const data = await response.json();
	return data;
}	

export async function getProgressData(data) {
	var q = stringify({
		filters: {
			user: {
				$eq: data.user,
			},
			course: {
				documentId: {
					$eq: data.course
				}						
			}
		}
	});
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	const progressResponse = await response.json();
	return progressResponse;
}

export async function getCourseData(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const courseResponse = await response.json();
	return courseResponse;
}

export async function getEnterpriseUsers(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const usersResponse = await response.json();
	return usersResponse;
}

export async function getCompanyData(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/enterprise-accounts?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const companyResponse = await response.json();
	return companyResponse;
}

export async function getAnswerScheme(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const answerSchemeResponse = await response.json();
	return answerSchemeResponse;
}

export async function getExternalPage(slug) {
	if(slug) {
		var q = stringify({
			filters: {
				slug: {
					$eq: slug,
				}
			},
			populate: {
				contentBlocks: {
					on: {
						'external-page-blocks.faq-block': {
							populate: '*'
						}
					}
				},
				seo: {
					populate: '*'
				}
			}
		});
		const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/external-pages?${q}`);
		if(!response.ok) {
			throw new Error(response);
		}
		const answerSchemeResponse = await response.json();
		return answerSchemeResponse;
	}
}

export async function getSignupPage() {
	var q = stringify({
		populate: {
			promoBanner: {
				populate: '*'
			}
		}
	});
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/sign-up-page?${q}`);
	if(!response.ok) {
		throw new Error(response);
	}
	const signupPageResponse = await response.json();
	return signupPageResponse;
}


export async function getStore() {
	var q = stringify({
		populate: {
			banner: {
				populate: '*'
			},
			content: {
				on: {
					'store.store-section': {
						populate: {
							highlights: {
								populate: '*'
							},
							products: {
								populate: {
									course: {
										populate: ['features']
									}
								}
							}
						}
					}
				}
			}
		}
	});
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/store?${q}`);
	if(!response.ok) {
		throw new Error(response);
	}
	const storeResponse = await response.json();
	return storeResponse;
}

export async function getVendor(id) {
	if(id) {
		var q = stringify({
			populate: ['avatar']
		});
		const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/vendors/${id}?${q}`);
		if(!response.ok) {
			throw new Error(response);
		}
		const vendorResponse = await response.json();
		return vendorResponse;
	}
}

export async function getProductReviews(productId) {
	if(productId) {
		var q = stringify({
			filters: {
				product: {
					documentId: {
						$eq: productId,
					}					
				}
			},
			populate: {
				user: {
					fields: ['country','firstName']
				}
			},
			sort: ['reviewDate:desc']
		});
		const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/product-reviews?${q}`, {
			headers: {
				'Authorization': `Bearer ${authToken}`
			}
		})
		if(!response.ok) {
			throw new Error(response);
		}
		const reviewsResponse = await response.json();
		return reviewsResponse;
	}
}


/* Creates */

export async function createProgressEntry(data) {	
	const q = stringify({
		populate: {
			lessons: {
				populate: {
					lessonPages: {
						populate: '*',
						sort: ['pageNo:asc']
					}
				}
			}
		}
	});
	const course = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses/${data.courseId}?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	const courseResponse = await course.json();
	if(courseResponse.data) {
		const progressJSON = initialiseProgressJSON(courseResponse.data.lessons);
		const newProgressData = {
			data: {
				user: data.user,
				course: data.courseId,
				lessonProgress: progressJSON
			}
		}
		const saveProgress = initialiseProgress(newProgressData);
		return saveProgress;
	}
}

export async function createNewAnswerEntry(newAnswerData) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(newAnswerData)
	})
	const answerResponse = await response.json();
	return answerResponse;
}	


/* Updates */

export async function updateUserData(user, data) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user}`, {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});
	const updateUserDataResponse = await response.json();
	return updateUserDataResponse;
}

export async function updateEnterpriseSettings(documentId, data) {	
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/enterprise-accounts/${documentId}`, {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			data: data
		})
	})
	const enterpriseSettingsResponse = await response.json();
	return enterpriseSettingsResponse;
}

export async function updateExerciseAnswers(documentId, data) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers/${documentId}`, {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			data: data
		})
	})
	const exerciseAnswersResponse = await response.json();
	return exerciseAnswersResponse;
}


/* Delete */

export async function deleteCourseProgress(documentId) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses/${documentId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		}
	})
	const deleteCourseProgressResponse = await response;
	return deleteCourseProgressResponse;
}

export async function deleteExerciseAnswers(documentId) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers/${documentId}`, {
		method: 'DELETE',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		}
	})
	const deleteExerciseAnswersResponse = await response;
	return deleteExerciseAnswersResponse;
}


/* Mailing list */

export async function subscribeMailingList(formData) {
	const signupQ = stringify({
		email: formData.email,
		firstName: formData.firstName,
		referral: "signup"
	});
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/subscribeMailingList?${signupQ}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${process.env.REACT_APP_NODE_BACKEND_AUTH_TOKEN}`
		},
	})
	if(!response.ok) {
		console.log(response);
		throw new Error(response);
	}
	const subscribeMailingListResponse = await response.json();
	return subscribeMailingListResponse;
}

export async function unsubscribeMailingList(identifier) {
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/unsubscribeMailingList?id=${identifier}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${process.env.REACT_APP_NODE_BACKEND_AUTH_TOKEN}`
		},
	})
	if(!response.ok) {
		console.log(response);
		throw new Error(response);
	}
	const unsubscribeMailingListResponse = await response.json();
	return unsubscribeMailingListResponse;
}

export async function getMailingListIdentifier(email) {
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/getMailingListIdentifier?email=${email}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${process.env.REACT_APP_NODE_BACKEND_AUTH_TOKEN}`
		},
	})
	if(!response.ok) {
		console.log(response);
		throw new Error(response);
	}
	const getMailingListIdentifierResponse = await response.json();
	return getMailingListIdentifierResponse;
}


/* User management */

export async function registerUser(data) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local/register`, {
		method: 'POST',
		headers: {
			'Strapi-Response-Format': 'v4',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const signupResponse = await response.json();
	return signupResponse;
}	

export async function inviteEnterpriseUser(data) {
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/inviteEnterpriseUser`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const inviteEnterpriseUserResponse = await response.json();
	return inviteEnterpriseUserResponse;
}

export async function verifyToken(data) {
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/verifyToken`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const verifyTokenResponse = await response.json();
	return verifyTokenResponse;
}