import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SpecialCharsButton, ToggleButtons } from '../atoms';

import { deleteExerciseAnswers } from '../../fetches';

import { saveWork } from './exerciseHelpers';

import { RenderGapFill } from './gapFill';
import { RenderOrderChips, ChipsDemo } from './orderChips';
import { RenderBucketSort } from './bucketSort';


export function RenderExercise({
		content,
		exercise,
		exerciseType,
		modified,
		questions,
		setExercise,
		setModified,
		setQuestions
	}) {
		
	if(content.exercise.data.attributes.content[0].buckets) {
		var	buckets = content.exercise.data.attributes.content[0].buckets;
	}
		
	return (
		<>
		{(exerciseType==="gapFilling") && (
			<RenderGapFill 
				content={content}
				exercise={exercise}
				modified={modified}
				questions={questions}
				setExercise={setExercise}
				setModified={setModified}
				setQuestions={setQuestions}
			/>									
		)}
		{(exerciseType==="orderChips") && (
			<ol className="chips">
				{questions.map((thisRow, i) =>
					<RenderOrderChips
						content={thisRow}
						exercise={exercise}
						key={i}
						modified={modified}
						questions={questions}
						setExercise={setExercise}
						setModified={setModified}
						setQuestions={setQuestions}
					/>
				)}			
			</ol>
		)}
		{(exerciseType==="bucketSort") && (
			<RenderBucketSort
				buckets={buckets}
				content={content}
				mode="one-up"
				questions={questions}
				setModified={setModified}
				setQuestions={setQuestions}
			/>
		)}
		</>
	)
	
}

export function ExerciseLoadingSkeleton() {
	return(
		<ol>
			<li className="exercise-answer skeleton"></li>
			<li className="exercise-answer skeleton"></li>
			<li className="exercise-answer skeleton"></li>
			<li className="exercise-answer skeleton"></li>
			<li className="exercise-answer skeleton"></li>
		</ol>
	)
}

export function Mark({ 
		answersID,
		exercise,
		questions,
		saving,
		setModified,
		setSaveError,
		setSaving
	}) {
		
	const { t } = useTranslation();
	
	const [status, setStatus] = useState("");
	const [message, setMessage] = useState(undefined);
	
	function handleSaveWork() {
		saveWork({
			answersId: answersID,
			exercise: exercise,
			questions: questions,
			saving: saving,
			setModified: setModified,
			setSaveError: setSaveError,
			setSaving: setSaving
		});
	}
	
	useEffect(() => {
		if(exercise.correctAnswers === exercise.questionCount) {
			setMessage("💪 Vel gert!");
			setStatus("complete");
		} else {
			setMessage(undefined);
			if(exercise.incorrectAnswers === 0 && exercise.unfilledAnswers === 0) {
				setStatus("complete-partmark");
			} else {				
				setStatus("incomplete");
			}
		}
	}, [exercise.correctAnswers]);
	
	return(
		<div className={`mark ${status}`}>
			{status === "incomplete" && (
				<Button
					disabled={saving}
					icon="save"
					label={saving ? t('lessonPage.exercise.buttonSaving') : t('lessonPage.exercise.buttonSaveWork')}
					onClick={handleSaveWork}
					role="secondary"
				/>
			)}
			<span>{message}</span>
			<div className="mark-wrapper">
				{exercise.correctAnswers}
			</div>
		</div>
	)
}

export function ExerciseToolbar({
		answersID,
		dialogVisible,
		exercise,
		exerciseType,
		questions,
		setDialogVisible,
		setExercise,
		setQuestions
	}) {
			
	const { t } = useTranslation();
	
	const [dropdownVisible, setDropdownVisible] = useState(false);
		
	function handleShowKeyboard() {
		document.getElementById('keyboard').classList.toggle('visible');
		var lastFocus = localStorage.getItem("lastFocus");
		lastFocus = document.getElementById(lastFocus);
		lastFocus.focus();
	}
		
	function handleDropdownToggle(menu) {
		if(dropdownVisible) {			
			setDropdownVisible(false);
		} else {			
			setDropdownVisible(menu);
		}		
	}
	
	function handleLiveMarkingToggle() {
		handleDropdownToggle();
	}
	
	function handleRevealAnswers() {
		handleDropdownToggle();
	}
	
	function handleClearAnswers() {
		handleDropdownToggle();
		setDialogVisible('clear-answers');
	}
	
	const toggleButtonLiveMarking = [{
			title: 'On',
			value: true
		},
		{
			title: 'Off',
			value: false
		}
	];
	
	const ref = useRef(null);
	
	useEffect(() => {
		if(dropdownVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dropdownVisible]);
	
	return(
		<div className="toolbar-buttons">
			{exerciseType==="gapFilling" && (
				<SpecialCharsButton onClick={handleShowKeyboard} />
			)}		
			<Button
				icon="options"
				role={`toolbar${dropdownVisible ? ( " active" ) : ( "" ) }`}
				onClick={e => handleDropdownToggle("exercise")}
			/>
			<div className={`action-menu-wrapper${dropdownVisible === 'exercise' ? (' visible') : ('')}`} onClick={handleDropdownToggle}>
			</div>
			<div className={`dropdown action-menu exercise-dropdown${dropdownVisible === 'exercise' ? (' visible') : ('')}`}>
				{/* <ul>
					<li className="padded">
						<div className="icon">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.6875 11.7188C10.3125 12.125 9.65625 12.125 9.28125 11.7188L6.78125 9.21875C6.375 8.84375 6.375 8.1875 6.78125 7.8125C7.15625 7.40625 7.8125 7.40625 8.1875 7.8125L9.96875 9.59375L14.2812 5.3125C14.6562 4.90625 15.3125 4.90625 15.6875 5.3125C16.0938 5.6875 16.0938 6.34375 15.6875 6.71875L10.6875 11.7188ZM10.6875 18.7188C10.3125 19.125 9.65625 19.125 9.28125 18.7188L5.28125 14.7188C4.875 14.3438 4.875 13.6875 5.28125 13.3125C5.65625 12.9062 6.3125 12.9062 6.6875 13.3125L9.96875 16.5938L17.2812 9.3125C17.6562 8.90625 18.3125 8.90625 18.6875 9.3125C19.0938 9.6875 19.0938 10.3438 18.6875 10.7188L10.6875 18.7188Z" fill="#0B00D1"/>
							</svg>																															
						</div>
						<div className="dropdown-options-wrapper">													
							Live marking
							<ToggleButtons
								items={toggleButtonLiveMarking}
								value={true}
								onClick={handleLiveMarkingToggle}
							/>
						</div>
					</li>
				</ul> */}
				<ul>
					{/* <li className="linkless" onClick={handleRevealAnswers}>
						<div className="icon">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12C5.5 15.5938 8.40625 18.5 12 18.5ZM12 14.5C12.5312 14.5 13 14.9375 13 15.5C13 16.0625 12.5312 16.5 12 16.5C11.4062 16.5 11 16.0625 11 15.5C11 14.9375 11.4375 14.5 12 14.5ZM13.0312 8C14.2812 8 15.25 8.96875 15.2188 10.1875C15.2188 10.9375 14.8125 11.6562 14.1562 12.0625L12.75 12.9375V13C12.75 13.4062 12.4062 13.75 12 13.75C11.5938 13.75 11.25 13.4062 11.25 13V12.5C11.25 12.25 11.375 12 11.625 11.8438L13.4062 10.7812C13.625 10.6562 13.75 10.4375 13.75 10.1875C13.75 9.8125 13.4062 9.5 13 9.5H11.4062C11.0312 9.5 10.75 9.8125 10.75 10.1875C10.75 10.5938 10.4062 10.9375 10 10.9375C9.59375 10.9375 9.25 10.5938 9.25 10.1875C9.25 8.96875 10.2188 8 11.4375 8H13.0312Z" fill="#0B00D1"/>
							</svg>																			
						</div>
						Reveal answers
					</li> */}
					<li className="linkless danger" onClick={handleClearAnswers}>
						<div className="icon">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.21875 4.5625C9.375 4.21875 9.71875 4 10.0938 4H13.875C14.25 4 14.5938 4.21875 14.75 4.5625L15 5H18C18.5312 5 19 5.46875 19 6C19 6.5625 18.5312 7 18 7H6C5.4375 7 5 6.5625 5 6C5 5.46875 5.4375 5 6 5H9L9.21875 4.5625ZM17.3125 18.5938C17.2812 19.4062 16.625 20 15.8125 20H8.15625C7.34375 20 6.6875 19.4062 6.65625 18.5938L5.96875 8H18L17.3125 18.5938Z" fill="#EC1801"/>
							</svg>																	
						</div>
						{t('lessonPage.exercise.actionMenu.clearAnswers')}
					</li>
				</ul>
			</div>
		</div>
	)	
}

export function Keyboard({
		blurOrigin,
		setBlurOrigin
	}) {
	
	function handleCloseKeyboard() {
		document.getElementById('keyboard').classList.remove('visible');
	}
	
	function handleInsertCharacter(char) {
		setBlurOrigin("specialChars");
		var lastFocus = localStorage.getItem("lastFocus");
		lastFocus = document.getElementById(lastFocus);
		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
		var newValue = lastFocus.value+char;
		var startPos = lastFocus.selectionStart;
		var endPos = lastFocus.selectionEnd;
		if(startPos || startPos == '0') {
			newValue = lastFocus.value.substring(0, startPos)
					+ char
					+ lastFocus.value.substring(endPos, lastFocus.value.length);	
		}
		nativeInputValueSetter.call(lastFocus, newValue);
		var inputEvent = new Event('input', { bubbles: true});
		lastFocus.dispatchEvent(inputEvent);
		lastFocus.focus();
		lastFocus.selectionStart = startPos+char.length; 
		lastFocus.selectionEnd = startPos+char.length;
	}
	
	return(
		<div id="keyboard">
			<div className="keys">
				<div className="key-wrapper">
					<Button label="á" onClick={() => handleInsertCharacter('á')} />
					<Button label="ð" onClick={() => handleInsertCharacter('ð')} />
					<Button label="é" onClick={() => handleInsertCharacter('é')} />
					<Button label="í" onClick={() => handleInsertCharacter('í')} />
					<Button label="ó" onClick={() => handleInsertCharacter('ó')} />
					<Button label="ú" onClick={() => handleInsertCharacter('ú')} />
					<Button label="ý" onClick={() => handleInsertCharacter('ý')} />
					<Button label="þ" onClick={() => handleInsertCharacter('þ')} />
					<Button label="æ" onClick={() => handleInsertCharacter('æ')} />
					<Button label="ö" onClick={() => handleInsertCharacter('ö')} />					
				</div>
			</div>
			<div className="key-shader"></div>
			<button className="specialchars close" onClick={handleCloseKeyboard}>
				<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.3594 10.6406C12.8672 11.1094 12.8672 11.9297 12.3594 12.3984C12.125 12.6328 11.8125 12.75 11.5 12.75C11.1484 12.75 10.8359 12.6328 10.6016 12.3984L6.5 8.29688L2.35938 12.3984C2.125 12.6328 1.8125 12.75 1.5 12.75C1.14844 12.75 0.835938 12.6328 0.601562 12.3984C0.09375 11.9297 0.09375 11.1094 0.601562 10.6406L4.70312 6.5L0.601562 2.39844C0.09375 1.92969 0.09375 1.10938 0.601562 0.640625C1.07031 0.132812 1.89062 0.132812 2.35938 0.640625L6.5 4.74219L10.6016 0.640625C11.0703 0.132812 11.8906 0.132812 12.3594 0.640625C12.8672 1.10938 12.8672 1.92969 12.3594 2.39844L8.25781 6.53906L12.3594 10.6406Z" fill="#1C1E21"/>
				</svg>				
			</button>
		</div>
	)
}

export function ClearAnswersDialog({ 
		answersID,
		visible,
		setVisible
	}) {
	
	const { t } = useTranslation();
		
	const [disabled, setDisabled] = useState(false);
	
	function handleCloseDialog() {
		setVisible(null);
	}
	
	function handleClearAnswers() {
		setDisabled(true);
		deleteExerciseAnswers(answersID)
		.then((response) => {
			if(response.status===204) {
				window.location.reload();
			}			
			if(response.status===500) {
				console.log(response);
			}	
		})
	}
		
	return(
		<div className={`dialog-shader${visible === 'clear-answers' ? (' visible') : ('')}`}>
			<dialog className="dialog">
				<div className="rows dialog-content">		
					<div className="row">
						<div className="prose">
							<h2>{t('lessonPage.exercise.clearAnswersDialog.heading')}</h2>
							<p>{t('lessonPage.exercise.clearAnswersDialog.areYouSure')}</p>
						</div>
					</div>		
					<div className="row">
						<Button 
							disabled={disabled ? true : false}
							icon="delete"
							onClick={handleClearAnswers}
							role="secondary full-width">
							{t('lessonPage.exercise.clearAnswersDialog.buttonClearAnswers')}
						</Button>
					</div>	
				</div>
				<div className="rows anchored-buttons">	
					<div className="row">
						<Button
							disabled={disabled ? true : false}
							onClick={handleCloseDialog}
							role="full-width">
							{t('lessonPage.exercise.clearAnswersDialog.buttonCancel')}
						</Button>
					</div>	
				</div>
			</dialog>
		</div>
	)
	
}	
