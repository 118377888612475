import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useAuthContext } from '../../contexts';
import { setToken } from '../../helpers';

import { Button } from '../../components/atoms';

export default function ResetPassword() {
	
	const { setUser } = useAuthContext();
	
	const [searchParams, setSearchParams] = useSearchParams();
	
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	
	const [passwordReset, setPasswordReset] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	async function handleResetPassword(formData) {		
		if(formData.newPassword !== formData.confirmNewPassword) {
			setError("The passwords do not match.");
		}
		if(!error) {			
			setLoading(true);
			try {
				const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/reset-password`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						code: searchParams.get("code"),
						password: formData.password,
						passwordConfirmation: formData.confirmPassword
					}),
				});	
				const data = await response.json();
				if (data?.error) {
					throw data?.error;
				} else {
					console.log(data);
					setToken(data.jwt);
					setUser(data.user);	
					setAuthenticated(true);
					window.location.replace('/dashboard');
				}
			} catch (error) {
				console.error(error);	
			} finally {
				setLoading(false);
			}
		}
	}
	
	return(
		<>
			<div className="container account external">
				<div className="blurb">
					<h1>Reset password</h1>
				</div>	
				<div className="card">
					{error && (
						<div className="placard error">						
							{error.message}
						</div>
					)}
					{errors.email?.type === 'required' && (
						<div className="placard error">Please enter your email address ✉️</div>
					)}
					{errors.email?.type === 'pattern' && (
						<div className="placard error">Please enter a valid email address ✉️</div>
					)}
					{errors.password?.type === 'required' && (
						<div className="placard error">Please enter your password 🔐</div>
					)}
					{authenticated && (
						<div className="placard success">Logged in! 😎</div>
					)}
					<div className="centred-content">
						<form onSubmit={handleSubmit(handleResetPassword)}>
							<div className="rows">							
								<div className="row">
									<label htmlFor="password" className="row-label">New password</label>
									<div className="vertical-row-content no-padding">
										<input 
											autofocus="autofocus"
											type="password" 
											id="password" 
											name="password" 
											className="account-form"
											{...register('password', { required: true })}
											aria-invalid={errors.password ? "true" : "false"}
										/>
									</div>
								</div>
								<div className="row">
									<label htmlFor="confirm-password" className="row-label">Confirm new password</label>
									<div className="vertical-row-content no-padding">
										<input 
											type="password" 
											id="confirm-password" 
											name="confirm-password" 
											className="account-form"
											{...register('confirmPassword', { required: true })}
											aria-invalid={errors.confirmPassword ? "true" : "false"}
										/>

									</div>
								</div>
								<div className="row centred">						
									{loading ? (
										<Button	label="Saving new password…" disabled={true} />			
									) : (
										<button type="submit">Save new password</button>			
									)}							
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)	
	
}
