import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { usePostHog } from 'posthog-js/react';

import { getMailingListIdentifier, subscribeMailingList, unsubscribeMailingList, updateUserData } from '../../fetches';
import { useAuthContext } from '../../contexts';

import { formatDate, getToken, removeToken } from '../../helpers';
import { Button, Spinner } from '../../components/atoms';

export function DetailsSection() {
	
	const authToken = getToken();
	const { setUser } = useAuthContext();
	const user = useAuthContext();	
	
	const { t, i18n } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();
	
	async function handleUpdateUserDetails(formData) {
		setSuccess(false);
		var dataChanged = false;
		if(formData.firstName !== user.user.firstName) {
			dataChanged = true;
		}
		if(formData.email !== user.user.email) {
			dataChanged = true;
		}
		if(formData.gender !== user.user.gender) {
			dataChanged = true;
		}
		if(dataChanged) {			
			setLoading(true);
			try {
				const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user.user.id}`, {
					method: 'PUT',
					headers: {
						'Strapi-Response-Format': 'v4',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${authToken}`
					},
					body: JSON.stringify(formData),
				});
				const responseData = await response.json();		
				if(responseData.error) {
					setError(responseData.error.message);
				} else {
					reset();
					setUser({
						...user,
						user: responseData
					});
					setSuccess(true);
					setError(false);
				}	
			} catch (error) {
				console.error(Error);
			} finally {
				setLoading(false);
			}
		}	
	}
	
	return(
		<section className="card account-section">
			<h2>{t('account.personalDetails.personalDetails')}</h2>								
			{success && (
				<div className="placard success">
					{t('account.personalDetails.saved')}
				</div>
			)}	
			{error && (
				<div className="placard error">
					{error === "Email already taken" && "That email address is registered to another account."}
				</div>
			)}
			{errors.firstName?.type === 'required' && (
				<div className="placard error">Please enter your first name 👤</div>
			)}
			{errors.email?.type === 'required' && (
				<div className="placard error">Please enter your email address ✉️</div>
			)}
			{errors.email?.type === 'pattern' && (
				<div className="placard error">Please enter a valid email address ✉️</div>
			)}
			<div className="centred-content">
				<form onSubmit={handleSubmit(handleUpdateUserDetails)}>
					<div className="rows">
						<div className="row">
							<label htmlFor="firstName" className="row-label"><strong>{t('account.personalDetails.firstName')}</strong></label>
							<input 
								type="text" 
								id="firstName" 
								name="firstName" 
								className="account-form" 
								defaultValue={user.user.firstName} 
								{...register('firstName', { required: true })}
								aria-invalid={errors.firstName ? "true" : "false"}
								onFocus={() => setSuccess(false)}
							/>
						</div>
						<div className="row">
							<label htmlFor="email" className="row-label">{t('account.personalDetails.email')}</label>
							<input 
								type="email" 
								id="email" 
								name="email" 
								className="account-form" 
								defaultValue={user.user.email} 
								{...register('email', { required: true, pattern: /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/ })} 
								aria-invalid={errors.email ? "true" : "false"}
								onFocus={() => setSuccess(false)}
							/>
						</div>
						<div className="row">
							<label htmlFor="firstname" className="row-label">{t('account.personalDetails.gender.gender')}</label>
							<div className="vertical-row-content">
								<div className="radio-wrapper">
									<div className="radio-button-wrapper">
										<input 
											type="radio" 
											id="male" 
											name="gender" 
											value="m" 
											defaultChecked={user.user.gender === 'm'} 
											{...register('gender')}												
										/>
										<label htmlFor="male">{t('account.personalDetails.gender.male')}</label>							
									</div>							
									<div className="radio-button-wrapper">
										<input 
											type="radio" 
											id="female" 
											name="gender" 
											value="f" 
											defaultChecked={user.user.gender === 'f'} 
											{...register('gender')}
										/>
										<label htmlFor="female">{t('account.personalDetails.gender.female')}</label>
									</div>
									<div className="radio-button-wrapper">
										<input 
											type="radio" 
											id="nonbinary" 
											name="gender" 
											value="nb" 
											defaultChecked={user.user.gender === 'nb'} 
											{...register('gender')}
										/>
										<label htmlFor="nonbinary">{t('account.personalDetails.gender.nonbinary')}</label>
									</div>
								</div>
								<div className="explanation">{t('account.personalDetails.gender.explanation')}</div>
							</div>
						</div>
						<div className="row centred">				
							{loading ? (											
								<Button	label={t('account.personalDetails.saving')} disabled={true} />
							) : (											
								<Button	label={t('account.personalDetails.saveChanges')} onClick={handleUpdateUserDetails} />
							)}											
						</div>
					</div>
				</form>
			</div>	
		</section>
	)	
}

export function PasswordSection({
		dialogVisible,
		setDialogVisible
	}) {
	
	const { t, i18n } = useTranslation();
	
	return(
		<section className="card account-section">
			<h2>{t('account.password.password')}</h2>
			<div className="centred-content padded">
				<div className="rows">
					<div className="row">
						<Button											
							fullWidth="true" 
							label={t('account.password.buttonChangePassword')} 
							onClick={() => setDialogVisible('password')}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export function AccountSection({
		dialogVisible,
		setDialogVisible
	}) {
	
	const posthog = usePostHog();
	const navigate = useNavigate();
	
	const { t } = useTranslation();
	
	function handleLogout() {
		removeToken();
		posthog?.capture('user_logged_out');
		posthog?.reset();
		navigate('/', { replace: true });
	}
	
	return(
		<section className="card account-section">
			<h2>{t('account.accountManagement.accountManagement')}</h2>
			<div className="centred-content padded">
				<div className="rows">
					<div className="row">
						<Button	
							fullWidth="true"
							label={t('account.accountManagement.logOut')} 
							icon="logOut" 
							onClick={handleLogout} 
						/>
					</div>
					<div className="row">								
						<div className="vertical-row-content">
							<Button	
								label={t('account.accountManagement.deleteAccount')} 
								role="secondary" 
								icon="delete" 
								fullWidth="true" 
								onClick={() => setDialogVisible('delete')}
							/>
							<div className="explanation">{t('account.accountManagement.explanation')}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
	
}

export function CommsSection() {
	
	const { t } = useTranslation();	
	
	const user = useAuthContext();
	
	const [sendgridIdentifier, setSendgridIdentifier] = useState(null);
	
	const [loading, setLoading] = useState(false);
	
	const [saving, setSaving] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	useEffect(() => {
		if(user.user.mailingListOptIn && !sendgridIdentifier) {
			setLoading(true);
			getMailingListIdentifier(user.user.email)
			.then((data) => {
				setLoading(false);
				setSendgridIdentifier(data.data.identifier);
				console.log(data.data.identifier);
			})
			.catch((error) => {
				console.log(error);
			});
		}
	}, [sendgridIdentifier]);
	
	async function updateCommsPrefs(formData) {
		if(formData.newsletterOptin===true) {	
			setSaving(true);
			const response = await subscribeMailingList(formData)
			if(!response.ok) {
				setSaving(false);
				console.log(error);
			}
			const subscribeMailingListResponse = await response;
			if(subscribeMailingListResponse) {
				const userData = {
					mailingListOptIn: true
				}
				updateUserData(user.user.id, userData)
				.then((data) => {
					setSendgridIdentifier(null);
					setSaving(false);
					setSuccess(true);
				})
				.catch((error) => {
					setSaving(false);
					console.log(error);
				})
			}
		} else if(formData.newsletterOptin===false) {
			if(sendgridIdentifier) {
				setSaving(true);
				const response = await unsubscribeMailingList(sendgridIdentifier)
				if(!response.ok) {
					setSaving(false);
					console.log(error);
				}
				const unsubscribeMailingListResponse = await response;
				if(unsubscribeMailingListResponse) {
					const userData = {
						mailingListOptIn: false
					}
					updateUserData(user.user.id, userData)
					.then((data) => {
						setSendgridIdentifier(null);
						setSaving(false);
						setSuccess(true);
					})
					.catch((error) => {
						setSaving(false);
						console.log(error);
					})
				}
			}	else {
				setError(true);
			}	
		}
	}
	
	return(
		<section className="card account-section">
			<h2>{t('account.commsPrefs.commsPrefs')}</h2>
			{error && (
				<div className="placard error">
					{t('account.commsPrefs.error')}
				</div>
			)}	
			{(success && !error) && (
				<div className="placard success">
					{t('account.commsPrefs.saved')}
				</div>
			)}	
			<div className="centred-content padded">
				<form className="rows" onSubmit={handleSubmit(updateCommsPrefs)}>
					<div className="row">
						<div className="checkbox-wrapper">
							<input
								id="email"
								name="email"		
								{...register('email')} 			
								type="hidden"
								value={user.user.email}
							/>
							<input
								id="firstName"
								name="firstName"		
								{...register('firstName')} 			
								type="hidden"
								value={user.user.firstName}
							/>
							<input
								defaultChecked={user.user.mailingListOptIn}
								disabled={(loading || saving) ? true : false}
								id="newsletterOptin"
								name="newsletterOptin"		
								{...register('newsletterOptin')} 			
								type="checkbox"
							/>
							<label htmlFor="newsletterOptin">{t('account.commsPrefs.mailingListOptInText')}</label>
						</div>
					</div>
					<div className="row">								
						<div className="vertical-row-content">
							<Button
								fullWidth="true"
								disabled={(loading || saving) ? true : false}
								>
								{saving ? t('account.commsPrefs.buttonSaving') : t('account.commsPrefs.buttonSaveChanges')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</section>
	)
	
}

export function AdvancedSection() {
	
	const authToken = getToken();
	const user = useAuthContext();
	
	const navigate = useNavigate();
	
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	
	async function handleDeleteExerciseAnswers() {
		setDisabled(true);
		setLoading(true);
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/delete-exercise-answers/${user.user.id}`, {
			method: 'DELETE',
			headers: {
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			setDisabled(false);
			setLoading(false);
		})
		.catch((error) => {
			console.log(error);
			setDisabled(false);
			setLoading(false);
		});
	}
	
	function handleClearCache() {
		localStorage.clear();
		navigate('/', { replace: true });
	}
	
	return(
		<section className="card account-section">
			<h2>{t('account.advanced.advanced')}</h2>
			<div className="centred-content padded">
				<div className="rows">
					<div className="row">								
						<div className="vertical-row-content">
							<Button	
								disabled={disabled}
								label={t('account.advanced.buttonClearExerciseAnswers')}
								role="secondary" 
								icon="delete" 
								fullWidth="true" 
								loading={loading}
								onClick={handleDeleteExerciseAnswers}
							/>
						</div>
					</div>
					<div className="row">								
						<div className="vertical-row-content">
							<Button	
								label={t('account.advanced.buttonClearCache')}
								role="secondary" 
								icon="delete" 
								fullWidth="true" 
								onClick={handleClearCache}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
	
}