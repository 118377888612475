import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { Button } from '../../components/atoms';

export function MailingListForm() {
	
	const [disabled, setDisabled] = useState(false);
	const [signupSuccess, setSignupSuccess] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	const navigate = useNavigate();
	
	function handleMailingListSignup(formData) {
		setDisabled(true);
		const signupQ = stringify({
			email: formData.email,
			firstName: formData.firstName,
			referral: "homepage"
		});
		fetch(`${process.env.REACT_APP_NODE_BACKEND}/subscribeMailingList?${signupQ}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${process.env.REACT_APP_NODE_BACKEND_AUTH_TOKEN}`
			},
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			if(!data.error) {
				setSignupSuccess(true);
			} else {
				console.error(data.error.message);
			}			
		})
		.catch((error) => {
			console.log(error);
		});
	}
	
	useEffect(() => {
		if(!signupSuccess) {
			document.getElementById('firstName').focus();
		}		
	}, [signupSuccess]);
	
	return(
		<>
			{signupSuccess ? (
				<div className="signup-success">
					<img src="/images/icon-success.svg" width="42" />
					Thanks for registering for updates! We’ll keep you posted via email.
				</div>
			) : (
			<form id="mailing-list" onSubmit={handleSubmit(handleMailingListSignup)}>
				<input 
					type="text"
					id="firstName"
					name="firstName"
					placeholder="First name"
					spellCheck="off"
					{...register('firstName', { required: true })}
					aria-invalid={errors.firstName ? "true" : "false"}
				/>
				<input 
					type="email"
					id="email"
					name="email"
					placeholder="Email address"
					spellCheck="off"
					{...register('email', { required: true, pattern: /^[-a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
					aria-invalid={errors.email ? "true" : "false"}
				/>
				<Button disabled={disabled}>Register for updates</Button>
			</form>
		)}
		</>
	)
	
}