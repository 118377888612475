export function countActiveUsers(users) {
	var activeUsers = 0;
	for (const thisUser of users) {
		if(thisUser.enterpriseAccountActive===true) {
			activeUsers = activeUsers+1;
		}
	}
	return activeUsers;	
}

export function countAdminUsers(users) {
	var adminUsers = 0;
	for (const thisUser of users) {
		if(thisUser.enterpriseAccountRole==="admin") {
			adminUsers = adminUsers+1;
		}
	}
	return adminUsers;	
}