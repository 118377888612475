import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'qs';

import { useAuthContext } from '../contexts';

import { Button, ProgressDonut, Spinner	 } from '../components/atoms';

export default function CourseCard({
		content,
		buttonEvent, 
		buttonText,
		hidePrice,
		link
	}) {
		
	const user = useAuthContext();
		
	const [loading, setLoading] = useState(true);
	const [squarePaymentLink, setSquarePaymentLink] = useState(null);
		
	var disabled = false;
	
	if(!content.available) {
		buttonText = "Coming soon"
		disabled = true;
	} else {
		buttonText = "Find out more"
	}
	
	var buttonDisabled = null;
	
	if(disabled) {
		buttonDisabled = "disabled";
	}
	
	return(
		<div className={`card store-course-card${disabled ? " disabled" : ""}`}>
			<div className="course-info">
				<div className="course-blurb">
					<h3 className="course-name">
						{content.name}
						<div className="product-level-badge">{content.levelCEFR}</div>
					</h3>
					<p>{content.cardTagline}</p>
				</div>
				{!content.purchased && (
					<div className="course-details">
						<h4>What you’ll learn</h4>
						<ul>
							{content.features.map((thisFeature, i) =>								
								<li key={i}>
									<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
									</svg>																
									{thisFeature.feature}
								</li>
							)}		
						</ul>					
					</div>
				)}				
			</div>
			<div className="course-price-cta">
				{!content.purchased && (
				<div className="course-details">
						<h4>Details</h4>
						<ul>
							<li>
								<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.75 4.5H12.25V3.25C12.25 2.84375 12.5625 2.5 13 2.5C13.4062 2.5 13.75 2.84375 13.75 3.25V4.5H15C16.0938 4.5 17 5.40625 17 6.5V16.5C17 17.625 16.0938 18.5 15 18.5H5C3.875 18.5 3 17.625 3 16.5V6.5C3 5.40625 3.875 4.5 5 4.5H6.25V3.25C6.25 2.84375 6.5625 2.5 7 2.5C7.40625 2.5 7.75 2.84375 7.75 3.25V4.5ZM4.5 16.5C4.5 16.7812 4.71875 17 5 17H15C15.25 17 15.5 16.7812 15.5 16.5V8.5H4.5V16.5Z" fill="#1C1E21"/>
								</svg>							
								<span><strong>Recommended time</strong><br />{content.duration} weeks</span>
							</li>
							<li>
								<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.6562 3.28125C15.5312 2.40625 16.9375 2.40625 17.8125 3.28125L18.2188 3.6875C19.0938 4.5625 19.0938 5.96875 18.2188 6.84375L12.0312 13.0312C11.7812 13.2812 11.4375 13.5 11.0625 13.5938L7.9375 14.5C7.6875 14.5625 7.40625 14.5 7.21875 14.2812C7 14.0938 6.9375 13.8125 7 13.5625L7.90625 10.4375C8 10.0625 8.21875 9.71875 8.46875 9.46875L14.6562 3.28125ZM16.75 4.34375C16.4688 4.0625 16 4.0625 15.7188 4.34375L14.7812 5.25L16.25 6.71875L17.1562 5.78125C17.4375 5.5 17.4375 5.03125 17.1562 4.75L16.75 4.34375ZM9.34375 10.8438L8.8125 12.6875L10.6562 12.1562C10.7812 12.125 10.875 12.0625 10.9688 11.9688L15.1875 7.75L13.75 6.3125L9.53125 10.5312C9.4375 10.625 9.375 10.7188 9.34375 10.8438ZM9.25 4.5C9.65625 4.5 10 4.84375 10 5.25C10 5.6875 9.65625 6 9.25 6H5.75C5.03125 6 4.5 6.5625 4.5 7.25V15.75C4.5 16.4688 5.03125 17 5.75 17H14.25C14.9375 17 15.5 16.4688 15.5 15.75V12.25C15.5 11.8438 15.8125 11.5 16.25 11.5C16.6562 11.5 17 11.8438 17 12.25V15.75C17 17.2812 15.75 18.5 14.25 18.5H5.75C4.21875 18.5 3 17.2812 3 15.75V7.25C3 5.75 4.21875 4.5 5.75 4.5H9.25Z" fill="#1C1E21"/>
								</svg>
								<span><strong>Assessment</strong><br />{content.assessmentType}</span>
							</li>
						</ul>
					</div>
				)}
				{!hidePrice && 
					<div className="course-pricing">
						<div className="price-detail">
							{content.promoText &&
								<div className="promo-text">
									<svg className="icon small-med" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M4.125 3.25H7.61719C8.01562 3.25 8.39062 3.41406 8.67188 3.69531L12.7969 7.82031C13.3828 8.40625 13.3828 9.36719 12.7969 9.95312L9.67969 13.0703C9.09375 13.6562 8.13281 13.6562 7.54688 13.0703L3.42188 8.94531C3.14062 8.66406 3 8.28906 3 7.89062V4.375C3 3.76562 3.49219 3.25 4.125 3.25ZM5.625 6.625C6.02344 6.625 6.375 6.29688 6.375 5.875C6.375 5.47656 6.02344 5.125 5.625 5.125C5.20312 5.125 4.875 5.47656 4.875 5.875C4.875 6.29688 5.20312 6.625 5.625 6.625Z" fill="#EC1801"/>
									</svg>
									{content.promoText}
								</div>
							}
						</div>
						{!content.purchased && (
							<p className="price">£{content.price}</p>
						)}					
					</div>
				}				
				{content.purchased ? (
					<Link to={`/${content.slug}`}>
						<Button
							icon="next"
							iconPlacement="after"
							label="Open course"
							role="secondary"
						/>
					</Link>
				) : ( 
					<>
						{disabled ? (
							<Button 
								disabled={disabled}
								icon="next"
								iconPlacement="after">
								{buttonText}
							</Button>
						) : (
							<Link to={`/store/p/${content.slug}`}>
								<Button 
									disabled={disabled}
									icon="next"
									iconPlacement="after">
									{buttonText}
								</Button>
							</Link>
						)}		
					</>			
				)}	
			</div>			
		</div>
	)
	
}