import { useState } from 'react';

import { RichTextBlockRenderer } from '../../components/renderers';

export function FAQBlock({
		content
	}) {
		
	console.log(content);
		
	return(
		<div className="faq-block">
			{content.map((thisFAQ, i) => 
				<FAQ content={thisFAQ} key={i} />
			)}
		</div>
	)
	
}

function FAQ({
		content
	}) {
		
	const [answerVisible, setAnswerVisible] = useState(false);
		
	function handleToggleAnswer() {
		if(!answerVisible) {
			setAnswerVisible(true);
		} else {
			setAnswerVisible(false);
		}
	}
		
	return(
		<div className={`faq${answerVisible ? " expanded" : ""}`}>
			<div className="faq-question" onClick={handleToggleAnswer}>
				<svg className="icon small-med body-fill faq-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.5 8C14.5 8.5625 14.0312 9.03125 13.5 9.03125H9V13.5312C9 14.0625 8.53125 14.5 8 14.5C7.4375 14.5 7 14.0625 7 13.5312V9.03125H2.5C1.9375 9.03125 1.5 8.5625 1.5 8C1.5 7.46875 1.9375 7.03125 2.5 7.03125H7V2.53125C7 1.96875 7.4375 1.5 8 1.5C8.53125 1.5 9 1.96875 9 2.53125V7.03125H13.5C14.0312 7 14.5 7.46875 14.5 8Z" fill="black"/>
				</svg>
				{content.question}
			</div>
			<div className="faq-answer">
				<div className="prose">
					<RichTextBlockRenderer content={content.answer} />
				</div>
			</div>
		</div>
	)
		
}