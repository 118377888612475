import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TrialContext } from '../contexts';
import { calculateTrialDays } from '../helpers';

import { LoadError } from './atoms';

export function Paywall({
	children,
	course,
	user
}) {
	
	const trial = useContext(TrialContext);
	
	const { t } = useTranslation();
	
	var available = false;
	
	if(user.user.trialExpiry) {
		const trialDays = calculateTrialDays(user.user.trialExpiry);
		if((trialDays > 0) && (course === trial)) {
			available = true;
		}		
	} else {
		for (const thisPurchase of user.purchases) {
			if(thisPurchase.product.documentId === course) {
				available = true;
			}
		}
	}
	
	if(available) {
		return children;
	} else {
		return(
			<div className="container course">
				<LoadError>
					<h2>{t('course.loadError')}</h2>
					<p>{t('course.loadErrorExplanation')}</p>
				</LoadError>
			</div>
		)
	}
	
}