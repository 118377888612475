import { RichTextBlockRenderer } from '../../../components/renderers';
import { AudioPlayer } from '../../../components/audioPlayer'

export default function Conversation(data) {
	if(data.content) {
		return (
			<div className="block conversation">
				<h1>{data.content.title}</h1>
				<div className="content">					
					{data.content.content[0].audio.data && (		
						<AudioPlayer src={`${data.content.content[0].audio.data[0].attributes.url}`} type={data.content.content[0].audio.data[0].attributes.mime} />			
					)}
				</div>
				<div className="content">	
					<table>
						<tbody>
							{data.content.content[0].row.map((thisRow, i) =>
								<tr key={i}>
									<th><p>{thisRow.speaker}</p></th>
									<td><RichTextBlockRenderer content={thisRow.content} /></td>
								</tr>
							)}
						</tbody>			
					</table>
				</div>
			</div>
		);
	}
}