import { useEffect, useState } from 'react';

import { getSignupPage } from '../../../fetches';

import { RichTextBlockRenderer } from '../../../components/renderers';

export function SignupBanner() {
		
	const [loading, setLoading] = useState(true);
	
	const [content, setContent] = useState(null);
	
	useEffect(() => {
		if(!content) {
			getSignupPage()
			.then((data) => {				
				setLoading(false);
				setContent(data.data.promoBanner);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
		}
	}, [content]);
	
	function handleLoaded() {		
		setLoading(false);
	}
	
	return(
		<div className={`signup-login-banner-wrapper${loading ? " hidden" : ""}`}>
			<div className="card secondary signup-login-banner">
				{!loading &&
					<>
						<div className="signup-login-banner-image-carousel">
							{content.cover.map((thisImage, i) =>
								<img src={thisImage.url} key={i} />
							)}
						</div>
						<div className="signup-login-banner-body">
							<h2>{content.heading}</h2>
							<RichTextBlockRenderer content={content.body} />
							<p className="terms"><RichTextBlockRenderer content={content.terms} noptags={true} /></p>
						</div>
					</>
				}				
			</div>
		</div>
	)
	
}