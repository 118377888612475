import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { getToken } from '../helpers';

import { useAuthContext } from '../contexts';

import { Button, Spinner } from '../components/atoms';

function OnboardingOptions({
		options,
		title,
		value,
		setValue
	}) {
			
	function handleChangeOption(newValue) {
		setValue(newValue);
	}
	
	var vertical = false;
	
	if(options.length>2) {
		vertical = true;
	}
	
	return(
		<div className="onboarding-options-wrapper">
			{title && (
				<strong>{title}</strong>
			)}
			<ul className={`onboarding-options${vertical ? " vertical" : ""}`}>
				<input type="hidden" value={value} />
				{options.map((thisOption, i) => 
					<li 
						key={i} 
						className={value===thisOption.value ? "active" : undefined}
						onClick={() => handleChangeOption(thisOption.value)}
					>
						<div className="radio">
							{value===thisOption.value && (
								<div className="fill"></div>
							)}
						</div>
						{thisOption.content}
					</li>
				)}
			</ul>
		</div>
	)
	
}

function OnboardingPage1({
		buttonAction
	}) {
		
	return(
		<div className="screen splash">
			<h1>Velkomin</h1>
			<Button
				icon="next"
				iconPlacement="after"
				onClick={() => buttonAction()}
			>
				Get started
			</Button>
		</div>
	)	
}

function OnboardingPage2({
		buttonAction,
		loading,
		purchases
	}) {
		
	const siteMode = process.env.REACT_APP_SITE_MODE;
		
	const navigate = useNavigate();
	
	if(siteMode==="beta") {
		var defaultOption = "trial";
	} else {
		var defaultOption = "purchase";
	}
		
	const [value, setValue] = useState(defaultOption);
	
	useEffect(() => {
		if(purchases.length>0) {
			buttonAction();
		}
	}, [purchases]);
	
	var options = [
		{
			"content": "Purchase a course",
			"value": "purchase"
		},
		{
			"content": "Start free trial",
			"value": "trial"
		},
	];
		
	if(siteMode==="beta") {
		options = [
			{
				"content": "Start free trial",
				"value": "trial"
			},
		];
	}
	
	function handleButtonClick() {
		buttonAction();
		if(value==='purchase') {
			navigate('/store');
		}
	}
		
	return(
		<>
			{loading ? (				
				<div className="screen splash">
					<Spinner />
				</div>
			) : (
				<div className="screen">
					<div className="dialog-content">
						<div className="prose">
							<h2>Purchase a course</h2>
							{siteMode==="beta" ? (
								<p>As an early beta tester, you get a free trial of Icelandic Beginners 1 until the end of the beta period. Afterwards, you’ll be able to purchase the course for continued access.</p>
							) : (
								<p>Get your learning off to a great start by purchasing a course. If you’re still making up your mind, enjoy a 7-day free trial of Icelandic Beginners 1.</p>
							)}
						</div>
						<OnboardingOptions
							options={options}
							value={value}
							setValue={setValue}
						/>
					</div>
					<div className="anchored-buttons">
						<Button
							icon="next"
							iconPlacement="after"
							onClick={() => handleButtonClick()}
						>
							Continue
						</Button>
					</div>
				</div>
			)}	
		</>		
	)	
}

function OnboardingPage3({
		buttonAction,
		loading,
		setLoading
	}) {
		
	const authToken = getToken();
	const user = useAuthContext();
		
	const [value, setValue] = useState('');
		
	const options = [
		{
			"content": "Male",
			"value": "m"
		},
		{
			"content": "Female",
			"value": "f"
		},
		{
			"content": "Non-binary",
			"value": "nb"
		},
		{
			"content": "Prefer not to say",
			"value": ""
		}
	];
	
	function handleUpdateGender() {
		setLoading(true);
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user.user.id}`, {
			method: 'PUT',
			headers: {
				'Strapi-Response-Format': 'v4',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`
			},
			body: JSON.stringify({
				gender: value
			}),
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			buttonAction();
			setLoading(false);
		})
		.catch((error) => {			
			console.log(error);
		});		
	}
		
	return(
		<div className="screen">
			<div className="dialog-content">
				<div className="prose">
					<h2>About you</h2>
					<p>Customise your learning experience by providing a bit more info about yourself.</p>
				</div>
				<OnboardingOptions
					title="What’s your gender?"
					options={options}
					value={value}
					setValue={setValue}
				/>
			</div>
			<div className="anchored-buttons">
				<Button
					icon="next"
					iconPlacement="after"
					onClick={() => handleUpdateGender()}
					disabled={loading ? "disabled" : ""}
				>
					Continue
				</Button>
			</div>
		</div>
	)	
}

function OnboardingPage4({
		buttonAction,
		loading
	}) {
		
	return(
		<div className="screen">
			<div className="dialog-content">
				<div className="prose">
					<h2>How to use Learn Icelandic</h2>
					<p>Get the most out of your learning experience with these tips.</p>
				</div>
				<ul className="tips">
					<li>
						<div className="emoji">📝</div>
						<div>
							<strong>Take notes as you go</strong><br />Make notes as you go to help you absorb what you’re learning. A paper notebook works best!
						</div>
					</li>
					<li>
						<div className="emoji">🔕</div>
						<div>
							<strong>Minimise distractions</strong><br />Mute or turn off notifications on your device to help you focus. Try and find a quiet space to study.
						</div>
					</li>
					<li>
						<div className="emoji">⏳</div>
						<div>
							<strong>Pace yourself</strong><br />Allow at least 1 hour for each lesson. If you need more time, you can always pick up where you left off later on.
						</div>
					</li>
					<li>
						<div className="emoji">🤔</div>
						<div>
							<strong>Use context to help you understand</strong><br />Not everything is translated into English – on purpose. Use context to try and work out the meaning of words.
						</div>
					</li>
				</ul>
			</div>
			<div className="anchored-buttons">
				<Button
					icon="next"
					iconPlacement="after"
					onClick={() => buttonAction()}
					disabled={loading ? "disabled" : ""}
				>
					Start learning
				</Button>
			</div>
		</div>
	)	
}

export default function Onboarding() {
	
	const authToken = getToken();
	const user = useAuthContext();
	
	const navigate = useNavigate();
	
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	
	const [purchases, setPurchases] = useState([]);
	
	useEffect(() => {
		document.body.classList.add('no-scroll');		
	}, []);
	
	useEffect(() => {
		if(user.user) {
			if(user.user.enterpriseAccount) {
				setPurchases(["enterprise"]);
			} else {
				checkPurchaseHistory();		
			}		
		}		
	}, [user.user]);
	
	async function checkPurchaseHistory() {
		if(user.user) {			
			setLoading(true);
			var q = stringify({
				filters: {
					user: {
						$eq: user.user.id
					}
				},
				populate: {
					product: {
						populate: '*'
					}
				}
			});
			console.log("Checking purchase history…")
			await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/purchases?${q}`, {
				headers: {
					'Authorization': `Bearer ${authToken}`
				}
			})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setPurchases(data.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
		}
	}
	
	function handleNextPage() {
		setPage(page+1);
	}	
	
	function handleFinishOnboarding() {
		setLoading(true);
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user.user.id}`, {
			method: 'PUT',
			headers: {
				'Strapi-Response-Format': 'v4',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`
			},
			body: JSON.stringify({
				onboarded: true
			}),
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			setLoading(false);
			navigate('/dashboard');
		})
		.catch((error) => {			
			console.log(error);
		});		
	}
	
	return(
		<div className="dialog-shader visible">
			<div className="dialog onboarding">
				{user.user ? (
					<>
						{page === 1 && (
							<OnboardingPage1 buttonAction={handleNextPage} />
						)}				
						{page === 2 && (
							<OnboardingPage2 buttonAction={handleNextPage} loading={loading} purchases={purchases} />
						)}		
						{page === 3 && (
							<OnboardingPage3 buttonAction={handleNextPage} loading={loading} setLoading={setLoading} />
						)}	
						{page === 4 && (
							<OnboardingPage4 buttonAction={handleFinishOnboarding} loading={loading} />
						)}	
					</>
				) : (
					<div className="screen splash">
						<Spinner />
					</div>
				)}				
			</div>
		</div>
	)
}