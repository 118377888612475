import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { stringify } from 'qs';

import { useAuthContext } from '../../contexts';

import { formatDate, getToken } from '../../helpers';
import { Button, Spinner } from '../../components/atoms';

export default function Purchases() {
		
	const siteMode = process.env.REACT_APP_SITE_MODE;
		
	const authToken = getToken();
	const user = useAuthContext();	
	
	const { t, i18n } = useTranslation();
		
	useEffect(() => {			
		document.body.classList.remove('conversation');
		document.body.classList.remove('no-scroll');
		document.title = `${t('purchaseHistory.title')}${t('site.titleSuffix')}`;
	}, []);
		
	return(
		<div className="container external">
			<div className="prose">
				<h1>{t('purchaseHistory.purchaseHistory')}</h1>
			</div>
			<section className="card purchases no-padding">
				{user.loading ? (
					<div className="centred-content padded">
						<Spinner />
					</div>
				) : (
					<>
						{user?.purchases.length>0 ? (
							<table className="pretty full-width">
								<thead>
									<tr>
										<th>{t('purchaseHistory.course')}</th>
										<th>{t('purchaseHistory.purchaseDate')}</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									{user.purchases.map((thisPurchase, i) =>
										<tr key={i}>
											<td>{thisPurchase.product.externalName}</td>
											<td>{formatDate(thisPurchase.transactionDate, i18n.language)}</td>
											{thisPurchase.invoiceLink && (												
												<td className="align-right">
													<Link to={thisPurchase.invoiceLink} target="_new">
														<Button 
															role="link" 
															label={t('purchaseHistory.downloadInvoice')} 
															icon="download" 
															iconPlacement="after"
														/>
													</Link>
												</td>
											)}
										</tr>									
									)}								
								</tbody>
							</table>
						) : (
							<div className="centred-content">
								<div className="rows">
									<div className="row">
										<div className="vertical-row-content full-width">
											<div className="loading">{t('purchaseHistory.errors.noPurchases')}</div>
											{siteMode!=="beta" && 
												<Link to="/store" className="full-width">
													<Button			
														icon="next"
														iconPlacement="after"					
														fullWidth="true">
														{t('purchaseHistory.buttonGoToStore')}
													</Button>
												</Link>
											}										
										</div>
									</div>
								</div>
							</div>	
						)}						
					</>
				)}					
			</section>		
		</div>
	)
}