import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { getEnterpriseUsers, inviteEnterpriseUser } from '../../fetches';
import { useAuthContext } from '../../contexts';

import { Button, Spinner } from '../../components/atoms';

export function EnterpriseSidebar() {
	
	const user = useAuthContext();
	const location = useLocation();
	
	const { t } = useTranslation();
	
	return(
		<nav className="enterprise-sidebar">
			<Link to="/enterprise">
				<div className="avatar-wrapper">
					<div className="avatar">
						<svg className="icon small-med body-fill" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3 1C3.53125 1 4 1.46875 4 2V6.75L8.875 4.09375C9.375 3.84375 10 4.1875 10 4.78125V6.75L14.875 4.09375C15.375 3.84375 16 4.1875 16 4.78125V13.5C16 14.3438 15.3125 15 14.5 15H1.5C0.65625 15 0 14.3438 0 13.5V2C0 1.46875 0.4375 1 1 1H3Z" fill="#1C1E21"/>
						</svg>
					</div>
					<div className="avatar-label">{user.user && user.user.enterpriseAccount.companyName}</div>
				</div>
			</Link>
			<ul>
				<li>
					<Link to="/enterprise" className={`${location.pathname==="/enterprise" ? 'active' : ''}`}>
						<svg className="icon small-med body-fill" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 4C7.4375 4 7 4.46875 7 5C7 5.5625 7.4375 6 8 6C8.53125 6 9 5.5625 9 5C9 4.46875 8.53125 4 8 4ZM9.25 12C9.65625 12 10 11.6875 10 11.25C10 10.8438 9.65625 10.5 9.25 10.5H8.75V7.75C8.75 7.34375 8.40625 7 8 7H7C6.5625 7 6.25 7.34375 6.25 7.75C6.25 8.1875 6.5625 8.5 7 8.5H7.25V10.5H6.75C6.3125 10.5 6 10.8438 6 11.25C6 11.6875 6.3125 12 6.75 12H9.25Z" fill="#1C1E21"/>
						</svg>
						{t('enterprise.overview.overview')}
					</Link>
				</li>
				<li>
					<Link to="/enterprise/users" className={`${location.pathname==="/enterprise/users" ? 'active' : ''}`}>
						<svg className="icon small-med body-fill" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 8C4.78125 8 3 6.21875 3 4C3 1.8125 4.78125 0 7 0C9.1875 0 11 1.8125 11 4C11 6.21875 9.1875 8 7 8ZM8.5625 9.5C11.5625 9.5 14 11.9375 14 14.9375C14 15.5312 13.5 16 12.9062 16H1.0625C0.46875 16 0 15.5312 0 14.9375C0 11.9375 2.40625 9.5 5.40625 9.5H8.5625Z" fill="#1C1E21"/>
						</svg>		
						{t('enterprise.users.users')}
					</Link>
				</li>
				<li>
					<Link to="/enterprise/settings" className={`${location.pathname==="/enterprise/settings" ? 'active' : ''}`}>
						<svg className="icon small-med body-fill" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.4688 5.21875C15.5938 5.5 15.5 5.78125 15.2812 6L13.9375 7.21875C13.9688 7.46875 14 7.75 14 8C14 8.28125 13.9688 8.5625 13.9375 8.8125L15.2812 10.0312C15.5 10.25 15.5938 10.5312 15.4688 10.8125C15.3438 11.1875 15.1875 11.5312 15 11.875L14.8438 12.125C14.625 12.4688 14.4062 12.8125 14.1562 13.125C13.9688 13.3438 13.6562 13.4062 13.375 13.3125L11.6562 12.7812C11.2188 13.0938 10.75 13.3438 10.2812 13.5625L9.875 15.3438C9.8125 15.625 9.59375 15.8438 9.3125 15.9062C8.875 15.9688 8.4375 16 7.96875 16C7.53125 16 7.09375 15.9688 6.65625 15.9062C6.375 15.8438 6.15625 15.625 6.09375 15.3438L5.6875 13.5625C5.21875 13.3438 4.75 13.0938 4.3125 12.7812L2.59375 13.3125C2.3125 13.4062 2 13.3438 1.8125 13.125C1.5625 12.8125 1.34375 12.4688 1.125 12.125L0.96875 11.875C0.78125 11.5312 0.625 11.1875 0.5 10.8125C0.375 10.5312 0.46875 10.25 0.6875 10.0312L2.03125 8.8125C2 8.5625 2 8.28125 2 8C2 7.75 2 7.46875 2.03125 7.21875L0.6875 6C0.46875 5.78125 0.375 5.5 0.5 5.21875C0.625 4.84375 0.78125 4.5 0.96875 4.15625L1.125 3.90625C1.34375 3.5625 1.5625 3.21875 1.8125 2.90625C2 2.6875 2.3125 2.625 2.59375 2.71875L4.3125 3.25C4.75 2.9375 5.21875 2.65625 5.6875 2.46875L6.09375 0.6875C6.15625 0.40625 6.375 0.1875 6.65625 0.125C7.09375 0.0625 7.53125 0 8 0C8.4375 0 8.875 0.0625 9.3125 0.125C9.59375 0.1875 9.8125 0.40625 9.875 0.6875L10.2812 2.46875C10.75 2.65625 11.2188 2.9375 11.6562 3.25L13.375 2.71875C13.6562 2.625 13.9688 2.6875 14.1562 2.90625C14.4062 3.21875 14.625 3.5625 14.8438 3.90625L15 4.15625C15.1875 4.5 15.3438 4.84375 15.4688 5.21875ZM8 10.5C9.375 10.5 10.5 9.40625 10.5 8C10.5 6.625 9.375 5.5 8 5.5C6.59375 5.5 5.5 6.625 5.5 8C5.5 9.40625 6.59375 10.5 8 10.5Z" fill="#1C1E21"/>
						</svg>						
						{t('enterprise.settings.settings')}
					</Link>
				</li>
			</ul>
		</nav>
	)
	
}

export function AddUserDialog({ 
		visible,
		setVisible
	}) {
		
	const user = useAuthContext();
	
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	
	const [userAdded, setUserAdded] = useState(false);
	const [email, setEmail] = useState(null);
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();
	
	useEffect(() => {
		if(visible && !userAdded) {
			const emailField = document.getElementById('email');
			emailField.type = 'text';
			emailField.focus();
			emailField.setSelectionRange(0, 0);
			emailField.type = 'email';
		}		
	}, [visible]);
	
	function handleAddUser(formData) {
		setLoading(true);
		var q = stringify({
			filters: {
				enterpriseAccount: {
					documentId: {
						$eq: user.user.enterpriseAccount.documentId
					}
				},
				username: {
					$eq: formData.email
				}
			}
		});
		const data = {
			companyName: user.user.enterpriseAccount.companyName,
			email: formData.email
		}
		getEnterpriseUsers(q).
		then((userData) => {
			if(userData.length>0) {
				setError({ message: t('enterprise.users.dialogAddUser.errors.accountExists') });
				setLoading(false);
			} else {
				inviteEnterpriseUser(data)
				.then((inviteResponse) => {
					if(inviteResponse) {
						setLoading(false);
						setUserAdded(true);
						setEmail(formData.email);
					}			
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				})
			}
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
		})
	}
	
	function handleCloseDialog() {
		setVisible(false);
		setTimeout(function() {
			reset();
			setError(false);
			setLoading(false);
			setUserAdded(false);
			setEmail(null);
		}, 250);
	}
		
	return(
		<div className={`dialog-shader${visible === 'enterprise-add-user' ? (' visible') : ('')}`}>
			<div className="dialog">
				{!user.user ? (
					<div className="centred-content">
						<Spinner />
					</div>
				) : (
					<div className="rows">
						<div className="row">
							<div className="prose">						
								<h2>{t('enterprise.users.dialogAddUser.addUser')}</h2>
							</div>
						</div>
						{error && (
							<div className="placard error">{error.message}</div>
						)}
						{errors.email?.type === 'required' && (
							<div className="placard error">{t('enterprise.users.dialogAddUser.errors.noEmail')}</div>
						)}
						{errors.email?.type === 'pattern' && (
							<div className="placard error">{t('enterprise.users.dialogAddUser.errors.wrongDomain')}</div>
						)}
						{userAdded ? (
							<>
							
								<div className="row">
									<div className="centred-content">
										<img src="/images/icon-success.svg" className="icon large" />
									</div>
								</div>
								<div className="centred-content">									
									{t('enterprise.users.dialogAddUser.inviteSent')}<strong>{email}</strong>
								</div>
								<div className="row">
									<div className="vertical-row-content">		
										<Button
											disabled={loading ? (true) : (false)}
											fullWidth={true}
											label={t('enterprise.users.dialogAddUser.buttonDone')}
											onClick={handleCloseDialog}
											role="secondary"
										/>					
									</div>
								</div>
							</>
						) : ( 
							<>
								<form onSubmit={handleSubmit(handleAddUser)}>
									<div className="row">
										<div className="vertical-row-content">
											<label htmlFor="email"><strong>{t('enterprise.users.dialogAddUser.email')}</strong></label>
											<input
												aria-invalid={errors.email ? "true" : "false"}
												autoFocus="autoFocus"
												className="account-form"
												defaultValue={`@${user.user.enterpriseAccount.domain}`}
												disabled={loading ? ('disabled') : ('')}
												id="email"
												name="email"
												placeholder={`@${user.user.enterpriseAccount.domain}`}
												onFocus={() => setError(null)}
												type="email"
												{...register('email', { 
													pattern: new RegExp(`^[A-Za-z0-9_.\-]+@${user.user.enterpriseAccount.domain}$`),
													required: true
												})} 
											/>
										</div>
									</div>
									<div className="row">			
										<Button 
											fullWidth={true}  
											label={t('enterprise.users.dialogAddUser.buttonInviteUser')}
											disabled={loading ? (true) : (false)}
										/>
									</div>
								</form>
								<div className="row">
									<div className="vertical-row-content">		
										<Button
											disabled={loading ? (true) : (false)}
											fullWidth={true}
											label={t('enterprise.users.dialogAddUser.buttonCancel')}
											onClick={handleCloseDialog}
											role="secondary"
										/>					
									</div>
								</div>
							</>
						)}					
					</div>
				)}				
			</div>
		</div>
	)
	
}	