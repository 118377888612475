import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ProgressDonut } from '../../components/atoms';

export function DashboardContent({ 
		data, mode 
	}) {
	
	const { t } = useTranslation();
	
	return(
		<div className="container">
			<div className="prose">
				<h1>{t('dashboard.courses')}</h1>
			</div>
			<section className="courses">
				{mode=="trialExpired" &&
					<TrialExpired />
				}
				<CourseCards data={data} mode={mode} />
			</section>
		</div>
	)
	
}

export function CourseCards({ 
		data,
		mode 
	}) {			
	
	var cardsArray = [];	
	data.map((thisCourse, i) => {
		var progress = 0;
		cardsArray.push(
			<CourseCard 
				data={thisCourse.course}
				key={i}
				mode={mode}
				progress={thisCourse.progress}
			/>
		)
	})
	
	return (
		cardsArray
	)
	
}

export function CourseCard({
		data,
		mode,
		progress
	}) {
		
	const siteMode = process.env.REACT_APP_SITE_MODE;
		
	const { t } = useTranslation();
	
	return(
		<div className={`course-card${mode==="trialExpired" ? " disabled" : ""}`}>
			<div className="cover">
				{data.cover && (
					<img 
						src={data.cover.url}
						alt={data.cover.alternativeText}
					/>
				)}				
			</div>
			<div className="course-label">
				<h2>{data.name}</h2>
				{progress > 0 ? (
					<ProgressDonut value={progress} label={t('dashboard.inProgress')} />
				) : (
					<ProgressDonut value="0" label={t('dashboard.notStarted')} /> 
				)}							
			</div>
			<div className="course-buttons">
				{mode!=="trialExpired" &&
				<Link to={`/c/${data.slug}`}>
					<Button icon="next" iconPlacement="after">
						{progress > 0 ? (
							<>{t('dashboard.buttonContinue')}</>
						) : (									
							<>{t('dashboard.buttonStart')}</>
						)}
					</Button>
				</Link>
				}
				{mode==="trial" &&					
					<Link to={`/store/p/${data.slug}`}>
						<Button role="secondary">
							{t('dashboard.buttonPurchase')}
						</Button>
					</Link>
				}
				{mode==="trialExpired" && 		
					<Link to={`/store/p/${data.slug}`}>
						<Button icon="next" iconPlacement="after">
							{t('dashboard.buttonPurchase')}
						</Button>
					</Link>
				}
			</div>
		</div>
	)
	
}

export function TrialExpired() {
	
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	return(
		<div className="card trial-expired">
			<h2>😢 {t('trial.expiredDialog.heading')}</h2>
			<p>{t('trial.expiredDialog.explanation')}</p>
		</div>
	)
	
}

export function NoPurchases() {
	
	const { t } = useTranslation();
	
	return(
		<div className="container">
			<div className="prose">
				<h1>{t('dashboard.courses')}</h1>
			</div>
			<div className="placard info">
				<div className="content-wrapper">
					{t('dashboard.noPurchases')}
					<Link to="/store">									
						<button>{t('dashboard.buttonPurchaseCourse')}</button>
					</Link>
				</div>
			</div>
		</div>
	)
	
}