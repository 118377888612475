import { stringify } from 'qs';

import { getToken } from '../../helpers';

import { useAuthContext } from '../../contexts';

const authToken = getToken();

export async function getProductData(productSlug) {
	var q = stringify({
		filters: {
			slug: {
				$eq: productSlug
			}
		},
		populate: {
			features: '*',
			productCarousel: {
				populate: '*'
			},
			vendor: {
				populate: ['avatar']
			}
		}
	});		
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses?${q}`);
	if(!response.ok) {
		const message = `Error fetching product data: ${response.status}`;
		throw new Error(message);
	}
	const product = await response.json();
	return product.data;
}

export async function getPaymentLink(paymentLinkData) {
	const orderQ = stringify({
		filters: {
			product: {
				$eq: paymentLinkData.productId
			},
			user: {
				$eq: paymentLinkData.user
			}
		}
	});		
	const checkOrderResponse = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/orders?=${orderQ}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${authToken}`
		}
	});
	if(!checkOrderResponse.ok) {
		const message = `Error fetching existing order data: ${checkOrderResponse.status}`;
		throw new Error(message);
	}
	const orderResponseData = await checkOrderResponse.json();
	if(orderResponseData.data[0]?.paymentStatus === "DRAFT") {
		console.log('Existing payment link found for '+paymentLinkData.productName);
		const paymentLink = orderResponseData.data[0].paymentLink;
		return {
			orderId: orderResponseData.data[0].documentId,
			paymentLink: orderResponseData.data[0].paymentLink,
			squareOrderId: orderResponseData.data[0].squareOrderID
		}
	} else {
		console.log('Creating new payment link for '+paymentLinkData.productName);
		const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/createPaymentLink`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`
			},
			body: JSON.stringify(paymentLinkData)
		});
		if(!response.ok) {
			const message = `An error has occured: ${response.status}`;
			throw new Error(message);
		}
		const responseData = await response.json();
		return {
			orderId: responseData.orderId,
			paymentLink: responseData.paymentLink,
			squareOrderId: responseData.squareOrderId
		}	
	}	
}

export async function createPurchase(data) {
	var today = new Date();	
	today = today.toISOString().substring(0,10);
	const newPurchaseData = {
		user: data.user,
		product: data.product,
		transactionDate: today,
		invoiceLink: data.invoiceLink
	}
	const newOrderData = {
		paymentStatus: 'OPEN'
	}
	const [createPurchaseResponse, updateOrderResponse] = await Promise.all([
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/purchases`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`
			},
			body: JSON.stringify({ data: newPurchaseData }),
		}),
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/orders/${data.orderId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`
			},
			body: JSON.stringify({ data: newOrderData }),
		})
	]);
	if(!createPurchaseResponse.ok) {
		const message = `Error creating purchase: ${createPurchaseResponse.status}`;
		throw new Error(message);
	}
	if(!updateOrderResponse.ok) {
		const message = `Error updating order: ${updateOrderResponse.status}`;
		throw new Error(message);
	}
	const purchaseData = await createPurchaseResponse.json();
	if(createPurchaseResponse && updateOrderResponse) {
		const trialPeriodResponse = await removeTrialPeriod();
		if(trialPeriodResponse) {
			return purchaseData;
		}
	}
}

export async function removeTrialPeriod() {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users-permissions/users/me`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${authToken}`
		},
		body: JSON.stringify({ trialExpiry: null }),
	});
	if(!response.ok) {
		const message = `Error removing trial period: ${response.status}`;
		throw new Error(message);
	}
	const responseData = await response.json();
	return responseData;	
}

