import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { useAuthContext } from '../../contexts';
import { getEnterpriseUsers } from '../../fetches';

import { countActiveUsers, countAdminUsers } from './enterpriseHelpers';
import { EnterpriseSidebar } from './enterpriseComponents';

export default function EnterpriseOverview() {
	
	const user = useAuthContext();
	
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(true);	
	const [users, setUsers] = useState(null);
	
	useEffect(() => {
		if(!users && user.user) {
			var q = stringify({
				filters: {
					enterpriseAccount: {
						documentId: {
							$eq: user.user.enterpriseAccount.documentId
						}
					}
				}
			});
			getEnterpriseUsers(q)
			.then((users) => {
				setUsers(users);
				setLoading(false);
			})
		}
	}, [user.user]);
	
	return(
		<div className="container">
			<div className="content enterprise">
				<EnterpriseSidebar />
				<div className="enterprise-body">
					<div className="card-wrapper">
						{!(user.user && users) ? (
							<>
								<div className="card stat skeleton"></div>
								<div className="card stat skeleton"></div>
							</>
						) : (
							<>
								<div className="card stat">
									<span className="figure">{countActiveUsers(users)}</span>{t('enterprise.overview.activeUsers', { count: countActiveUsers(users) })}
								</div>
								<div className="card stat">
									<span className="figure">{user.user.enterpriseAccount.seatLimit - countActiveUsers(users)}</span>{t('enterprise.overview.seatsLeft', { count: user.user.enterpriseAccount.seatLimit - countActiveUsers(users) })}
								</div>
							</>
						)}						
					</div>
				</div>
			</div>
		</div>
	)
	
}