import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { useAuthContext, BreadcrumbsContext } from '../../contexts';
import { calculateTrialDays } from '../../helpers';
import { getDashboardData, createProgressEntry } from '../../fetches';

import { DashboardContent, NoPurchases, TrialExpired } from './dashboardComponents';

export default function Dashboard() {
	
	const user = useAuthContext();
	
	const { t } = useTranslation();
	const breadcrumbs = useContext(BreadcrumbsContext);
	
	var trialDays = null;
	
	if(user.user) {
		trialDays = calculateTrialDays(user.user.trialExpiry);
	}
		
  const [dashboard, setDashboard] = useState(null);	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	const [progressCreated, setProgressCreated] = useState(false);
	
	useEffect(() => {			
		document.body.classList.remove('conversation');
	}, [dashboard]);
	
	if(user.user) {
		var q = stringify({
			filters: {
				user: {
					$eq: user.user.id
				}
			},
			populate: {
				course: {
					populate: ['cover']
				}
			}
		});
	}
	
	useEffect(() => {	
		document.title = `${t('dashboard.title')}${t('site.titleSuffix')}`;
		if(user.user) {		
			getDashboardData(q)
			.then((data) => {
				if(data.data.length>0) {	
					setDashboard(data.data);				
					var newBreadcrumbs = [{
						path: '/dashboard',
						title: 'Learn Icelandic',
						mobileStyle: 'hidden'	
					}];
					breadcrumbs.setBreadcrumbs(newBreadcrumbs);
					setLoading(false);
				} else {			
					if(!progressCreated) {
						console.log("Course progress not found");
						createProgressEntry({
							courseId: 'eqan5aa0jn1uxiqjlnvgkvw0',
							user: user.user.id
						})
						.then((progressEntryData) => {
							setProgressCreated(true);
						});
					} else {
						setProgressCreated(false);
					}
				}
			})
			.catch((error) => {
				console.log(error);
				setError(error);				
				setLoading(false);
			});
		}
	}, [user.user, q, progressCreated]);
  
	if(loading || user.loading) {	
	 	return(
			<div className="container">
				<div className="prose">
					<h1>{t('dashboard.courses')}</h1>
				</div>
				<section className="courses">
					<div className="course-card skeleton"></div>						
					<div className="course-card skeleton"></div>
				</section>
			</div>
	 	)
	} if(error) {	
		return(
			<div className="container">
				<div className="prose">
					<h1>{t('dashboard.courses')}</h1>					
				</div>
				<div className="placard error">{t('dashboard.errorLoading')}</div>
			</div>
		)
	} else {
		if(trialDays) {		
			if(trialDays > 0) {
				if(dashboard) {
					return(
						<DashboardContent data={dashboard} mode="trial" />
					)
				}
			} else {
				if(dashboard) {
					return(
						<DashboardContent data={dashboard} mode="trialExpired" />
					)
				}
			}			
		} else {
			if(dashboard) {
				return (
					<DashboardContent data={dashboard} />
				);
			}	else {
				return (
					<NoPurchases />
				)				
			}
		}
	}
}
