import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RichTextBlockRenderer, TextRenderer } from './renderers';

import { Button, AudioButton } from './atoms';

/* Basic HTML tags */

export function Heading({ content }) {
	switch(content.level) {
		case 2:
			return(
				<h2>
					<TextRenderer textArray={content.children} />
				</h2>
			);
			break;
		case 3:
			return(
				<h3>
					<TextRenderer textArray={content.children} />
				</h3>
			);
			break;
		case 4:
			return(
				<h4>
					<TextRenderer textArray={content.children} />
				</h4>
			);
			break;
	}				
}

export function Paragraph({
		audio,
		audioPlaylist,
		content,
		noptags,
		parseHTML,
		setAudioPlaylist
	}) {
	
	if(noptags) {					
		return(
			<TextRenderer textArray={content.children} parseHTML={parseHTML} />
		)
	} else {					
		return(
			<p>
				<TextRenderer textArray={content.children} parseHTML={parseHTML} />
				{audio && (	
					<AudioButton 
						playlist={audioPlaylist}
						setPlaylist={setAudioPlaylist}
						src={`${audio.attributes.url}`}
						type={audio.attributes.mime}
					/>
				)}
			</p>
		)
	}
	
}

export function List({ content, parseHTML }) {
	if(content.format === "unordered") {
		return <UnorderedList content={content} parseHTML={parseHTML} />
	} else if(content.format === "ordered") {
		return <OrderedList content={content} parseHTML={parseHTML} />
	}
}

export function UnorderedList({ content, parseHTML }) {
	console.log(parseHTML);
	var listItemContent = [];	
	return(
		<ul>
			{content.children.map(function(thisChild, j) {
				if(thisChild.type==="list-item") {
					listItemContent.push(
						<li key={j}>
							<TextRenderer textArray={thisChild.children} parseHTML={parseHTML} />
						</li>
					)
				} else if(thisChild.type==="list") {
					console.log(thisChild.children);
					listItemContent.push(
						<UnorderedList key={j} content={thisChild} parseHTML={parseHTML} />
					)
				}						
			})}
			{listItemContent}
		</ul>
	)
}

export function OrderedList({ content, parseHTML }) {
	var listItemContent = [];	
	return(
		<ol>
			{content.children.map(function(thisChild, j) {
				if(thisChild.type==="list-item") {
					listItemContent.push(
						<li key={j}>
							<TextRenderer textArray={thisChild.children} parseHTML={parseHTML}/>
						</li>
					)
				} else if(thisChild.type==="list") {
					console.log(thisChild.children);
					listItemContent.push(
						<OrderedList key={j} content={thisChild} parseHTML={parseHTML} />
					)
				}						
			})}
			{listItemContent}
		</ol>
	)
}


/* Content blocks */

export function VocabBlock({ content }) {
	
	const { t } = useTranslation();
	
	const [playlist, setPlaylist] = useState([]);
	const [playing, setPlaying] = useState(false);
	
	var i = 0;
	
	const onEnded = (event) => {
		event.target.removeEventListener('ended', onEnded);
		if(i>0 && i<playlist.length) {
			handlePlayAll();	
		} else if(i===playlist.length) {
			setPlaying(false);
			i = 0;
		}
	}
	
	function handlePlayAll() {
		if(i<playlist.length) {
			setPlaying(true);
			playlist[i].play();
			playlist[i].addEventListener('ended', onEnded);
			i++;
		}	
	}
	
	function handleStop(event) {
		setPlaying(false);
		for(const thisItem of playlist) {			
			thisItem.pause();
			thisItem.currentTime = 0;
			thisItem.removeEventListener('ended', onEnded);
		}
		i = 0;
	}
	
	if(content.hero) {
		return(		
			<div className="block-wrapper">
				{content.row.map((thisRow, i) =>											
					<div className={`block hero col-${content.columns}${thisRow.class ? ` ${thisRow.class}` : ""}`} key={i}>			
						<RichTextBlockRenderer content={content.row[i].content} />
						{content.row[i].audio.data && (	
							<AudioButton
								playlist={playlist}
								setPlaylist={setPlaylist}
								src={`${content.row[i].audio.data.attributes.url}`}
								type={content.row[i].audio.data.attributes.mime}
							/>
						)}
					</div>
				)}
			</div>
		)
	} else {
		return(		
			<div className="block vocab">
				<div className="block-content-wrapper">
					{content.image.data && (						
						<img 
							src={content.image.data.attributes.url}
							alt={content.image.data.attributes.alternativeText} 
							className={content.imageSize}
						/>
					)}
					<div className={`content${content.vAlign ? " "+content.vAlign : ""}`}>
						{[...Array(content.columns)].map((x, i) =>
							<div className={`col${content.columnsSizeToFit ? " to-fit" : ""}`} key={i}>
								{content.row.map((thisRow, j) =>		
									<Fragment key={j}>
										{thisRow.column === i+1 && (								
											<div className={`row${thisRow.gap ? " padded" : ""}${thisRow.isCentred ? " centred" : ""}${thisRow.isColumn ? " as-column" : ""}${thisRow.class ? ` ${thisRow.class}` : ""}`}>															
												{((thisRow.column === i+1) && (thisRow.image.data)) && (						
													<img 
														src={thisRow.image.data.attributes.url}
														alt={thisRow.image.data.attributes.alternativeText} 
														className={thisRow.imageSize}
													/>
												)}	
												<div className="prose">
													<RichTextBlockRenderer
														audio={content.row[j].audio.data}
														audioPlaylist={playlist}
														content={content.row[j].content}
														setAudioPlaylist={setPlaylist}
													/>
												</div>
											</div>
										)}
									</Fragment>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="block-audio-controls">
					{playing ? (
						<Button 
							onClick={handleStop}
							role="link padded">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15 5V13C15 14.0938 14.0938 15 13 15H5C3.875 15 3 14.0938 3 13V5C3 3.90625 3.875 3 5 3H13C14.0938 3 15 3.90625 15 5Z" fill="#0B00D1"/>
							</svg>
							{t('lessonPage.buttonStop')}
						</Button>
					) : (
						<Button 
							onClick={handlePlayAll}
							role="link padded">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.2812 7.71875C14.7188 8 15 8.5 15 9C15 9.53125 14.7188 10.0312 14.2812 10.2812L5.28125 15.7812C4.8125 16.0625 4.21875 16.0938 3.75 15.8125C3.28125 15.5625 3 15.0625 3 14.5V3.5C3 2.96875 3.28125 2.46875 3.75 2.21875C4.21875 1.9375 4.8125 1.9375 5.28125 2.25L14.2812 7.71875Z" fill="#0B00D1"/>
							</svg>
							{t('lessonPage.buttonPlayAll')}
						</Button>
					)}					
				</div>
			</div>
		)
	}
}

export function Paradigm({ content }) {
	return(
		<figure className="paradigm-wrapper">
			{content.template==="pronoun" && 
				<table className="paradigm-key-table">
					<tbody>
						<tr>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td>1.et.</td>
						</tr>
						<tr>
							<td>2.et.</td>
						</tr>
						<tr>
							<td>3.et.</td>
						</tr>
						<tr>
							<td>1.ft.</td>
						</tr>
						<tr>
							<td>2.ft.</td>
						</tr>
						<tr>
							<td>3.ft.</td>
						</tr>
					</tbody>
				</table>
			}
			<div className="card paradigm-card">
				<table className="paradigm-table">
					{content.headerRow && (
						<thead>
							<tr>
								{content.headerRow.cell.map((thisCell, i) =>
									<th key={i} colSpan={thisCell.colSpan ? thisCell.colSpan : ""} width={thisCell.width ? `${thisCell.width}%` : ""}>
										<RichTextBlockRenderer content={thisCell.content} parseHTML={true} />
									</th>
								)}
							</tr>
						</thead>
					)}					
					<tbody>
						{content.bodyRows.map((thisRow, i) =>
							<tr key={i} className={thisRow.dividerRow ? "divider-row" : ""}>
								{thisRow.cell.map((thisCell, i) =>
									<td 
										key={i}
										colSpan={thisCell.colSpan ? thisCell.colSpan : ""}
										rowSpan={thisCell.rowSpan ? thisCell.rowSpan : ""}
										width={thisCell.width ? `${thisCell.width}%` : ""}
									>
										<RichTextBlockRenderer content={thisCell.content} parseHTML={true} />
									</td>
								)}
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</figure>
	)
}
