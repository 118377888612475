import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useAuthContext } from '../../contexts';
import { updateEnterpriseSettings } from '../../fetches';

import { Button } from '../../components/atoms';
 
import { EnterpriseSidebar } from './enterpriseComponents';

function CompanyDetails() {
	
	const user = useAuthContext();		
	const { t } = useTranslation();
	
	return(
		<div className="card">
			<h3>{t('enterprise.settings.companyDetails.companyDetails')}</h3>
			<div className="centred-content">
				<div className="rows">
					<div className="row">
						<label htmlFor="domain" className="row-label">{t('enterprise.settings.companyDetails.companyName')}</label>									
						<div className="vertical-row-content no-padding">
							<input 
								className="account-form"
								disabled="disabled"
								id="domain" 
								name="domain" 
								type="text"
								value={user.user.enterpriseAccount.companyName}
							/>
						</div>
					</div>		
					<div className="row">
						<label htmlFor="domain" className="row-label">{t('enterprise.settings.companyDetails.companyNumber')}</label>									
						<div className="vertical-row-content no-padding">
							<input 
								className="account-form"
								disabled="disabled"
								id="domain" 
								name="domain" 
								type="text"
								value={user.user.enterpriseAccount.companyNumber}
							/>
						</div>
					</div>		
					<div className="row">
						<label htmlFor="domain" className="row-label">{t('enterprise.settings.companyDetails.companyDomain')}</label>									
						<div className="vertical-row-content no-padding">
							<input 
								className="account-form"
								disabled="disabled"
								id="domain" 
								name="domain" 
								type="text"
								value={user.user.enterpriseAccount.domain}
							/>
							<div className="explanation">{t('enterprise.settings.companyDetails.companyDomainExplanation')}</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
	)
	
}

function UserSettings() {
	
	const user = useAuthContext();		
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	const [autoenrolNotificationsDisabled, setAutoenrolNotificationsDisabled] = useState(false);
	
	const { register, handleSubmit, formState: { errors }, setValue } = useForm();
	
	useEffect(() => {
		if(user.user.enterpriseAccount.autoenrol===false) {
			setAutoenrolNotificationsDisabled(true);
		}	
	}, [user.user]);
	
	function handleToggleAutoenrolNotificationsDisabled() {
		if(autoenrolNotificationsDisabled) {
			setAutoenrolNotificationsDisabled(false);
		} else {
			setAutoenrolNotificationsDisabled(true);
			setValue('autoenrolNotifications', false);
		}
	}
	
	function handleUpdateSettings(formData) {
		setLoading(true);
		const data = {
			autoenrol: formData.autoenrol,
			autoenrolNotifications: formData.autoenrolNotifications,
			seatLimit: formData.seatLimit
		}
		updateEnterpriseSettings(user.user.enterpriseAccount.documentId, data)
		.then((response) => {			
			setLoading(false);	
		})
		.catch((error) => {
			setLoading(false);
			console.log(error);
		})
	}
	
	return(
		<div className="card">
			<h3>{t('enterprise.settings.userSettings.userSettings')}</h3>
			<div className="centred-content">
				<form className="rows" onSubmit={handleSubmit(handleUpdateSettings)}>							
					<div className="row">
						<div className="row-label">{t('enterprise.settings.userSettings.userEnrolment')}</div>		
						<div className="vertical-row-content no-padding">
							<div className="checkbox-wrapper">
								<input
									defaultChecked={user.user.enterpriseAccount.autoenrol===true && 'checked'}
									id="autoenrol"
									name="autoenrol"
									onClick={handleToggleAutoenrolNotificationsDisabled}
									{...register('autoenrol')} 							
									type="checkbox"
								/>
								<label htmlFor="autoenrol">{t('enterprise.settings.userSettings.allowAutoenrol')}</label>
							</div>
							<div className="explanation">{t('enterprise.settings.userSettings.allowAutoenrolExplanation')}</div>
							<div className="checkbox-wrapper">
								<input
									defaultChecked={user.user.enterpriseAccount.autoenrolNotifications===true && 'checked'}
									disabled={autoenrolNotificationsDisabled ? 'disabled' : ''}
									id="autoenrolNotifications"
									name="autoenrolNotifications"
									{...register('autoenrolNotifications')} 
									type="checkbox"
								/>
								<label htmlFor="autoenrolNotifications">{t('enterprise.settings.userSettings.notifyAdmins')}</label>
							</div>
						</div>
					</div>
					<div className="row">
						<label htmlFor="seatLimit" className="row-label">{t('enterprise.settings.userSettings.activeSeatLimit')}</label>									
						<div className="vertical-row-content no-padding">
							<input 
								className="account-form"
								defaultValue={user.user.enterpriseAccount.seatLimit}
								id="seatLimit" 
								name="seatLimit"
								{...register('seatLimit')} 
								type="number"
							/>
						</div>
					</div>
					<div className="row">				
						<Button 
							disabled={loading ? 'disabled' : ''} 
							label={loading ? t('enterprise.settings.userSettings.saving') : t('enterprise.settings.userSettings.buttonSaveChanges')}
						/>
					</div>
				</form>
			</div>
		</div>
	)
	
}

function BillingSettings() {
	
	const user = useAuthContext();		
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	function handleUpdateSettings(formData) {
		setLoading(true);
		const data = {
			billingEmail: formData.billingEmail
		}
		updateEnterpriseSettings(user.user.enterpriseAccount.documentId, data)
		.then((response) => {			
			setLoading(false);	
		})
		.catch((error) => {
			setLoading(false);
			console.log(error);
		})
	}
	
	return(
		<div className="card">
			<h3>{t('enterprise.settings.billingSettings.billingSettings')}</h3>
			<div className="centred-content">
				{errors.billingEmail?.type === 'pattern' && (
					<div className="placard error">Please enter a valid email address ✉️</div>
				)}
				<form className="rows" onSubmit={handleSubmit(handleUpdateSettings)}>							
					<div className="row">
						<div className="row-label">{t('enterprise.settings.billingSettings.subscriptionType.subscriptionType')}</div>		
						<div className="vertical-row-content text-padding">
							<div className="subscription-badge">{t('enterprise.settings.billingSettings.subscriptionType.tierComplimentary')}</div>
						</div>
					</div>
					<div className="row">
						<label htmlFor="billingEmail" className="row-label">{t('enterprise.settings.billingSettings.billingEmail')}</label>									
						<div className="vertical-row-content no-padding">
							<input 
								aria-invalid={errors.billingEmail ? "true" : "false"}
								className="account-form"
								defaultValue={user.user.enterpriseAccount.billingEmail}
								id="billingEmail" 
								name="billingEmail" 
								{...register('billingEmail', { required: true, pattern: /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/ })}
								type="email"
							/>
						</div>
					</div>
					<div className="row">				
						<Button 
							disabled={loading ? 'disabled' : ''} 
							label={loading ? t('enterprise.settings.billingSettings.saving') : t('enterprise.settings.billingSettings.buttonSaveChanges')}
						/>
					</div>
				</form>
			</div>
		</div>
	)
	
}

export default function EnterpriseSettings() {
	
	const user = useAuthContext();		
	const { t } = useTranslation();
	
	return(
		<div className="container">
			<div className="content enterprise">
				<EnterpriseSidebar />
				<div className="enterprise-body settings">
					{!user.user ? (
						<>
							<section className="card skeleton"></section>
							<section className="card skeleton"></section>
							<section className="card skeleton"></section>
						</>
					) : (
						<>
							<CompanyDetails />
							<UserSettings />
							<BillingSettings />
						</>
					)}					
				</div>
			</div>
		</div>
	)
	
}