import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DialogContext, useAuthContext } from '../../contexts';

const navLinks = [
		{
			"label": "Courses",
			"path": "/store"
		},
		{
			"label": "FAQs",
			"path": "/faq"
		},
		{
			"label": "Blog",
			"path": "/blog"
		}
]

export function ExternalNav({
		mode
	}) {
		
	const siteMode = process.env.REACT_APP_SITE_MODE;
	
	const path = useLocation().pathname;
	
	const user = useAuthContext();
	
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const ref = useRef(null);
	
	var hidden = false;
	
	if(path==="/signup") {
		hidden = true;
	}
	
	useEffect(() => {
		if(dropdownVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dropdownVisible]);
	
	useEffect(() => {		
		const handleClick = (event) => {
			if(ref.current && !ref.current.contains(event.target)) {
				setDropdownVisible(false);
			}
		}
		
		document.addEventListener('click', handleClick);
		
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [dropdownVisible]);
		
	function handleDropdownToggle(menu) {
		if(dropdownVisible) {			
			setDropdownVisible(false);
		} else {			
			setDropdownVisible(menu);
		}		
	}
	
	if(!hidden) {
		return(
			<nav className="top external">
				<div className="left">
					<button className={`mobile-only hamburger menu-toggle icon${dropdownVisible === 'nav' ? (' active') : ('')}`} onClick={() => handleDropdownToggle('nav')}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="#0B00D1"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" fill="#0B00D1"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z" fill="#0B00D1"/>
						</svg>
					</button>			
					<div className="logo">							
						<Link to="/">
							<img src="/images/icelandic-lessons-logo.svg" alt="Learn Icelandic logo" width="36px" />
							Learn Icelandic
						</Link>
					</div>
				</div>
				<ExternalNavDropdown 
					visibleMenu={dropdownVisible}
					setVisibleMenu={handleDropdownToggle}
					parent={ref}
					handleDropdownToggle={handleDropdownToggle}
				/>	
				<div className="center mobile-hidden">
					<ul className="nav-links">
						{navLinks.map((thisLink, i) =>
							<li>
								<Link
									className={`${thisLink.path===path ? "active" : ""}`}
									to={thisLink.path}>
									{thisLink.label}
								</Link>
							</li>
						)}
					</ul>
				</div>
				<div className="right">
					{user.user ? (
						<>
							<Link to="/dashboard" className="button secondary my-courses">
								My courses
							</Link>
						</>
					) : (
						<>
							{path !== '/login' && (
								<Link to="/login" role="button" className="button secondary">Log in</Link>
							)}
							{path === '/login' ? (
								<Link to="/signup" role="button" className="button secondary mobile-hidden">Sign up</Link>
							) : (
								<Link to="/signup" role="button" className="button mobile-hidden">Sign up</Link>
							)}		
						</>
					)}					
				</div>	
			</nav>
		)
	}
	
}

function ExternalNavDropdown({
		visibleMenu,
		setVisibleMenu,
		parent,
		handleDropdownToggle
	}) {
	
	const siteMode = process.env.REACT_APP_SITE_MODE;
	
	const { t } = useTranslation();	
	const ref = useRef(null);
	
	const dialog = useContext(DialogContext);
	
	function handleReportProblem() {
		handleDropdownToggle();
		dialog.setDialog('report-problem');
	}
	
	return(
		<div className={`dropdown nav-dropdown${visibleMenu === 'nav' ? (' visible') : ('')}`} ref={ref}>
			<ul>
				<li>				
					<a href="/">
						<div className="icon">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M20.9688 12C20.9688 12.5625 20.5 13 19.9688 13H18.9688L19 18C19 18.0938 19 18.1875 19 18.25V18.75C19 19.4688 18.4375 20 17.75 20H17.25C17.1875 20 17.1562 20 17.125 20C17.0938 20 17.0312 20 17 20H15.25C14.5312 20 14 19.4688 14 18.75V16C14 15.4688 13.5312 15 13 15H11C10.4375 15 10 15.4688 10 16V18.75C10 19.4688 9.4375 20 8.75 20H7C6.9375 20 6.90625 20 6.84375 20C6.8125 20 6.78125 20 6.75 20H6.25C5.53125 20 5 19.4688 5 18.75V15.25C5 15.25 5 15.2188 5 15.1875V13H4C3.4375 13 3 12.5625 3 12C3 11.7188 3.09375 11.4688 3.3125 11.25L11.3125 4.28125C11.5312 4.0625 11.7812 4 12 4C12.2188 4 12.4688 4.09375 12.6562 4.25L20.625 11.25C20.875 11.4688 21 11.7188 20.9688 12Z" fill="#0B00D1"/>
							</svg>	
						</div>
						Home
					</a>
				</li>
			</ul>
			<ul>
				{navLinks.map((thisLink, i) =>					
					<li>
						<a href={thisLink.path}>
							{thisLink.label}
						</a>
					</li>
				)}
			</ul>
		</div>
	)
	
}