export function ProgressBar({
	width,
	lesson
}) {
	
	return (
		<div className={`progress-wrapper ${lesson && ( " lesson-progress")}`}>
			<div className="progress-bar" style={{width: width}}></div>					
		</div>
	)
	
}