import { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { stringify } from 'qs';

import { usePostHog } from 'posthog-js/react';

import { useAuthContext } from '../../contexts';

import { getCourseData } from '../../fetches';
import { getToken } from '../../helpers';

import { Button, ProgressDonut, Spinner } from '../../components/atoms';

import { Inclusions, ProductIntro, ProductCarousel, ProductDetails } from './productComponents';
import { HumanMadeDialog, LevelInfoDialog, VendorInfoDialog } from './productDialogs';
import { Reviews } from './reviewComponents';
import { createPurchase, getPaymentLink, getProductData, removeTrialPeriod } from './productHelpers';

import { PaymentProcessing, PaymentSuccess } from './paymentComponents';

export default function Product() {
	
	const authToken = getToken();
	const user = useAuthContext();
	
	const posthog = usePostHog();
	
	const productSlug = useParams().productSlug;
	
	const [searchParams, setSearchParams] = useSearchParams();
	
	const squareOrderIdQuery = searchParams.get('orderId');
	
	const [productData, setProductData] = useState(null);	
	const [purchased, setPurchased] = useState(false);
	
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	
	const [orderID, setOrderID] = useState(null);
	const [squarePaymentLink, setSquarePaymentLink] = useState(null);
	const [squareOrderID, setSquareOrderID] = useState(squareOrderIdQuery);
	const [squareReceiptURL, setSquareReceiptURL] = useState(null);
	
	const [paymentProcessing, setPaymentProcessing] = useState(false);
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const [checkingStatus, setCheckingStatus] = useState(false);
	
	const [paymentStatus, setPaymentStatus] = useState("Processing…");
	
	const [countdown, setCountdown] = useState(0);
	
	const [dialogVisible, setDialogVisible] = useState(false);
	
	const siteMode = process.env.REACT_APP_SITE_MODE;
	
	useEffect(() => {
		if(productData) {
			document.title = `${productData.externalName} | Learn Icelandic`;
		}
	}, [productData]);
	
	useEffect(() => {
		if(dialogVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dialogVisible]);
	
	useEffect(() => {
		const id = setInterval(() => {
			setCountdown(c => c + 1);
			if(countdown>4) {
				checkPaymentStatus(id);
			}		
		}, 1000);
		return () => {
			clearInterval(id);
		};
	}, [countdown]);
	
	useEffect(() => {
		if(!productData && !user.loading) {
			getProductData(productSlug)
			.then((product) => {
				if(product.length>0) {
					setProductData(product[0]);
					if(user.user) {
						for(const thisPurchase of user.purchases) {
							if(thisPurchase.product.documentId===product[0].documentId) {
								setPurchased(true);
							}
						}
					}	else {
						setLoading(false);
					}
				} else {
					window.location.replace('/not-found');
				}				
			})
			.catch((error) => {
				setError(true);
				setLoading(false);
			});
		}
	}, [productData, user]);
	
	useEffect(() => {
		if(!purchased) {
			if(productData && !user.loading && user.user) {
				var paymentLinkData = {			
					email: user.user.email,
					price: productData.price,
					productId: productData.documentId,
					productName: productData.name,
					redirectUrl: window.location.href,
					user: user.user.id
				}
				getPaymentLink(paymentLinkData)
				.then((paymentLink) => {
					console.log(paymentLink);
					setLoading(false);
					setOrderID(paymentLink.orderId);
					setSquarePaymentLink(paymentLink.paymentLink);
					if(squareOrderID) {
						setPaymentProcessing(true);
					} else {
						setSquareOrderID(paymentLink.squareOrderId);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.error(error);
				})		
			}
		} else {
			setLoading(false);
		}		
	}, [productData, user, purchased]);
	
	if(productData) {
		var structuredData = {
			"@context": "https://schema.org",
			"@type": "Product",
			"brand": productData.vendor.name,
			"description": productData.cardTagline,
			"name": productData.externalName,
			"image": productData.productCarousel[0].url,
			"offers": {
				"@type": "Offer",
				"availability": "https://schema.org/InStock",
				"price": productData.price,
				"priceCurrency": "GBP"
			}
		}
	}
		
	async function checkPaymentStatus(timeout) {
		setCountdown(0);
		if(!checkingStatus && !paymentSuccessful && paymentProcessing) {
			setCheckingStatus(true);
			console.log(orderID);
			console.log('Checking status of order '+squareOrderID);
			const orderStatusResponse = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/getOrderStatus/${squareOrderID}`);
			if(!orderStatusResponse.ok) {
				const message = `Error fetching order status: ${orderStatusResponse.status}`;
				throw new Error(message);
			}
			const orderData = await orderStatusResponse.json();
			if(orderData?.data.state === 'DRAFT') {
				setCheckingStatus(false);
				setPaymentStatus("Please complete your payment with Square, then return here");
			} else if(orderData?.data.state === 'OPEN') {
				clearTimeout(timeout);
				const invoiceLink = orderData.data.receiptURL;
				console.log(orderData.data);
				setSquareReceiptURL(orderData.data.receiptURL);
				const purchaseData = {
					user: user.user.id,
					orderId: orderID,
					product: productData.documentId,
					invoiceLink: invoiceLink
				}
				createPurchase(purchaseData)
				.then((purchase) => {			
					if(purchase.data) {
						setPaymentProcessing(false);
						setPaymentSuccessful(true);								
						document.body.classList.remove('no-scroll');
						posthog.capture('product_purchased', {										
							type: productData.type,
							price: productData.price,
							productID: productData.productID,
							name: productData.externalName,
							levelCEFR: productData.levelCEFR,
							instructionLang: productData.instructionLang,
							vendor: {											
								vendorID: productData.vendor.vendorID,
								name: productData.vendor.name
							}
						})
					}								
				})
			}		
		}
	}
	
	function handlePaymentProcessingPopup() {
		setPaymentProcessing(true);
		window.scrollTo(0, 0);
		document.body.classList.toggle('no-scroll');
	}
	
	if(loading) {
		return(
			<div className="container external product-page">
				<div className="prose">
					<h1 className="skeleton">Test course</h1>
					<p className="skeleton">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis blandit ante id aliquet. Suspendisse sit amet tellus quis enim consequat aliquam.</p>
				</div>
				<div className="card product-carousel skeleton"></div>
				<div className="card product-details skeleton"></div>
			</div>
		)
	} else if(productData) {
		return(
			<>
				{paymentProcessing && (
					<PaymentProcessing status={paymentStatus} />
				)}
				{paymentSuccessful && (
					<PaymentSuccess squareReceiptURL={squareReceiptURL} productData={productData} />
				)}
				<div className="container external product-page">
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(structuredData),
						}}
					/>
					<ProductIntro product={productData} setDialogVisible={setDialogVisible} />
					<div className="card product-carousel">
						<ProductCarousel product={productData} />				
						<div className="product-cta-wrapper">
							{purchased ? (
								<div className="course-purchased-badge">
									<ProgressDonut label="Purchased" status="complete" />
								</div>
							) : (
								<div className="product-price-wrapper">
									<div className="price">£{productData.price}</div>
									{productData.promoText && 
										<div className="product-promo-text">
											<svg className="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M4.125 3.25H7.61719C8.01562 3.25 8.39062 3.41406 8.67188 3.69531L12.7969 7.82031C13.3828 8.40625 13.3828 9.36719 12.7969 9.95312L9.67969 13.0703C9.09375 13.6562 8.13281 13.6562 7.54688 13.0703L3.42188 8.94531C3.14062 8.66406 3 8.28906 3 7.89062V4.375C3 3.76562 3.49219 3.25 4.125 3.25ZM5.625 6.625C6.02344 6.625 6.375 6.29688 6.375 5.875C6.375 5.47656 6.02344 5.125 5.625 5.125C5.20312 5.125 4.875 5.47656 4.875 5.875C4.875 6.29688 5.20312 6.625 5.625 6.625Z" fill="#EC1801"/>
											</svg>
											{productData.promoText}
										</div>
									}									
								</div>
							)}			
							{user.user ? (
								<>
									{!purchased ? (
										<Link to={squarePaymentLink}>
											<Button icon="next" iconPlacement="after" onClick={handlePaymentProcessingPopup}>Purchase course</Button>
										</Link>
									) : (
										<Link to={`/c/${productData.slug}`}>
											<Button role="secondary" icon="next" iconPlacement="after">Open course</Button>
										</Link>
									)}
								</>
							) : (
								<Link to="/login">
									<Button icon="next" iconPlacement="after">Log in and purchase course</Button>
								</Link>
							)}						
						</div>
					</div>
					<div className="product-details-wrapper">
						<div className="product-details-left">
							<ProductDetails product={productData} />
							<Reviews product={productData.documentId} />
						</div>
						<Inclusions />
					</div>
				</div>
				<VendorInfoDialog vendorId={productData.vendor.documentId} visible={dialogVisible} setVisible={setDialogVisible} />
				<LevelInfoDialog level={productData.levelCEFR} visible={dialogVisible} setVisible={setDialogVisible} />
				<HumanMadeDialog visible={dialogVisible} setVisible={setDialogVisible} />
			</>
		)
	}	
	
}