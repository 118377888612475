import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { usePostHog } from 'posthog-js/react';

import { useAuthContext } from '../../contexts';
import { getToken } from '../../helpers';

import { Button } from '../../components/atoms';
import { BlockRenderer, RenderMarkdown } from '../../components/renderers';

import Exercise from '../../components/exercise/exercise';
import Conversation from './lessonPageTypes/conversation';
import Verb from './lessonPageTypes/verb';
import { LessonSummary } from './lessonPageTypes/summary';

function calculateLessonProgress(pageProgressObject, progressObject) {
	var newProgressObject = [];
	progressObject.map((thisLesson, i) => {
		if(thisLesson.documentId === pageProgressObject.lessonID) {
			var newLessonPageObject = [];
			var newLessonProgress = 0;
			thisLesson.lessonPages.map((thisLessonPage, j) => {
				if(thisLessonPage.documentId === pageProgressObject.pageID) {
					newLessonPageObject.push({
						...thisLessonPage,
						progress: pageProgressObject.progress						
					})
					newLessonProgress = newLessonProgress+pageProgressObject.progress;
				} else {
					newLessonPageObject.push(thisLessonPage);
					newLessonProgress = newLessonProgress+thisLessonPage.progress;
				}
			});
			newLessonProgress = Math.round(newLessonProgress/thisLesson.lessonPages.length);
			newProgressObject.push({
				...thisLesson,
				progress: newLessonProgress,
				lastViewedPage: pageProgressObject.slug,
				lessonPages: newLessonPageObject
			});
		} else {
			newProgressObject.push(thisLesson);
		}
	});
	return newProgressObject;
}

function calculateCourseProgress(progressObject) {
	var courseProgress = 0;
	progressObject.map((thisLesson, i) => {
		courseProgress = courseProgress+thisLesson.progress;
	});
	courseProgress = Math.round(courseProgress/progressObject.length);
	return courseProgress;
}

export function PageNavButtons({ 
		courseSlug,
		lessonID,
		lessonSlug,
		loading,
		nextPageDisabled,
		pageID,
		pageList,
		progress,
		progressUpdating,
		setLoading,
		setProgressUpdating,
		setNextPageDisabled
	}) {	
		
	const posthog = usePostHog();
		
	const { t } = useTranslation();
	
	const authToken = getToken();
	const user = useAuthContext();
	
	function handlePageNavigate(updateProgress, type) {		
		setLoading(true);
		setNextPageDisabled(false);
		if(type === 'conversation') {
			document.body.classList.add('conversation');
		} else {
			document.body.classList.remove('conversation');
		}
		const pageProgressObject = {
			lessonID: lessonID,
			pageID: pageID,
			slug: `${pageList.nextPage[0] ? `/${courseSlug}/${lessonSlug}/${pageList.nextPage[0].attributes.slug}/` : ''}`,
			progress: 100
		};
		if(updateProgress) {
			if(progress) {
				setNextPageDisabled(true);
				const newLessonObject =	calculateLessonProgress(pageProgressObject, progress.attributes.lessonProgress);
				const newCourseProgress = calculateCourseProgress(newLessonObject);
				const authToken = getToken();			
				fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses/${progress.documentId}`, {
					method: 'PUT',
					headers: {
						'Strapi-Response-Format': 'v4',
						'Authorization': `Bearer ${authToken}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						data: {
							progress: newCourseProgress,
							lessonProgress: newLessonObject
						}		
					})
				})
				.then((response) => response.json())
				.then((data) => { 			
					posthog?.capture('update_progress', {
						newProgress: newCourseProgress
					});		
					console.log('Course progress updated');
					setNextPageDisabled(false);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
			}			
		}
	}
		
	return(
		<div className="nav-buttons">
			<div className="left">
			{pageList.lastPage.length > 0 ? (			
				<NavigationButton
					icon="previous"
					iconPlacement="before"
					label={pageList.lastPage[0].attributes.longTitle ? (
						pageList.lastPage[0].attributes.longTitle
					) : (
						pageList.lastPage[0].attributes.title
					)} 
					onClick={() => handlePageNavigate(false, pageList.lastPage[0].attributes.type)}
					role="secondary"
					to={`/c/${courseSlug}/${lessonSlug}/${pageList.lastPage[0].attributes.slug}/`}		
				/>
			) : (
				<NavigationButton
					icon="previous"
					iconPlacement="before"
					label={t('lessonPage.buttonBackToOverview')}
					onClick={() => handlePageNavigate(false)}
					role="secondary"
					to={`/c/${courseSlug}/${lessonSlug}/`}
				/>
			)}
			</div>
			<div className="right">
			{pageList.nextPage.length > 0 ? (		
				<NavigationButton
					disabled={nextPageDisabled}
					label={pageList.nextPage[0].attributes.longTitle ? (
						pageList.nextPage[0].attributes.longTitle
					) : (
						pageList.nextPage[0].attributes.title
					)}
					icon="next"
					iconPlacement="after"
					onClick={() => handlePageNavigate(true, pageList.nextPage[0].attributes.type)} 
					to={`/c/${courseSlug}/${lessonSlug}/${pageList.nextPage[0].attributes.slug}/`}
				/>
			) : (
				<NavigationButton
					disabled={nextPageDisabled}
					label={t('lessonPage.buttonFinishLesson')}
					icon="next"
					iconPlacement="after"
					onClick={() => handlePageNavigate(true)}
					to={`/c/${courseSlug}/`}
				/>
			)}							
			</div>
		</div>
	)
	
}

export function NavigationButton({
	disabled,
	icon,
	iconPlacement,
	label,
	onClick,
	role,
	to
}) {
	return(
		<>
			{disabled ? (				
				<Button 
					disabled={disabled}
					icon={icon}
					iconPlacement={iconPlacement}
					label={label}
					role={role}
				/>
			) : (			
				<Link 
					to={to}
					onClick={onClick}
				>	
				<Button 
					icon={icon}
					iconPlacement={iconPlacement}
					label={label}
					role={role}
				/>
				</Link>
			)}		
		</>
	)
}

export function SkeletonLoader() {
	return(
		<>
			{document.body.classList.contains('conversation') ? (				
				<>
					<div className="block skeleton"></div>
					<div className="nav-buttons">
						<div className="left">
							<div className="button skeleton"></div>
						</div>
						<div className="right">
							<div className="button skeleton"></div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="prose">
						<h1 className="skeleton">Lesson page 1</h1>
					</div>
					<p className="skeleton">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ante sit amet fermentum facilisis.</p>
					<div className="block skeleton"></div>
					<div className="nav-buttons">
						<div className="left">
							<div className="button skeleton"></div>
						</div>
						<div className="right">
							<div className="button skeleton"></div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export function PageContent({ 
		courseSlug,
		lessonSlug,
		nextPageDisabled,
		pageData,
		progress,
		setNextPageDisabled
	}) {
		
	return(
		<>
			{pageData.attributes.type === 'conversation' && (
				<Conversation content={pageData.attributes} />
			)}
			{pageData.attributes.type === 'verb' && (
				<Verb content={pageData.attributes} />
			)}
			{pageData.attributes.type === 'exercise' && (
				<Exercise 
					content={pageData.attributes} 
					contentID={pageData.documentId}
					nextPageDisabled={nextPageDisabled}
					setNextPageDisabled={setNextPageDisabled}
				/>
			)}
			{((pageData.attributes.type === 'vocab') || (pageData.attributes.type === 'pronunciation') || (pageData.attributes.type === 'summary')) && (
				<>				
					<div className="prose">
						<h1>{RenderMarkdown(pageData.attributes.title)}</h1>
					</div>
					<BlockRenderer content={pageData.attributes.content} />
				</>
			)}
			{pageData.attributes.type === 'summary' && (
				<LessonSummary 
					courseSlug={courseSlug}
					lessonSlug={lessonSlug}
					pageData={pageData} 
					progress={progress}
				/>
			)}
		</>
	)
	
}
