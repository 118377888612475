import { useParams, Link } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import { stringify } from 'qs';

import { getToken } from '../helpers';

import { BreadcrumbsContext } from '../contexts';

import { LoadError } from '../components/atoms';

export default function Lesson() {
	
	const authToken = getToken();
	
	const breadcrumbs = useContext(BreadcrumbsContext);
	const lessonSlug = useParams().lessonSlug;
	
	const [lesson, setLesson] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	
	useEffect(() => {			
		document.body.classList.remove('conversation');
	}, [lesson]);
	
	var q = stringify({
		sort: ['slug:asc'],
		filters: {
			slug: {
				$eq: lessonSlug,
			},
		},
		populate: {
			lessonPages: {
				populate: '*',
			},				
			course: {
				populate: '*',
			},
		},
		publicationState: 'live',
	});
	
	useEffect(() => {
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/lessons?${q}`, {
			headers: {
				'Strapi-Response-Format': 'v4',
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			if(data.data.length>0) {
				console.log(data.data[0]);
				setLesson(data.data[0]);
				setLoading(false);
				var newBreadcrumbs = [
					{
						path: '/dashboard',
						title: 'Learn Icelandic'	
					}, 
					{	path: '/c/'+data.data[0].attributes.course.data.attributes.slug+'/', 
						title: data.data[0].attributes.course.data.attributes.name	
					},
					{	path: '/c/'+data.data[0].attributes.course.data.attributes.slug+'/'+data.data[0].attributes.slug+'/', 
						title: data.data[0].attributes.title	
					}
				];
				breadcrumbs.setBreadcrumbs(newBreadcrumbs);
			} else {
				setLesson(data);
				setLoading(false);
			}
		})
		.catch((error) => {
			console.log(error);
			setError(error);
			setLoading(false);
		});
	}, [breadcrumbs, q]);
	
	if(loading) {
		return (
			<div className="container">
				<h1 className="skeleton">&nbsp;&nbsp;&nbsp;&nbsp;</h1>
			</div>
		);
	}
	
	if(error) {
		return (
			<div className="container lesson">
				<LoadError>
					<h2>This course could not be loaded.</h2>
					<p>Either you have not purchased this course or it is no longer available.</p>
				</LoadError>
			</div>
		);
	}
	
	if(lesson) {
		return (
			<>
				<div className="container">
					<div className="prose">
						<h1>{lesson.attributes.title}</h1>
					</div>
					<ul className="course-contents">
						{lesson.attributes.lessonPages.data.map((thisLesson, i) =>
							<Link to={`${thisLesson.attributes.slug}/`} key={i}>
								<li>
									{thisLesson.attributes.longTitle ? (
										thisLesson.attributes.longTitle
									) : (
										thisLesson.attributes.title
									)}
								</li>
							</Link>
						)}
					</ul>
				</div>
			</>
		);
	}
}