import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useAuthContext } from '../../../contexts';
import { setToken } from '../../../helpers';
import { verifyToken } from '../../../fetches';

import { Button } from '../../../components/atoms';

import { SignupForm, Success } from './signupComponents';
import { SignupBanner } from './signupBanner';

export default function Signup() {
		
	const siteMode = process.env.REACT_APP_SITE_MODE;
	
	const { setUser } = useAuthContext();
	
	const [searchParams, setSearchParams] = useSearchParams();
	const token = searchParams.get("token");
	
	const [loading, setLoading] = useState(false);	
	const [success, setSuccess] = useState(false);
	const [validToken, setValidToken] = useState(false);	
	
	const [enterpriseMode, setEnterpriseMode] = useState(false);
	
	const [prefilledName, setPrefilledName] = useState(null);
	const [prefilledEmail, setPrefilledEmail] = useState(null);
	
	const [email, setEmail] = useState(null);
	
	useEffect(() => {
		document.title = "Sign up | Learn Icelandic";
		if(token) {
			setLoading(true);	
			verifyToken({
				token: token
			})
			.then((result) => {
				if(result.data) {
					setValidToken(true);
					setPrefilledEmail(result.data.email);
					if(result.data.type==="enterprise") {
						setEnterpriseMode(true);
					}					
				}
			})
			.catch((error) => {				
				setLoading(false);
				console.log(error);
			})
		} else {
			setPrefilledName(localStorage.getItem('signupFirstname'));
			setPrefilledEmail(localStorage.getItem('signupEmail'));
		}
	}, [searchParams]);
	
	return(
		<>
			<div className="signup-login">
				<SignupBanner />
				<div className={`card signup-login-form-card${success ? " signup-success" : ""}`}>
					<div className="blurb">
						<div className="logo">	
							<Link to="/">
								<img src="/images/icelandic-lessons-logo.svg" alt="Learn Icelandic logo" width="36px" />
								Learn Icelandic
							</Link>
						</div>
						{!success &&
							<p>Góðan daginn! 👋 Let’s get your account set up.</p>
						}						
					</div>	
					{success ? (
						<Success
							email={email}
							loading={loading}
							setLoading={setLoading}
						/>
					) : (
						<>
							{(siteMode==="beta" && !validToken) ? (
								<div className="card secondary">
									<div className="row centred spaced no-padding">
										<span><strong>✨ Learn Icelandic is in closed beta.</strong> Right now, you can only sign up if you’ve received an invite. We’ll be launching to the public soon, so stay tuned!</span>
									</div>
								</div>
							) : (
								<SignupForm 
									enterpriseMode={enterpriseMode}
									loading={loading}
									prefilledEmail={prefilledEmail}
									prefilledName={prefilledName}
									setEmail={setEmail}
									setLoading={setLoading}
									setSuccess={setSuccess}
								/>	
							)}						
						</>
					)}				
					{(!success && !loading) &&						
						<div className="blurb">
							Already have an account? <Link to="/login">Log in →</Link>
						</div>
					}	
				</div>		
			</div>
		</>
	)	
	
}
