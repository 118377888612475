import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { calculateTrialDays } from '../../helpers';
import { createProgressEntry, getCourseData, getProgressData } from '../../fetches';

import { BreadcrumbsContext, useAuthContext } from '../../contexts';

import { Button, LoadError } from '../../components/atoms';
import { Paywall } from '../../components/paywall';
import { RichTextBlockRenderer } from '../../components/renderers';

import { CourseDropdown, LessonCard, ResetProgressDialog, SkeletonLoader } from './courseComponents';

export default function Course() {
	
	const user = useAuthContext();
	
	const { t } = useTranslation();
	
	const breadcrumbs = useContext(BreadcrumbsContext);
	const courseSlug = useParams().courseSlug;
	
	var trialDays = null;
				
	if(user.user) {
		trialDays = calculateTrialDays(user.user.trialExpiry);
	}
	
	const [course, setCourse] = useState(null);	
	const [progress, setProgress] = useState(null);
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	const [progressCreated, setProgressCreated] = useState(false);
	
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [dialogVisible, setDialogVisible] = useState(false);
	
	useEffect(() => {			
		document.body.classList.remove('conversation');
		if(course) {
			document.title = `${course.name}${t('site.titleSuffix')}`;
		}
	}, [course]);
	
	useEffect(() => {
		if(dialogVisible || dropdownVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dialogVisible, dropdownVisible]);
	
	useEffect(() => {
		if(user.user) {
			var q = stringify({
				filters: {
					slug: {
						$eq: courseSlug
					}
				},
				populate: {
					lessons: {
						populate: {
							lessonPages: {
								populate: '*',
								sort: ['pageNo:asc']
							},
							icon: {
								populate: '*'
							}
						}
					}
				}
			});		
			getCourseData(q)
			.then((courseData) => {			
				if(courseData.data) {
					setCourse(courseData.data[0]);
					getProgressData({
						user: user.user.id,
						course: courseData.data[0].documentId
					})
					.then((progressData) => {
						if(progressData.data.length>0) {
							setLoading(false);
							setProgress(progressData.data[0]);
						} else {
							if(!progressCreated) {
								console.log("Course progress not found");
								createProgressEntry({
									courseId: courseData.data[0].documentId,
									user: user.user.id
								})
								.then((progressEntryData) => {
									setProgressCreated(true);
								});
							} else {
								setProgressCreated(false);
							}
						}
					})
				} else {
					window.location.replace('/not-found');
				}
			})
			.catch((error) => {
				console.log(error);
				setError(true);
				setLoading(false);
			});
		}		
	}, [progressCreated, user.user]);
	
	useEffect(() => {
		if(course) {
			var newBreadcrumbs = [
				{
					path: '/dashboard',
					title: 'Learn Icelandic',
					mobileStyle: 'compact'
				}, 
				{	path: "/c/"+course.slug+"/", 
					title: course.name	
				}
			];
			breadcrumbs.setBreadcrumbs(newBreadcrumbs);		
		}		
	}, [course])
	
	function handleDropdownToggle(menu) {
		if(dropdownVisible) {			
			setDropdownVisible(false);
		} else {			
			setDropdownVisible(menu);
		}		
	}
	
	if(loading || user.loading) {
		return(
			<SkeletonLoader />
		)
	} else {
		if(error) {
			return(
				<div className="container course">
					<LoadError>
						<h2>{t('course.loadError')}</h2>
						<p>{t('course.loadErrorExplanation')}</p>
					</LoadError>
				</div>
			)
		} else {
			return(
				<Paywall course={course.documentId} user={user}>
					<div className="container course">
						<div className="course-header">
							<h1>{course.name}</h1>
							<Button
								icon="options"
								onClick={e => handleDropdownToggle("course-options")}
								role={`toolbar round${dropdownVisible ? ' active' : ''}`}
							/>
							<CourseDropdown 
								courseData={course}
								dropdownVisible={dropdownVisible}
								setDialogVisible={setDialogVisible}
								setDropdownVisible={setDropdownVisible} 
							/>
						</div>
						<RichTextBlockRenderer content={course.intro} />
						<section className="lessons">
						{course.lessons.map((thisLesson, i) =>
							<LessonCard 
								content={thisLesson}
								courseSlug={courseSlug}
								key={i}
								progress={progress.lessonProgress}
								trialDays={trialDays} 
							/>
						)}
						</section>
					</div>
					<ResetProgressDialog 
						courseData={course}
						dialogVisible={dialogVisible}
						progressId={progress.documentId}
						setVisible={setDialogVisible}
						visible={dialogVisible}
					/>
				</Paywall>
			)
		}
	}

}