import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { usePostHog } from 'posthog-js/react';

import { useAuthContext } from '../../contexts';
import { setToken } from '../../helpers';

import { Button } from '../../components/atoms';
import { ForgotPasswordDialog } from '../../components/dialogs';

export default function Login() {
	
	const posthog = usePostHog();
	const siteMode = process.env.REACT_APP_SITE_MODE;
	
	const redirectURL = useLocation().state;
	
	console.log(redirectURL);
	
	const { setUser, setPurchases } = useAuthContext();
	
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	
	const [dialogVisible, setDialogVisible] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	useEffect(() => {
		document.title = "Log in | Learn Icelandic";
		if(dialogVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dialogVisible]);
	
	function handleForgotPassword(e) {
		e.preventDefault();
		setDialogVisible('forgot-password');
	}
	
	async function updateLastLogin(data, token, user) {
		console.log("Updating last login…");
		try {
			const updateResponse = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user}`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					lastLogin: new Date()
				}),
			});
			const updateResponseData = await updateResponse.json();		
			if(updateResponseData?.error) {
				throw data?.error;
			} else {
				posthog?.capture('user_logged_in');
				if(data.user.onboarded) {
					if(redirectURL) {
						window.location.replace(redirectURL);
					} else {
						window.location.replace('/dashboard');
					}					
				} else {
					window.location.replace('/onboarding');
				}			
			}
		} catch(error) {
			console.error(error);
		}		
	}
	
	async function handleLogin(formData) {
		setLoading(true);
		try {
			const value = {
				identifier: formData.email,
				password: formData.password				
			};
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(value),
			});	
			const data = await response.json();
			if(data?.error) {
				throw data?.error;
			} else {
				setToken(data.jwt);
				setUser(data.user);	
				setAuthenticated(true);		
				localStorage.removeItem('pageCache');		
				updateLastLogin(data, data.jwt, data.user.id);			
			}
		} catch(error) {
			console.error(error);
			if(error.message === 'Invalid identifier or password') {
				setError({ message: 'Incorrect email address or password. 😞 Check you’ve typed them correctly and try again.' });
			} else if(error.message === 'Your account has been blocked by an administrator') {
				setError({ message: 'Your account has been blocked.' });
			} else if(error.message === 'Load failed') {
				setError({ message: 'Unable to connect to authentication server.' });
			} else {
				setError(error);
			}			
		} finally {
			setLoading(false);
		}
	}
	
	return(
		<>
			<div className="container account external">
				<div className="blurb">
					<h1>Log in</h1>
				</div>	
				<div className="card">
					{error && (
						<div className="placard error">						
							{error.message}
						</div>
					)}
					{errors.email?.type === 'required' && (
						<div className="placard error">Please enter your email address ✉️</div>
					)}
					{errors.email?.type === 'pattern' && (
						<div className="placard error">Please enter a valid email address ✉️</div>
					)}
					{errors.password?.type === 'required' && (
						<div className="placard error">Please enter your password 🔐</div>
					)}
					{authenticated && (
						<div className="placard success">Logged in! 😎 Loading…</div>
					)}
					<div className="centred-content">
						<form onSubmit={handleSubmit(handleLogin)}>
							<div className="rows">							
								<div className="row">
									<label htmlFor="email" className="row-label"><strong>Email address</strong></label>
									<input 
										type="email" 
										id="email" 
										name="email" 
										className="account-form"
										{...register('email', { required: true, pattern: /^[-a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
										aria-invalid={errors.email ? "true" : "false"}
										disabled={(loading || authenticated) && ("disabled")}
									/>
								</div>
								<div className="row">
									<label htmlFor="password" className="row-label">Password</label>
									<div className="vertical-row-content no-padding">
										<input 
											type="password" 
											id="password" 
											name="password" 
											className="account-form"
											{...register('password', { required: true })}
											aria-invalid={errors.password ? "true" : "false"}
											disabled={(loading || authenticated) && ("disabled")}
										/>
										<a className="highlight" onClick={e => handleForgotPassword(e)}>Forgotten password?</a>
									</div>
								</div>
								<div className="row centred">						
									{(loading || authenticated) ? (
										<Button	label="Logging in…" disabled={true} />			
									) : (
										<button type="submit">Log in</button>			
									)}							
								</div>
							</div>
						</form>
					</div>
				</div>						
				<div className="card secondary">
					{siteMode==="beta" ? (
						<div className="row centred spaced no-padding">
							<span><strong>✨ Learn Icelandic is in closed beta.</strong> Right now, you can only sign up if you’ve received an invite. We’ll be launching to the public soon, so stay tuned!</span>
						</div>
					) : (
						<div className="row centred spaced no-padding">
							<p><strong>Not got an account?</strong> Get a 7-day free trial of Beginners 1.</p>
							<Link to="/signup" className="full-width-mobile">
								<Button role="secondary">Sign up</Button>
							</Link>
						</div>
					)}
				</div>	
			</div>			
			<ForgotPasswordDialog visible={dialogVisible} setVisible={setDialogVisible} />
		</>
	)	
	
}
