import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbsContext, useAuthContext } from '../../contexts';

import { DetailsSection, PasswordSection, AccountSection, CommsSection, AdvancedSection } from './accountComponents';
import { PasswordDialog, DeleteAccountDialog } from '../../components/dialogs';

export default function Account() {
	
	const { t, i18n } = useTranslation();
	const breadcrumbs = useContext(BreadcrumbsContext);

	const user = useAuthContext();
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	const [dialogVisible, setDialogVisible] = useState(false);
	
	useEffect(() => {			
		document.body.classList.remove('conversation');
		breadcrumbs.setBreadcrumbs();
		document.title = `${t('account.title')}${t('site.titleSuffix')}`;
	}, []);
	
	useEffect(() => {
		if(dialogVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dialogVisible]);
	
	if(user.user) {
		return(
			<>
				<div className="container account">
					<div className="blurb">
						<h1>{t('account.account')}</h1>
					</div>				
					<DetailsSection />
					<PasswordSection
						dialogVisible={dialogVisible}
						setDialogVisible={setDialogVisible}
					/>
					<CommsSection />
					<AccountSection 
						dialogVisible={dialogVisible}
						setDialogVisible={setDialogVisible}
					/>
					{user.user.advancedMode && (
						<AdvancedSection />
					)}					
				</div>
				<PasswordDialog visible={dialogVisible} setVisible={setDialogVisible} />
				<DeleteAccountDialog visible={dialogVisible} setVisible={setDialogVisible} />
			</>
		)	
	} else {
		return (
			<>
				<div className="container account">
					<div className="blurb">
						<h1>{t('account.account')}</h1>
					</div>						
					<section className="card skeleton">
					</section>
					<section className="card skeleton">
					</section>
					<section className="card skeleton">
					</section>
				</div>
			</>
		)		
	}
		
}
