import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'qs';

import { getToken } from '../../../helpers';

import { DialogContext, useAuthContext } from '../../../contexts';

import { Button, ProgressDonut, Spinner } from '../../../components/atoms';

function calculateGrade(marks, possibleMarks) {
	var grade = marks/possibleMarks*100;
	return Math.round(grade);
}

function calculateAverageGrade(data) {
	var totalGrade = 0;
	data.map((thisExercise, i) => {
		return totalGrade = totalGrade+calculateGrade(thisExercise.correctAnswers, thisExercise.possibleMarks);				
	});
	return Math.round(totalGrade/data.length);
}

export function LessonSummary({ 
		courseSlug,
		lessonSlug,
		pageData,
		progress
	}) {
			
	const authToken = getToken();
	const user = useAuthContext();
	
	const dialog = useContext(DialogContext);
		
	const [marks, setMarks] = useState([]);
	const [marksLoading, setMarksLoading] = useState(true);
	const [marksError, setMarksError] = useState(false);
	
	const [averageGrade, setAverageGrade] = useState(0);
	
	console.log(pageData.attributes.lesson.data.documentId);
	
	if(user.user) {
		var q = stringify({
			filters: {
				user: {
					$eq: user.user.id
				},
				lessonPage: {
					lesson: {
						$eq: pageData.attributes.lesson.data.id
					}
				}
			},
			populate: {
				exercise: {
					fields: ['title']
				},
				lessonPage: {
					populate: {
						fields: ['slug']
					}
				}
			}
		});
	}
	
	function handleReportProblem() {
		dialog.setDialog('report-problem');
	}
		
	async function fetchExerciseMarks() {		
		
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers?${q}`, {
			headers: {
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			if(data.data.length>0) {
				console.log(data.data);
				setMarks(data.data);
				setMarksLoading(false);
				setAverageGrade(calculateAverageGrade(data.data));
			} else {		
				setMarksLoading(false);
				setMarksError(true);		
			}
		})
		.catch((error) => {
			setMarksError(true);		
			setMarksLoading(false);
		});
	}
		
	useEffect(() => {
		if(user.user) {
			fetchExerciseMarks();
		}		
	}, [user.user])
	
	return(
		<div className="lesson-summary">
			<ul className="course-contents">
				{pageData.attributes.lesson.data.attributes.lessonPages.data.map((thisLessonPage, i) =>
					<>
						{thisLessonPage.attributes.type!=="summary" && (
							<Link to={`/c/${courseSlug}/${lessonSlug}/${thisLessonPage.attributes.slug}/`} key={i}>
								<li>
									<img 
									className="icon colour"
									src={`/images/icon-${thisLessonPage.attributes.type}.svg`} 
									alt={thisLessonPage.attributes.type} />
									{thisLessonPage.attributes.longTitle ? (
										thisLessonPage.attributes.longTitle
									) : (
										thisLessonPage.attributes.title
									)}
								</li>
							</Link>		
						)}				
					</>	
				)}
			</ul>
			<div className="progress-summary">
				<div className="card overall-progress">
					{progress ? (
						<>
							<strong>Overall progress in {pageData.attributes.lesson.data.attributes.course.data.attributes.name}</strong>
							<ProgressDonut 
								animate={true}
								label={`${progress.attributes.progress}%`}
								value={progress.attributes.progress}
								size="extra-large"
							/>
						</>
					) : (
						<div className="centred-content">
							<Spinner color="black" />
						</div>
					)}					
				</div>
				<div className="card lesson-grades">
					{marksLoading ? (
						<div className="centred-content">
							<Spinner color="black" />
						</div>
					) : (
						<>							
							<strong>Grades for this lesson</strong>
							{marks.length>0 ? (
								<>
									<table className="grade-table">
										<tbody>
											{marks.map((thisExercise, i) =>
												<tr>
													<td>
														<Link 
															to={`/c/${courseSlug}/${lessonSlug}/${thisExercise.lessonPage.slug}/`}
														>
															{thisExercise.exercise.title}
														</Link>
													</td>
													<td className="grade">{calculateGrade(thisExercise.correctAnswers, thisExercise.possibleMarks)}%</td>
												</tr>									
											)}
											<tr>
												<th className="average">Lesson average</th>
												<th className="grade">{averageGrade}%</th>
											</tr>
										</tbody>
									</table>
								</>
							) : (
								<div className="centred-content padded">
									No grades for this lesson
								</div>
							)}
						</>			
					)}					
				</div>
				<div className="card secondary feedback-reminder">
					<div className="prose">
						<h2>How did you find this lesson?</h2>
						<p>Let us know what you thought of the lesson content. Was it too hard or too easy? Was anything missing?</p>
					</div>
					<Button onClick={handleReportProblem} role="secondary" fullWidth={true}>Leave feedback</Button>
				</div>
			</div>
		</div>
	)
	
}