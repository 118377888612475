import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { getToken, parsePageList } from '../../helpers';
import { checkPageCache, fetchPageCache, updatePageCache } from './lessonPageHelpers';

import { BreadcrumbsContext, useAuthContext } from '../../contexts';
import { ProgressBar } from './progressBar';

import { LoadError } from '../../components/atoms';
import { Paywall } from '../../components/paywall';
import { PageContent, PageNavButtons, SkeletonLoader } from './lessonPageComponents';

export default function LessonPage() {
		
	const authToken = getToken();
	const user = useAuthContext();
	
	const { t } = useTranslation();
	
	const breadcrumbs = useContext(BreadcrumbsContext);
	const courseSlug = useParams().courseSlug;
	const lessonSlug = useParams().lessonSlug;
	const pageSlug = useParams().pageSlug;

	const [pageData, setPageData] = useState(null);
	const [pageList, setPageList] = useState({
		pageContext: {
			progressBarWidth: 0
		}});
	
	const [progress, setProgress] = useState(null);
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	const [nextPageDisabled, setNextPageDisabled] = useState(false);
	
	useEffect(() => {			
		if(pageData) {
			document.body.classList.toggle('conversation', pageData.attributes.type === 'conversation');
			fetchProgressData();
			document.title = `${pageData.attributes.longTitle ? pageData.attributes.longTitle : pageData.attributes.title}${t('site.titleSuffix')}`;
		}
	}, [pageData]);
	
	var q = stringify({
		sort: ['slug:asc'],
		filters: {
			slug: {
				$eq: pageSlug,
			},
		},
		populate: {
			content: {
				on: {
					'lesson-page-blocks.callout': {
						populate: '*'
					},
					'lesson-page-blocks.conversation': {
						populate: ['audio', 'row']
					},
					'lesson-page-blocks.paragraph': {
						populate: '*'
					},
					'lesson-page-blocks.paradigm': {
						populate: {
							headerRow: {
								populate: '*'
							},
							bodyRows: {
								populate: '*'
							}
						}
					},
					'lesson-page-blocks.verb': {
						populate: '*'
					},
					'lesson-page-blocks.vocab-block': {
						populate: ['image', 'row.image', 'row.audio']
						/* orderBy: { column: 'asc' } */
					},
				},
			},
			exercise: {
				populate: {
					content: {
						on: {
							'exercise-blocks.gap-filling': {
								populate: {
									rows: {
										populate: ['images']
									}
								}
							},
							'exercise-blocks.order-chips': {
								populate: {
									rows: {
										populate: {
											answers: '*'
										}
									}
								}
							},
							'exercise-blocks.bucket-sort': {
								populate: {
									buckets: {
										populate: '*'
									},
									rows: {
										populate: '*'
									},
								}
							}
						}
					}
				}				
			},
			lesson: {
				populate: {
					lessonPages: {
						populate: '*',
						sort: ['pageNo:asc']
					},
					course: {
						populate: '*'
					}
				}
			},
		},
		publicationState: 'live',
	});
	
	const fetchPageData = (retries) => {
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/lesson-pages?${q}`, {
			headers: {
				'Strapi-Response-Format': 'v4',
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			if(data.data.length>0) {
				setLoading(false);
				setPageData(data.data[0]);
				if(!user.user.disableCaching) {
					updatePageCache(data.data[0]);
				}				
				setPageList(parsePageList(data.data[0]));
				var newBreadcrumbs = [
					{
						path: '/dashboard',
						title: 'Learn Icelandic',
						mobileStyle: 'compact'
					}, 
					{	path: '/c/'+data.data[0].attributes.lesson.data.attributes.course.data.attributes.slug+'/', 
						title: data.data[0].attributes.lesson.data.attributes.course.data.attributes.name,
						mobileStyle: 'hidden'	
					},
					{	path: '/c/'+data.data[0].attributes.lesson.data.attributes.course.data.attributes.slug+'/'+data.data[0].attributes.lesson.data.attributes.slug+'/', 
						title: data.data[0].attributes.lesson.data.attributes.title,
						courseSlug: courseSlug,
						dropdownItems: data.data[0].attributes.lesson.data.attributes.lessonPages.data,
						dropdownOnClick: () => setLoading(true)
					}
				];
				breadcrumbs.setBreadcrumbs(newBreadcrumbs);
			} else {
				setError(true);				
				setLoading(false);
			}
		})
		.catch((error) => {
			setPageData(null);	
			setError(true);		
			setLoading(false);
			console.log(error);
			if(retries > 0) {
				console.log(`Retrying request, ${retries} attempts left…`);
				return fetchPageData(retries - 1);
			}
		});
	}
	
	if(user.user && pageData) {
		var progressQ = stringify({
			filters: {
				user: {
					$eq: user.user.id,
				},
				course: {
					documentId: {
						$eq: pageData.attributes.lesson.data.attributes.course.data.documentId	
					}					
				}
			}
		});
	}
	
	async function fetchProgressData() {
		setNextPageDisabled(true);
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses?${progressQ}`, {
			headers: {
				'Strapi-Response-Format': 'v4',
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			setNextPageDisabled(false);
			if(data.data.length>0) {
				setProgress(data.data[0]);
			} else {
				setError(true);				
				setLoading(false);
			}
		})
		.catch((error) => {
			console.log(error);
		});
	}
	
	useEffect(() => {
		if(user.user) {			
			var checkCache = checkPageCache(pageSlug);	
			if(checkCache && !user.user.disableCaching) {				
				setLoading(false);
				var pageData = fetchPageCache(pageSlug);
				setPageData(pageData);
				setPageList(parsePageList(pageData));
				var newBreadcrumbs = [
					{
						path: '/dashboard',
						title: 'Learn Icelandic',
						mobileStyle: 'compact'
					}, 
					{	path: '/c/'+pageData.attributes.lesson.data.attributes.course.data.attributes.slug+'/', 
						title: pageData.attributes.lesson.data.attributes.course.data.attributes.name,
						mobileStyle: 'hidden'	
					},
					{	path: '/c/'+pageData.attributes.lesson.data.attributes.course.data.attributes.slug+'/'+pageData.attributes.lesson.data.attributes.slug+'/', 
						title: pageData.attributes.lesson.data.attributes.title,
						dropdownItems: pageData.attributes.lesson.data.attributes.lessonPages.data,
						dropdownOnClick: () => setLoading(true)
					}
				];
				breadcrumbs.setBreadcrumbs(newBreadcrumbs);
			} else {
				var retries = 3;
				fetchPageData(retries);
			}
		}		
	}, [pageSlug, user.user]);
	
	return(
		<>
			<ProgressBar width={pageList.pageContext.progressBarwidth} lesson={true} />
			<div className="container lesson">
				{loading || user.loading ? (
					<SkeletonLoader />
				) : (
					<>
						{error ? (
							<LoadError>
								<h2>This course could not be loaded.</h2>
								<p>Either you have not purchased this course or it is no longer available.</p>
							</LoadError>
						) : (
							<Paywall course={pageData.attributes.lesson.data.attributes.course.data.documentId} user={user}>					
								<PageContent 
									courseSlug={courseSlug}
									lessonSlug={lessonSlug}
									pageData={pageData}
									nextPageDisabled={nextPageDisabled}
									progress={progress}
									setNextPageDisabled={setNextPageDisabled}
								/>
								<PageNavButtons
									courseSlug={courseSlug}
									lessonID={pageData.attributes.lesson.data.documentId}
									lessonSlug={lessonSlug}
									loading={loading}
									nextPageDisabled={nextPageDisabled}
									pageID={pageData.documentId}
									pageList={pageList}
									progress={progress}
									setLoading={setLoading} 
									setNextPageDisabled={setNextPageDisabled}
								/>
							</Paywall>
						)}	
					</>				
				)}
			</div>
		</>
	)
}