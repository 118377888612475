import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { deleteCourseProgress } from '../../fetches';

import { Button, ProgressDonut } from '../../components/atoms';

export function LessonCard({
		content,
		courseSlug,
		progress,
		trialDays
	}) {

	const { t } = useTranslation();
		
	const [lessonProgress, setLessonProgress] = useState(null);
	const [lastViewedPage, setLastViewedPage] = useState(null);
	
	useEffect(() => {
		if(progress) {
			progress.map((thisLesson, i) => {
				if(thisLesson.documentId === content.documentId) {
					setLessonProgress(thisLesson.progress);
					setLastViewedPage(thisLesson.lastViewedPage);
				}
			});
		}
	}, [progress]);
	
	var buttonLabel = t('course.buttonContinueLesson');
		
	if(lessonProgress === 100) {
		buttonLabel = t('course.buttonReviewLesson');
	} else if(lessonProgress === 0) {
		buttonLabel = t('course.buttonStartLesson');
	}
		
	return(
		<div className="lesson-card">
			<div className="lesson-icon-wrapper">
			{content.icon && (
				<img 
					src={content.icon.url}
					alt={content.icon.alternativeText}
					className="icon large"
				/>
			)}							
			</div>
			<div className="lesson-label">
				<div className="lesson-info">
					<div className="lesson-info-label">
						{content.label}
						<div>
							{(content.lessonPages[0]) && (
								<>
									{lessonProgress > 0 ? (
										<ProgressDonut value={lessonProgress} label={t('dashboard.inProgress')} />
									) : (
										<ProgressDonut value={lessonProgress} label={t('dashboard.notStarted')} /> 
									)}	
								</>
							)}					
						</div>
					</div>	
					<h2>{content.title}</h2>				
				</div>				
				{content.lessonPages[0] ? (											
					<Link to={lastViewedPage ? `/c${lastViewedPage}` : `/c/${courseSlug}/${content.slug}/${content.lessonPages[0].slug}`}>
						<Button 
							label={buttonLabel}
							icon="next"
							iconPlacement="after"
							role={lessonProgress === 100 && "secondary"}
						/>
					</Link>
				) : (
					<Button 
						label={t('course.buttonStartLesson')}
						icon="next"
						iconPlacement="after"
						disabled="disabled"
					/>
				)}	
			</div>
		</div>
	)
	
}

export function SkeletonLoader() {
	return(
		<div className="container course">
			<div className="prose">
				<h1 className="skeleton">Course name</h1>
			</div>
			<p className="skeleton">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ante sit amet fermentum facilisis.</p>
			<section className="lessons">
				<div className="lesson-card skeleton"></div>
				<div className="lesson-card skeleton"></div>
				<div className="lesson-card skeleton"></div>
				<div className="lesson-card skeleton"></div>
				<div className="lesson-card skeleton"></div>
				<div className="lesson-card skeleton"></div>
			</section>
		</div>
	)
}

export function CourseDropdown({
		courseData,
		dropdownVisible,
		setDialogVisible,
		setDropdownVisible
	}) {
		
	const { t } = useTranslation();
		
	function handleCloseDropdown() {
		setDropdownVisible(null);
	}
	
	function handleClearProgress() {
		setDropdownVisible(null);
		setDialogVisible('reset-course-progress');
	}
		
	return(
		<>
			<div className={`action-menu-wrapper${dropdownVisible === 'course-options' ? (' visible') : ('')}`} onClick={handleCloseDropdown}>
			</div>
			<div className={`dropdown action-menu course-options-dropdown${dropdownVisible === 'course-options' ? (' visible') : ('')}`}>
				<ul>
					<li>
						<Link to={`/store/p/${courseData.slug}`} target="_new" onClick={handleCloseDropdown}>
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM12 8C11.4375 8 11 8.46875 11 9C11 9.5625 11.4375 10 12 10C12.5312 10 13 9.5625 13 9C13 8.46875 12.5312 8 12 8ZM13.25 16C13.6562 16 14 15.6875 14 15.25C14 14.8438 13.6562 14.5 13.25 14.5H12.75V11.75C12.75 11.3438 12.4062 11 12 11H11C10.5625 11 10.25 11.3438 10.25 11.75C10.25 12.1875 10.5625 12.5 11 12.5H11.25V14.5H10.75C10.3125 14.5 10 14.8438 10 15.25C10 15.6875 10.3125 16 10.75 16H13.25Z" fill="#0B00D1"/>
								</svg>
							</div>
							{t('course.actionMenu.about')}
						</Link>
					</li>
				</ul>
				<ul>
					<li className="linkless danger" onClick={handleClearProgress}>
						<div className="icon">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.21875 4.5625C9.375 4.21875 9.71875 4 10.0938 4H13.875C14.25 4 14.5938 4.21875 14.75 4.5625L15 5H18C18.5312 5 19 5.46875 19 6C19 6.5625 18.5312 7 18 7H6C5.4375 7 5 6.5625 5 6C5 5.46875 5.4375 5 6 5H9L9.21875 4.5625ZM17.3125 18.5938C17.2812 19.4062 16.625 20 15.8125 20H8.15625C7.34375 20 6.6875 19.4062 6.65625 18.5938L5.96875 8H18L17.3125 18.5938Z" fill="#EC1801"/>
							</svg>		
						</div>
						{t('course.actionMenu.resetProgress')}
					</li>
				</ul>
			</div>
		</>
	)
	
}

export function ResetProgressDialog({ 
		courseData,
		progressId,
		visible,
		setVisible
	}) {
	
	const { t } = useTranslation();
		
	const [disabled, setDisabled] = useState(false);
	
	function handleCloseDialog() {
		setVisible(null);
	}
	
	function handleClearProgress() {
		setDisabled(true);
		deleteCourseProgress(progressId)
		.then((response) => {
			setDisabled(false);
			if(response.status===204) {
				window.location.reload();
			}			
			if(response.status===500) {
				console.log(response);
			}	
		})
	}
		
	return(
		<div className={`dialog-shader${visible === 'reset-course-progress' ? (' visible') : ('')}`}>
			<dialog className="dialog">
				<div className="rows dialog-content">		
					<div className="row">
						<div className="prose">
							<h2>{t('course.resetProgressDialog.heading')}</h2>
							<p>{t('course.resetProgressDialog.areYouSure', { course: courseData.externalName })}</p>
							<p>{t('course.resetProgressDialog.exerciseAnswers')}</p>
						</div>
					</div>		
					<div className="row">
						<Button 
							disabled={disabled ? true : false}
							icon="delete"
							onClick={handleClearProgress}
							role="secondary full-width">
							{t('course.resetProgressDialog.buttonResetProgress')}
						</Button>
					</div>	
				</div>
				<div className="rows anchored-buttons">	
					<div className="row">
						<Button
							disabled={disabled ? true : false}
							onClick={handleCloseDialog}
							role="full-width">
							{t('course.resetProgressDialog.buttonCancel')}
						</Button>
					</div>	
				</div>
			</dialog>
		</div>
	)
	
}	