import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { stringify } from 'qs';

import { useAuthContext } from '../../contexts';
import { getStore } from '../../fetches';
import { formatDate, getToken } from '../../helpers';

import { Spinner } from '../../components/atoms';
import CourseCard from '../../components/courseCard';
import { RichTextBlockRenderer } from '../../components/renderers';

export default function Store() {
	
	const { t, i18n } = useTranslation();
	
	const [loading, setLoading] = useState(true);
	const [storeData, setStoreData] = useState(false);
	
	useEffect(() => {
		getStore()
		.then((data) => {
			if(data.data) {
				console.log(data.data);
				setStoreData(data.data);
				setLoading(false);
			}
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
		});
	}, []);
		
	useEffect(() => {			
		document.body.classList.remove('conversation');
		document.body.classList.remove('no-scroll');
		document.title = `Store${t('site.titleSuffix')}`;
	}, []);
		
	if(loading) {
		return(
			<>
			<div className="container external">
				<div className="prose">
					<h1 className="skeleton">Store</h1>
				</div>
			</div>
			<div className="container store">			
				<section className="store-section">
					<div className="prose">
						<h2 className="skeleton">Icelandic courses</h2>
						<p className="skeleton">Learn Icelandic online with these self-study courses designed by a qualified teacher. Get to grips with grammar, vocabulary, pronunciation and more.</p>
					</div>
					<div className="course-cards-carousel">
						<div className="course-cards-wrapper">
							<div className="card store-course-card skeleton"></div>
							<div className="card store-course-card skeleton"></div>
							<div className="card store-course-card skeleton"></div>
						</div>
					</div>
				</section>
			</div>
			</>
		)
	} else {
		return(
			<>
			<div className="container external">
				<div className="prose">
					<h1>Store</h1>
				</div>
			</div>
			<div className="container store">
				{storeData.content.map((thisSection, i) => 
					<StoreSection content={thisSection} key={i} />
				)}
			</div>
			</>
		)
	}
	
	
}

function StoreSection({
		content
	}) {
		
	return(
		<section className="store-section">
			<div className="prose">
				<h2>{content.h2}</h2>
				<RichTextBlockRenderer content={content.intro} />
				{content.highlights.length>0 && 
					<ul className="ticklist">
						{content.highlights.map((thisHighlight, i) =>
							<li key={i}>
								<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
								</svg>
								{thisHighlight.highlight}
							</li>
						)}						
					</ul>
				}				
			</div>
			<div className="course-cards-carousel">
					<div className="course-cards-wrapper">
						{content.products.map((thisProduct, i) =>								
							<CourseCard content={thisProduct.course} link="/purchase" key={i} />
						)}
				</div>						
			</div>
		</section>
	)
}