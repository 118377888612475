import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { useAuthContext } from '../../contexts';
import { getEnterpriseUsers } from '../../fetches';

import { Button, Spinner } from '../../components/atoms';

import { countActiveUsers } from './enterpriseHelpers';
import { AddUserDialog, EnterpriseSidebar } from './enterpriseComponents';

function AccountStatus({ status }) {
	
	const { t } = useTranslation();
	
	var statusText = t('enterprise.users.statusInactive');
	
	if(status===true) {
		statusText = t('enterprise.users.statusActive');
	}
	
	return(
		<div className={`account-status${status===true ? ' active' : ''}`}>
			{statusText}
		</div>
	)
	
}

export default function EnterpriseUsers() {
	
	const user = useAuthContext();
	
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(true);	
	const [users, setUsers] = useState(null);
	
	const [dialogVisible, setDialogVisible] = useState(false);
	
	useEffect(() => {
		if(!users && user.user) {
			var q = stringify({
				filters: {
					enterpriseAccount: {
						documentId: {
							$eq: user.user.enterpriseAccount.documentId
						}
					}
				}
			});
			getEnterpriseUsers(q)
			.then((users) => {
				setUsers(users);
				setLoading(false);
			})
		}
	}, [user.user]);
	
	return(
		<div className="container">			
			<AddUserDialog visible={dialogVisible} setVisible={setDialogVisible} />
			<div className="content enterprise">
				<EnterpriseSidebar />
				<div className="enterprise-body">
					{user.user && users ? (
						<div className="card secondary toolbar">
							<div className="toolbar-group">
								<Button
									disabled={user.user.enterpriseAccount.seatLimit>countActiveUsers(users) ? '' : 'disabled'}
									icon="add"
									iconPlacement="before"
									onClick={() => setDialogVisible('enterprise-add-user')}
									role="toolbar-button"
								>
									{t('enterprise.users.buttonAddUser')}
								</Button>
								<div className="text-wrapper">
									<span>
										<strong>{countActiveUsers(users)}</strong> {t('enterprise.users.activeSeatsOf')} <strong>{user.user.enterpriseAccount.seatLimit} {t('enterprise.users.activeSeats', { count: user.user.enterpriseAccount.seatLimit })}</strong> {t('enterprise.users.activeSeatsInUse')}
									</span>
								</div>
							</div>
							<input
								autoFocus="autofocus"
								className="toolbar-input"
								id="search"
								name="search"
								placeholder={t('enterprise.users.searchPlaceholder')}
								type="search"
							/>
						</div>
					) : (
						<div className="card secondary toolbar skeleton"></div>
					)}					
					<div className="card no-padding full-height">
						<table className="view-table full-width">
							<thead>
								<tr>
									<th className="sortable" width="55%">{t('enterprise.users.colUser')}</th>
									<th className="sortable" width="20%">{t('enterprise.users.colStatus')}</th>
									<th className="sortable" width="20%">{t('enterprise.users.colRole')}</th>
									<th width="5%"></th>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<tr>
										<td colSpan="3">
											<div className="centred-content padded">
												<Spinner />
											</div>
										</td>
									</tr>
								) : (
									<>
										{users.map((thisUser, i) => 
											<tr key={i}>
												<td>{thisUser.firstName} ({thisUser.email})</td>
												<td>
													<AccountStatus status={thisUser.enterpriseAccountActive} />
												</td>
												<td>
													{thisUser.enterpriseAccountRole==="standard" && t('enterprise.users.roleStandard')}
													{thisUser.enterpriseAccountRole==="admin" && t('enterprise.users.roleAdmin')}
												</td>
												<td>
													<Button 
														icon="options"
														role="secondary table-inline-button"
													/>
												</td>
											</tr>
										)}
									</>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
	
}