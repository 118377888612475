export function checkPageCache(slug) {
	var pageFound = false;
	const pageCache = JSON.parse(localStorage.getItem('pageCache'));
	if(pageCache) {
		pageCache.map((thisPage, i) => {
			if(thisPage.attributes.slug === slug) {			
				pageFound = true;
			}
		});
	}	
	return pageFound;
}

export function fetchPageCache(slug) {
	const pageCache = JSON.parse(localStorage.getItem('pageCache'));
	var data = null;
	pageCache.map((thisPage, i) => {
		if(thisPage.attributes.slug === slug) {			
			data = thisPage;
		}
	});
	return data;
}

export function updatePageCache(data) {
	var newPageCache = [];
	const pageCache = JSON.parse(localStorage.getItem('pageCache'));
	if(pageCache) {
		newPageCache = pageCache;
	}	
	if(!checkPageCache(data.attributes.slug)) {
		console.log("Caching page");
		if(newPageCache.length > 49) {
			newPageCache.shift();
		}
		newPageCache.push(data);
	} 
	newPageCache = JSON.stringify(newPageCache);
	localStorage.setItem('pageCache', newPageCache);
}