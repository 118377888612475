import { useEffect, useState } from 'react';

import { shuffle, arrayEquals } from '../../helpers';

export function ChipsDemo() {
	return(
		<img src="/images/order-chips-demo.gif" className="chips-demo" />
	)
}

function Chip({
		content,
		onClick
	}) {
		
	const [answerFieldState, setAnswerFieldState] = useState(content.status);	
		
	function handleOnClick() {
		onClick();
	}
	
	return(
		<div className="chip" onClick={handleOnClick}>{content}</div>
	)
	
}

export function RenderOrderChips({
		content,
		exercise,
		modified,
		questions,
		setExercise,
		setModified,
		setQuestions
	}) {
		
	const solutions = content.answers;
	
	const [state, setState] = useState("unfilled");
	
	const [userChipsArray, setUserChipsArray] = useState(content.userAnswer);
	const [remainingChips, setRemainingChips] = useState(randomiseChips());
	
	function randomiseChips() {
		var answersArray = content.answers[0].content.split(',');
		var chipsArray = [];
		for(const thisAnswer of answersArray) {
			if(userChipsArray.length>0) {
				if(userChipsArray.indexOf(thisAnswer)<0) {
					chipsArray.push(thisAnswer);
				}
			} else {
				chipsArray.push(thisAnswer);
			}								
		}			
		return shuffle(chipsArray);
	}
	
	function handleMoveChip(action, content) {
		if(state!=="correct") {
			setModified(true);
			if(action==="add") {
				setRemainingChips(
					remainingChips.filter(a => a !== content)
				);
				setUserChipsArray([
					...userChipsArray,
					content
				]);
			}	else if(action==="remove") {
				setUserChipsArray(
					userChipsArray.filter(a => a !== content)
				);
				setRemainingChips([
					...remainingChips,
					content
				]);
			}
		}
	}
	
	useEffect(() => {
		setQuestions(questions.map(thisRow => {
			if(content.number === thisRow.number) {
				return { 
					...thisRow,
					status: state
				};
			} else {
				return thisRow;
			}
		}));
	}, [state]);
	
	useEffect(() => {
		if(state!=="correct") {
			if(remainingChips.length === 0) {	
				for(const thisSolution of solutions) {
					var cleanedUserChipsArray = userChipsArray.map(thisUserChip => {
						return thisUserChip.toLowerCase();
					});
					var thisSolutionArray = thisSolution.content.toLowerCase().split(',');
					if(arrayEquals(cleanedUserChipsArray, thisSolutionArray)) {
						setState("correct");
						setUserChipsArray(userChipsArray.map((thisChip, i) => {
							if(i===0) {
								return thisChip.charAt(0).toUpperCase() + thisChip.slice(1);
							}
							return thisChip;
						}));
						break;
					} else {
						setState("error");
					}
				}			
			}
			setQuestions(questions.map(thisRow => {
				if(content.number === thisRow.number) {
					return { 
						...thisRow,
						userAnswer: userChipsArray
					};
				} else {
					return thisRow;
				}
			}));
		}		
	}, [userChipsArray, setRemainingChips]);
	
	return(
		<li 
			className={`chip-row ${state}`}
			id={`answer-${content.number}`}
		>			
			
			<div className="numeral">{content.number}</div>
			<div className="question-content vertical">
				<div className="prompt">
					{content.prompt}
					<div className="chips-well">
						<div className="chips-wrapper">
							{userChipsArray.length > 0 && (
								<>
									{userChipsArray.map((thisUserChip, i) =>
										<Chip 
											key={i}
											content={thisUserChip}
											onClick={() => handleMoveChip("remove", thisUserChip)}
										/>
									)}		
								</>
							)}
						</div>
						{(state === "correct" && (
							<div className="icon color" title="Right answer">
								<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="12" cy="12.5" r="12" fill="#1CA2B2"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M17.5145 6.64241C17.9881 6.92656 18.1416 7.54082 17.8575 8.0144L11.8575 18.0144C11.6991 18.2785 11.4278 18.4548 11.1222 18.4924C10.8166 18.53 10.5106 18.4248 10.2929 18.207L6.29289 14.207C5.90237 13.8165 5.90237 13.1833 6.29289 12.7928C6.68342 12.4023 7.31658 12.4023 7.70711 12.7928L10.8015 15.8872L16.1425 6.98541C16.4267 6.51183 17.0409 6.35827 17.5145 6.64241Z" fill="white"/>
								</svg>					
							</div>
						))}
						{(state === "error" && (
							<div className="icon color" title="Wrong answer">
								<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="12" cy="12.5" r="12" fill="#EC1801"/>
								<circle cx="12" cy="18.5" r="2" fill="white"/>
								<path d="M12 5.5L12 13.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
								</svg>
							</div>
						))}					
					</div>				
					{content.afterPrompt}
				</div>
				<div className="chips">
					{remainingChips.map((thisChip, i) =>
						<Chip 
							key={i}
							content={thisChip}
							onClick={() => handleMoveChip("add", thisChip)}
						/>
					)}			
				</div>
			</div>
		</li>
	)
	
}