import { useState } from 'react';

import { parseLanguageCode } from '../../helpers';

import { Button, Spinner } from '../../components/atoms';
import { RichTextBlockRenderer } from '../../components/renderers';

export function ProductIntro({ 
		product,
		setDialogVisible
	}) {
		
	return(
		<div className="product-intro">
			<div className="product-intro-title">
				<div className="prose">
					<h1>{product.externalName}</h1>
				</div>
				<div className="product-byline">
					<a className="product-vendor" onClick={() => setDialogVisible('vendor-info')}>
						<img src={product.vendor.avatar.url} className="product-vendor-avatar" />
						{product.vendor.name}
					</a>
					<a className="product-level" onClick={() => setDialogVisible('level-info')}>
						CEFR level <span>{product.levelCEFR}</span>
					</a>
					<a className="product-human-made" onClick={() => setDialogVisible('human-made')}>
						<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.2695 11.6133C12.9375 11.4375 13.5352 12.0352 13.1484 12.5977C12.3047 13.9336 10.7578 14.8125 8.96484 14.8125C7.20703 14.8125 5.66016 13.9336 4.78125 12.5977C4.42969 12.0352 5.02734 11.4375 5.69531 11.6133C6.71484 11.8594 7.80469 11.9648 8.96484 11.9648C10.1602 11.9648 11.25 11.8594 12.2695 11.6133ZM8.36719 7.00781L7.76953 9.29297C7.69922 9.60938 7.38281 9.78516 7.06641 9.71484L4.78125 9.08203C4.14844 8.94141 3.79688 8.27344 3.97266 7.67578C4.11328 7.04297 4.78125 6.65625 5.37891 6.83203L5.97656 6.97266L6.11719 6.41016C6.29297 5.77734 6.92578 5.42578 7.55859 5.60156C8.19141 5.74219 8.54297 6.41016 8.36719 7.00781ZM11.9883 6.97266L12.5859 6.83203C13.1836 6.65625 13.8516 7.04297 13.9922 7.67578C14.168 8.27344 13.8164 8.94141 13.1836 9.08203L10.8984 9.71484C10.582 9.78516 10.2656 9.60938 10.1953 9.29297L9.59766 7.00781C9.42188 6.41016 9.77344 5.74219 10.4062 5.60156C11.0391 5.42578 11.6719 5.77734 11.8477 6.41016L11.9883 6.97266ZM18 9.75C18 14.7422 13.957 18.75 9 18.75C4.00781 18.75 0 14.7422 0 9.75C0 4.79297 4.00781 0.75 9 0.75C13.957 0.75 18 4.79297 18 9.75ZM9 2.4375C4.95703 2.4375 1.6875 5.74219 1.6875 9.75C1.6875 13.793 4.95703 17.0625 9 17.0625C13.0078 17.0625 16.3125 13.793 16.3125 9.75C16.3125 5.74219 13.0078 2.4375 9 2.4375Z" fill="#FD4802"/>
						</svg>
						Human-made
					</a>
				</div>
			</div>
			<RichTextBlockRenderer content={product.intro} />
		</div>
	)
	
}

export function ProductCarousel({ product }) {
	
	const [leftArrowVisible, setLeftArrowVisible] = useState(false);
	const [rightArrowVisible, setRightArrowVisible] = useState(true);
	
	function handleScrollCarousel(direction) {
		const carousel = document.getElementById("image-carousel");
		const scrollPos = carousel.scrollLeft;
		const width = carousel.scrollWidth - carousel.clientWidth;
		if(direction==="right") {
			var newScrollPos = scrollPos+300;
			carousel.scroll(newScrollPos, 0);
			if(newScrollPos>width) {
				setRightArrowVisible(false);
			}	
			if(newScrollPos>0) {
				setLeftArrowVisible(true);
			}	
		} else if(direction==="left") {
			var newScrollPos = scrollPos-300;
			carousel.scroll(newScrollPos, 0);
			if(newScrollPos<1) {
				setLeftArrowVisible(false);
			}	
			if(newScrollPos<width) {
				setRightArrowVisible(true);
			}	
		}
	}
	
	function handleScroll(e) {
		const scrollPos = e.target.scrollLeft;
		const width = e.target.scrollWidth - e.target.clientWidth;
		if(scrollPos<1) {
			setLeftArrowVisible(false);
		}	
		if(scrollPos>0) {
			setLeftArrowVisible(true);
		}	
		if(scrollPos<width) {
			setRightArrowVisible(true);
		}	else {			
			setRightArrowVisible(false);
		}
	}
	
	if(product.productCarousel) {
		return(
			<div className="product-image-carousel">
				<Button icon="arrow-left" role={`arrow-left${leftArrowVisible ? " visible" : ""}`} onClick={() => handleScrollCarousel("left")}/>
				<div className="product-image-carousel-wrapper" id="image-carousel" onScroll={(e) => handleScroll(e)}>
					<div className="product-image-carousel-image-wrapper">
						{product.productCarousel.map((thisImage, i) =>
							<img src={thisImage.url} alt={thisImage.alternativeText} key={i} />
						)}
					</div>
				</div>
				<Button icon="arrow-right" role={`arrow-right${rightArrowVisible ? " visible" : ""}`} onClick={() => handleScrollCarousel("right")}/>
			</div>
		)
	}
	
}

export function ProductDetails({ product }) {
	return(
		<div className="card secondary product-details">
			<div className="column">
				<h2>What you’ll learn</h2>
				<ul>
					{product.features.map((thisFeature, i) =>								
						<li key={i}>
							<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
							</svg>																
							{thisFeature.feature}
						</li>
					)}		
				</ul>				
			</div>
			<div className="column">
				<h2>Details</h2>
				<ul className="feature-list">
					<li>
						<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.75 4.5H12.25V3.25C12.25 2.84375 12.5625 2.5 13 2.5C13.4062 2.5 13.75 2.84375 13.75 3.25V4.5H15C16.0938 4.5 17 5.40625 17 6.5V16.5C17 17.625 16.0938 18.5 15 18.5H5C3.875 18.5 3 17.625 3 16.5V6.5C3 5.40625 3.875 4.5 5 4.5H6.25V3.25C6.25 2.84375 6.5625 2.5 7 2.5C7.40625 2.5 7.75 2.84375 7.75 3.25V4.5ZM4.5 16.5C4.5 16.7812 4.71875 17 5 17H15C15.25 17 15.5 16.7812 15.5 16.5V8.5H4.5V16.5Z" fill="#1C1E21"/>
						</svg>							
						<span><strong>Recommended time</strong><br />{product.duration} weeks</span>
					</li>
					<li>
						<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.6562 3.28125C15.5312 2.40625 16.9375 2.40625 17.8125 3.28125L18.2188 3.6875C19.0938 4.5625 19.0938 5.96875 18.2188 6.84375L12.0312 13.0312C11.7812 13.2812 11.4375 13.5 11.0625 13.5938L7.9375 14.5C7.6875 14.5625 7.40625 14.5 7.21875 14.2812C7 14.0938 6.9375 13.8125 7 13.5625L7.90625 10.4375C8 10.0625 8.21875 9.71875 8.46875 9.46875L14.6562 3.28125ZM16.75 4.34375C16.4688 4.0625 16 4.0625 15.7188 4.34375L14.7812 5.25L16.25 6.71875L17.1562 5.78125C17.4375 5.5 17.4375 5.03125 17.1562 4.75L16.75 4.34375ZM9.34375 10.8438L8.8125 12.6875L10.6562 12.1562C10.7812 12.125 10.875 12.0625 10.9688 11.9688L15.1875 7.75L13.75 6.3125L9.53125 10.5312C9.4375 10.625 9.375 10.7188 9.34375 10.8438ZM9.25 4.5C9.65625 4.5 10 4.84375 10 5.25C10 5.6875 9.65625 6 9.25 6H5.75C5.03125 6 4.5 6.5625 4.5 7.25V15.75C4.5 16.4688 5.03125 17 5.75 17H14.25C14.9375 17 15.5 16.4688 15.5 15.75V12.25C15.5 11.8438 15.8125 11.5 16.25 11.5C16.6562 11.5 17 11.8438 17 12.25V15.75C17 17.2812 15.75 18.5 14.25 18.5H5.75C4.21875 18.5 3 17.2812 3 15.75V7.25C3 5.75 4.21875 4.5 5.75 4.5H9.25Z" fill="#1C1E21"/>
						</svg>
						<span><strong>Assessment</strong><br />{product.assessmentType}</span>
					</li>
					<li>
						<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.3125 10.0938C14.9062 10.625 16 12.0938 16 13.75V14.75C16 16.8438 14.3125 18.5 12.25 18.5H5.75C5.28125 18.5 4.90625 18.0938 5 17.625C5.0625 17.25 5.40625 17 5.75 17H8.75C8.875 17 9 16.9062 9 16.75C9 16.625 8.875 16.5 8.75 16.5H4.75C4.28125 16.5 3.90625 16.0938 4 15.625C4.0625 15.25 4.40625 15 4.75 15H8.75C8.875 15 9 14.9062 9 14.75C9 14.625 8.875 14.5 8.75 14.5H3.75C3.28125 14.5 2.90625 14.0938 3 13.625C3.0625 13.25 3.40625 13 3.75 13H8.75C8.875 13 9 12.9062 9 12.75C9 12.625 8.875 12.5 8.75 12.5H4.75C4.28125 12.5 3.90625 12.0938 4 11.625C4.0625 11.25 4.40625 11 4.75 11H11L9.5 10.4688C9.125 10.3438 8.90625 9.9375 9.03125 9.53125C9.15625 9.15625 9.5625 8.9375 9.96875 9.0625L13.3125 10.0938ZM9.15625 8.125L8.65625 6.59375C8.53125 6.15625 8.8125 5.6875 9.3125 5.625C9.65625 5.59375 10 5.84375 10.0938 6.1875L10.75 8.25L10.25 8.09375C10.0938 8.03125 9.90625 8.03125 9.75 8.03125C9.53125 8.03125 9.34375 8.0625 9.15625 8.125ZM18.2188 7.03125C18.625 7.03125 18.9688 7.375 19 7.78125V11.3125C18.9688 12.7188 18.1875 13.9688 17 14.625V13.75C17 11.6562 15.625 9.8125 13.625 9.125L11.3438 8.4375L10.2812 5.0625C10.125 4.59375 10.4062 4.125 10.9062 4.0625C11.25 4.03125 11.5938 4.28125 11.7188 4.625L12.75 7.9375C12.7812 8.09375 12.9375 8.15625 13.0625 8.125C13.1875 8.0625 13.2812 7.9375 13.2188 7.8125L11.875 3.5C11.75 3.0625 12.0312 2.59375 12.5 2.53125C12.875 2.46875 13.2188 2.71875 13.3125 3.0625L14.8125 7.8125C14.8438 7.9375 15 8 15.125 7.96875C15.25 7.9375 15.3438 7.78125 15.2812 7.65625L14.0938 3.84375C13.9375 3.40625 14.2188 2.9375 14.7188 2.875C15.0625 2.84375 15.4062 3.0625 15.5312 3.40625L17.4062 9.34375L17.4688 7.78125C17.4688 7.375 17.7812 7.03125 18.2188 7.03125Z" fill="#1C1E21"/>
						</svg>
						<span><strong>Instruction language</strong><br />{parseLanguageCode(product.instructionLang)}</span>
					</li>
				</ul>
			</div>
		</div>
	)
}

export function Inclusions() {
	return(
		<div className="card inclusions">
			<h2>What’s included?</h2>
			<ul className="ticklist">
				<li><svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
				</svg>10 lessons designed by a qualified Icelandic teacher</li>
				<li><svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
				</svg>Practical phrases and vocabulary lists</li>
				<li><svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
				</svg>Helpful and clear grammar explanations</li>
				<li><svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
				</svg>Real recordings of spoken Icelandic</li>
				<li><svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
				</svg>Interactive grammar exercises</li>
				<li><svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M15.5145 4.14251C15.9881 4.42665 16.1416 5.04091 15.8575 5.51449L9.85749 15.5145C9.69906 15.7785 9.42781 15.9549 9.12218 15.9925C8.81656 16.0301 8.51064 15.9248 8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8.80146 13.3872L14.1425 4.4855C14.4267 4.01192 15.0409 3.85836 15.5145 4.14251Z" fill="#1C1E21"/>
				</svg>Cultural and situational context</li>
			</ul>
		</div>
	)
}